import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Stats } from './stat.type';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatComponent {
  @Input() stats: Stats;

  isNumber(value: unknown): value is number {
    return typeof value === 'number';
  }

  nullishDash(value: string): string {
    return value ?? '-';
  }
}
