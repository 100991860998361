import { DateTime } from 'luxon';

export interface DateRange {
  start: Date;
  end: Date;
}

export interface DateTimeRange {
  start: DateTime | null;
  end: DateTime | null;
}
