import { WithId } from 'mongodb';
import { MongoInsert } from '../../../types/db-insert.type';
import { DBMedia } from '../../../types/mongodb.type';

export interface UploadProgressError {
  uploadId: number;
  error: any;
}

export interface UploadProgressFileRead {
  uploadId: number;
  file: File;
  dataUrl: string;
  media: MongoInsert<MediaUploading>;
}

export interface UploadProgressPrepare {
  uploadId: number;
  file: File;
  mediaUploading: WithId<MediaUploading>;
}

export interface UploadProgressUpload {
  uploadId: number;
  /**
   * 완료 여부
   */
  completed: boolean;
  /**
   * 진행도 (0-1, 포함)
   */
  progress: number;
  mediaUploading: WithId<MediaUploading>;
}

export interface UploadProgressComplete {
  uploadId: number;
  /**
   * 업로드 완료 시 업로드 된 미디어 정보
   */
  uploadedMedia?: DBMedia;
}

export interface UploadProgressProgress {
  uploadId: number;
  state: 'fileRead' | 'prepare' | 'upload' | 'completed' | 'error';
  progress: number;
  media?: WithId<DBMedia | MediaUploading>;
  error?: any;
}

export interface UploadProgress {
  /**
   * 완료 여부
   */
  completed: boolean;
  /**
   * 전체 파일 수
   */
  totalCount: number;
  /**
   * 업로드 성공 파일 수
   */
  successCount: number;
  /**
   * 업로드 실패 파일 수
   */
  failureCount: number;
  /**
   * 진행도 (0-1, 포함)
   */
  progress: number;
  /**
   * 업로드 중인 미디어 목록
   */
  mediaUploadingList: Array<WithId<DBMedia | MediaUploading>>;
  /**
   * 업로드 완료된 미디어 목록
   */
  mediaUploadedList: Array<WithId<DBMedia>>;
  /**
   * 업로드 중인 미디어 추가된 목록
   */
  mediaUploadingNewList?: Array<WithId<DBMedia | MediaUploading>>;
  /**
   * 업로드 완료된 미디어 추가된 목록
   */
  mediaUploadedNewList?: Array<WithId<DBMedia>>;
  /**
   * 발생한 오류 목록
   */
  errorList?: Array<any>;
}

/**
 * 이미지 또는 동영상의 해상도 정보
 */
export interface Dimension {
  /**
   * 너비
   */
  width: number;
  /**
   * 높이
   */
  height: number;
}

export interface MediaUploading extends DBMedia {
  /**
   * 진행도 (0-1, 포함)
   */
  progress: number;
  /**
   * 파일 data url
   */
  dataUrl: string;
}
