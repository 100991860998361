<ng-template #itemTemplate let-item="item" let-depth="depth">
  <mat-list-item matRipple [class.open]="item.isOpen" [routerLink]="item.calculatedPath" routerLinkActive="active" (click)="onClickLink()">
    <button *ngIf="item.subItems?.length" mat-icon-button (click)="onClickExpand($event, item)" (mousedown)="$event.stopPropagation()" (mouseup)="$event.stopPropagation()">
      <mat-icon>expand_more</mat-icon>
    </button>
    <mat-icon *ngIf="item.icon" class="navigation-icon">{{ item.icon }}</mat-icon>
    <mat-icon *ngIf="!item.icon && !item.subItems?.length" class="navigation-icon" svgIcon="navigation-dot"></mat-icon>
    <span class="item-name">{{ item.name }}</span>
  </mat-list-item>
  <mat-nav-list *ngIf="item.subItems?.length && item.isOpen" [style.marginLeft.rem]="1">
    <ng-container *ngFor="let item of item.subItems">
      <ng-container *ngIf="!item.blocked" [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item: item, depth: depth + 1 }"></ng-container>
    </ng-container>
  </mat-nav-list>
</ng-template>
<mat-nav-list>
  <ng-container *ngFor="let item of navs">
    <ng-container *ngIf="!item.blocked" [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item: item, depth: 0 }"></ng-container>
  </ng-container>
  <mat-list-item *ngIf="!isAuthed" matRipple [routerLink]="['login']" (click)="onClickLink()">
    <mat-icon class="navigation-icon">login</mat-icon>
    <span class="item-name">로그인</span>
  </mat-list-item>
  <mat-list-item *ngIf="isAuthed" matRipple (click)="onClickLogout()">
    <mat-icon class="navigation-icon">power_settings_new</mat-icon>
    <span class="item-name">로그아웃</span>
  </mat-list-item>
</mat-nav-list>
