export interface AlertButton {
  text: string;
  role?: string;
  cssClass?: string | string[];
  handler?: (value: any) => boolean | void | {
    [key: string]: any;
  };
}

export interface AlertInput {
  type?: string;
  name?: string;
  placeholder?: string;
  value?: any;
  disabled?: boolean;
  min?: string | number;
  max?: string | number;
}

export type AlertButtonFill = 'clear' | 'outline' | 'solid';

export interface AlertOptions {
  header?: string;
  message?: string;
  inputs?: Array<AlertInput>;
  buttons?: Array<AlertButton | string>;
  buttonFill?: AlertButtonFill;
  values?: { [name: string]: any };
}

export interface AlertCloseEventDetail<T = any> {
  data?: T;
  role?: string;
}
