import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, throwError } from 'rxjs';
import { DBMedia } from '../../../types/mongodb.type';
import { showErrorSnackbar } from '../../../utils/etc.util';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';
import { ApiService } from '../../services/api';

@Component({
  selector: 'app-media-view',
  templateUrl: './media-view.component.html',
  styleUrls: ['./media-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaViewComponent implements OnChanges, OnDestroy {
  @Input() mediaId: string;

  media: DBMedia | null;

  private mediaReadSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    private apiService: ApiService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mediaId) {
      this.fetchMedia();
    }
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.mediaReadSubscription,
    ])
  }

  private fetchMedia(): void {
    unsubscribeAll([this.mediaReadSubscription]);
    this.mediaReadSubscription = (
      this.mediaId?.length === 24 ?
      this.apiService.mediaV1MediaRead({ _id: this.mediaId }) :
      throwError(new Error('Invalid media id'))
    ).subscribe((res) => {
      this.media = res.result.media;
      this.changeDetectorRef.markForCheck();
    }, (err) => showErrorSnackbar(this.matSnackBar, err));
  }
}
