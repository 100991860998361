import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PercentagePipe } from './percentage.pipe';

@NgModule({
  declarations: [
    PercentagePipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PercentagePipe,
  ],
})
export class PercentageModule {}
