import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTime } from 'luxon';
import { isValidDate } from '../../../utils/date.util';
import { randomHexString } from '../../../utils/etc.util';
import { parseInt } from '../../../utils/number.util';

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTimeComponent),
      multi: true
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTimeComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;

  textValue: string;

  random: string;

  private baseDate: DateTime;
  private isDisabled: boolean;

  private onChangeCallback: (value: DateTime | undefined) => void;
  private onTouchedCallback: () => void;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.random = randomHexString();
  }

  get value(): DateTime | undefined {
    if (this.textValue) {
      const split = this.textValue.split(':');
      const hour = parseInt(split[0], 10);
      const minute = parseInt(split[1], 10);

      if (hour == null || minute == null) {
        return undefined;
      }

      return this.baseDate.set({ hour, minute });
    }

    return undefined;
  }

  @Input()
  set value(value: DateTime | undefined) {
    this.writeValue(value);
    this.onChangeCallback?.(this.value);
  }

  get disabled(): boolean {
    return this.isDisabled;
  }

  @Input()
  set disabled(value: boolean) {
    this.isDisabled = !!value;
  }

  ngOnInit(): void {
    this.baseDate = DateTime.local();
  }

  onInput(ev: Event): void {
    const elem = ev.target as HTMLInputElement;
    this.writeValue(elem.value);
    this.onChangeCallback?.(this.value);
  }

  onBlur(): void {
    this.onTouchedCallback?.();
  }

  writeValue(value: DateTime | Date | number | string | undefined): void {
    if (value == null || value === '' && value === '') {
      this.baseDate = DateTime.local();
      this.textValue = '';
      this.changeDetectorRef.markForCheck();
      return;
    }

    if (value instanceof DateTime) {
      this.baseDate = value;
      this.textValue = `${('0' + this.baseDate.hour).slice(-2)}:${('0' + this.baseDate.minute).slice(-2)}:${('0' + this.baseDate.second).slice(-2)}`;
      this.changeDetectorRef.markForCheck();
      return;
    }

    if (typeof value === 'string') {
      const match = value.match(/^(\d+):(\d{1,2}):(\d{1,2})$/);
      if (match) {
        const hours = parseInt(match[1], 10);
        const minutes = parseInt(match[2], 10);
        const seconds = parseInt(match[3], 10);
        if (
          hours != null && hours >= 0 && hours < 24
          && minutes != null && minutes >= 0 && minutes < 60
          && seconds != null && seconds >= 0 && seconds < 60
        ) {
          this.textValue = value;
          this.changeDetectorRef.markForCheck();
          return;
        }
      }
    }

    if (typeof value !== 'number' && !isValidDate(value)) {
      this.baseDate = DateTime.local();
      this.textValue = '';
      this.changeDetectorRef.markForCheck();
      return;
    }

    this.baseDate = DateTime.fromJSDate(new Date(value));
    this.textValue = `${('0' + this.baseDate.hour).slice(-2)}:${('0' + this.baseDate.minute).slice(-2)}:${('0' + this.baseDate.second).slice(-2)}`;
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(callback: (value: DateTime | undefined) => void): void {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
