import { Component, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { ChangePageEvent } from './paginator.type';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnChanges {
  @Input() page: number;
  @Input() endPage: number;

  @Output() changePage: EventEmitter<ChangePageEvent> = new EventEmitter();

  pageNumList: Array<number>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page || changes.endPage) {
      this.page = Math.max(this.page, 1);
      this.endPage = Math.max(this.endPage, 1);
      this.pageNumList = this.makePageNumList(this.page, this.endPage);
    }
  }

  onClickPrev(): void {
    this.selectPage(this.page - 1);
  }

  onClickPageNum(page: number): void {
    this.selectPage(page);
  }

  onClickNext(): void {
    this.selectPage(this.page + 1);
  }

  /**
   * 페이지를 선택합니다.
   * `changePage`로 `CustomEvent<number>`가 방출됩니다.
   * @param page 페이지
   */
  private selectPage(page: number): void {
    this.page = page;
    this.pageNumList = this.makePageNumList(this.page, this.endPage);
    const ev = new CustomEvent<number>('ChangePageEvent', { detail: page });
    this.changePage.emit(ev);
  }

  /**
   * 페이지 번호 배열을 생성합니다.
   * @param page 페이지
   * @param endPage 끝 페이지
   */
  private makePageNumList(page: number, endPage: number): Array<number> {
    const list: Array<number> = [];
    const listStart = Math.floor((Math.min(page, endPage) - 1) / 10) * 10 + 1;
    const listEnd = Math.min(listStart + 9, endPage);
    for (let i = listStart; i <= listEnd; i += 1) {
      list.push(i);
    }
    return list;
  }
}
