<mat-form-field *ngIf="permissions.canListSubCap; else inputTemplate" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select [disabled]="disabled" [multiple]="multiple" [(ngModel)]="value">
    <mat-form-field class="select-search-field">
      <mat-label>서브캡 제목 검색</mat-label>
      <input #searchInput matInput (input)="searchWork($event.target.value)">
    </mat-form-field>
    <mat-option *ngIf="!(subCapList)?.length" disabled [value]="undefined">{{ label }}</mat-option>
    <mat-option *ngFor="let subCap of subCapList; trackBy: trackSubCap" [class.input-sub-cap-id-warn]="!subCap.found || subCap.deletedAt" [value]="subCap._id">
      <ng-container *ngIf="subCap.found">{{ subCap.title }}{{ subCap.deletedAt ? ' (삭제됨)' : '' }}</ng-container>
      <ng-container *ngIf="!subCap.found">{{ subCap._id }} (찾을 수 없음)</ng-container>
    </mat-option>
  </mat-select>
  <button mat-button *ngIf="clearable && (valueArray$ | async)?.length" matSuffix mat-icon-button aria-label="Clear" (click)="onClickClear($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<ng-template #inputTemplate>
  <mat-form-field appearance="fill">
    <mat-label>{{ labelText }}</mat-label>
    <input matInput type="text" [disabled]="disabled" [ngModel]="valueCommaString" (input)="onInput($event)" (blur)="onBlur()">
  </mat-form-field>
</ng-template>
