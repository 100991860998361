<mat-form-field *ngIf="permissions.canListTicketBuyOption; else inputTemplate" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select [disabled]="disabled" [multiple]="multiple" [(ngModel)]="value">
    <mat-option *ngIf="!(ticketBuyOptionList)?.length" disabled [value]="undefined">{{ label }}</mat-option>
    <mat-option *ngFor="let ticketBuyOption of ticketBuyOptionList; trackBy: trackTicketBuyOption" [class.input-ticket-buy-option-id-warn]="!ticketBuyOption.found || ticketBuyOption.deletedAt" [value]="ticketBuyOption.id">
      <ng-container *ngIf="ticketBuyOption.found">{{ ticketBuyOption.ios_product_id ? '(iOS) ' : '' }}{{ ticketBuyOption.amount | formatNumber:0:'-' }}장 - {{ (ticketBuyOption.krw_price + ticketBuyOption.krw_price_tax) | formatNumber:0:'-' }}원{{ ticketBuyOption.deletedAt ? ' (삭제됨)' : '' }}</ng-container>
      <ng-container *ngIf="!ticketBuyOption.found">{{ ticketBuyOption.id }} (찾을 수 없음)</ng-container>
    </mat-option>
  </mat-select>
  <button mat-button *ngIf="clearable && (valueArray | async)?.length" matSuffix mat-icon-button aria-label="Clear" (click)="onClickClear($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<ng-template #inputTemplate>
  <mat-form-field appearance="fill">
    <mat-label>{{ labelText }}</mat-label>
    <input matInput type="string" [disabled]="disabled" (input)="onInput($event)" (blur)="onBlur()">
  </mat-form-field>
</ng-template>
