<div class="give-ticket-modal">
  <mat-form-field appearance="fill">
    <mat-label>대상 회원 UID</mat-label>
    <input matInput type="text" [disabled]="isGivingTicket" [(ngModel)]="userUid">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>티켓 종류</mat-label>
    <mat-select [disabled]="isGivingTicket" [(ngModel)]="ticketType">
      <mat-option value="paid" disabled>유료</mat-option>
      <mat-option value="free">무료</mat-option>
      <mat-option value="pass">이용권</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>티켓 수량</mat-label>
    <input matInput type="number" min="0" [disabled]="isGivingTicket" [(ngModel)]="ticketAmount">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>티켓 만료일</mat-label>
    <input matInput [matDatepicker]="expiryDatePicker" [disabled]="isGivingTicket" [(ngModel)]="ticketExpiresAt">
    <mat-datepicker-toggle matSuffix [for]="expiryDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #expiryDatePicker></mat-datepicker>
  </mat-form-field>
  <app-input-time label="티켓 만료시" [disabled]="isGivingTicket" [(ngModel)]="ticketExpiresAt"></app-input-time>
  <mat-form-field appearance="fill">
    <mat-label>티켓 지급 사유</mat-label>
    <mat-select [disabled]="isGivingTicket" [(ngModel)]="ticketObtainedFromSelect">
      <mat-option *ngFor="let preset of ticketObtainedFromPreset" [value]="preset">{{ preset === INPUT ? '직접 입력' : preset }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="ticketObtainedFromSelect === INPUT" appearance="fill">
    <mat-label>티켓 지급 사유</mat-label>
    <input matInput type="text" [disabled]="isGivingTicket" [(ngModel)]="ticketObtainedFromInput">
  </mat-form-field>
  <app-input-work-id *ngIf="ticketType === 'pass'" label="이용권 사용 가능한 작품 (선택)" labelText="이용권 사용 가능한 작품 ID (선택)" clearable [disabled]="isGivingTicket" [(ngModel)]="ticketForWorkId"></app-input-work-id>
  <button mat-raised-button color="primary" [disabled]="isGivingTicket" (click)="onClickGive()">지급</button>
</div>
