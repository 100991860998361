import { NgModule } from '@angular/core';
import { ChartModule } from './components/chart';
import { ContentEditorModule } from './components/content-editor';
import { ContentPreviewModalModule } from './components/content-preview-modal';
import { ContentViewerModule } from './components/content-viewer';
import { CountdownModule } from './components/countdown';
import { DateRangePickerModule } from './components/date-range-picker';
import { GiveTicketModalModule } from './components/give-ticket-modal';
import { InputPromotionIdModule } from './components/input-promotion-id';
import { InputSubCapIdModule } from './components/input-sub-cap-id';
import { InputTicketBuyOptionIdModule } from './components/input-ticket-buy-option-id';
import { InputTimeModule } from './components/input-time';
import { InputWorkIdModule } from './components/input-work-id';
import { MediaViewModule } from './components/media-view';
import { NavigationModule } from './components/navigation';
import { PaginatorModule } from './components/paginator';
import { SelectMediaModule } from './components/select-media';
import { SpinnerBlockModule } from './components/spinner-block';
import { StatModule } from './components/stat';
import { StatGridModule } from './components/stat-grid';
import { ToolbarModule } from './components/toolbar';
import { VideoPreviewModule } from './components/video-preview';
import { FormatDateModule } from './pipes/format-date';
import { FormatDurationModule } from './pipes/format-duration';
import { FormatNumberModule } from './pipes/format-number';
import { FormatNumberDeltaModule } from './pipes/format-number-delta';
import { MediaUrlModule } from './pipes/media-url';
import { PercentageModule } from './pipes/percentage';

@NgModule({
  imports: [
    ChartModule,
    ContentEditorModule,
    ContentPreviewModalModule,
    ContentViewerModule,
    CountdownModule,
    DateRangePickerModule,
    GiveTicketModalModule,
    InputPromotionIdModule,
    InputSubCapIdModule,
    InputTicketBuyOptionIdModule,
    InputTimeModule,
    InputWorkIdModule,
    MediaViewModule,
    NavigationModule,
    PaginatorModule,
    SelectMediaModule,
    SpinnerBlockModule,
    StatModule,
    StatGridModule,
    ToolbarModule,
    VideoPreviewModule,
    FormatDateModule,
    FormatDurationModule,
    FormatNumberModule,
    FormatNumberDeltaModule,
    MediaUrlModule,
    PercentageModule,
  ],
  exports: [
    ChartModule,
    ContentEditorModule,
    ContentPreviewModalModule,
    ContentViewerModule,
    CountdownModule,
    DateRangePickerModule,
    GiveTicketModalModule,
    InputPromotionIdModule,
    InputSubCapIdModule,
    InputTicketBuyOptionIdModule,
    InputTimeModule,
    InputWorkIdModule,
    MediaViewModule,
    NavigationModule,
    PaginatorModule,
    SelectMediaModule,
    SpinnerBlockModule,
    StatModule,
    StatGridModule,
    ToolbarModule,
    VideoPreviewModule,
    FormatDateModule,
    FormatDurationModule,
    FormatNumberModule,
    FormatNumberDeltaModule,
    MediaUrlModule,
    PercentageModule,
  ],
})
export class SharedModule {}
