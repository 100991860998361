import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { WithId } from 'mongodb';
import { DBMedia } from '../../../../types/mongodb.type';
import { MediaUploading } from '../../../services/upload';
import { ContentImage } from '../../content-viewer/content-image/content-image.type';

@Component({
  selector: 'app-content-image-edit',
  templateUrl: './content-image-edit.component.html',
  styleUrls: ['./content-image-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentImageEditComponent implements OnChanges {
  @Input() cut: ContentImage;
  @Input() mediaList: Array<WithId<DBMedia | MediaUploading>>;

  imageList: Array<WithId<DBMedia>>;

  selectedImage?: WithId<DBMedia>;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cut) {
      this.updateSelectedImage();
    }
    if (changes.mediaList) {
      this.imageList = (this.mediaList || []).filter((media) => media.isUploaded && media.type.startsWith('image'));
      this.updateSelectedImage();
    }
  }

  onChangeImage(ev: MatSelectChange): void {
    const data = this.cut?.data;
    if (data && typeof data === 'object') {
      const media = ev.value as WithId<DBMedia>;
      this.cut.data.mediaId = media._id;
      this.cut.data.url = media.url!;
      this.cut.width = media.width;
      this.cut.height = media.height;
    }
  }

  onChangeLink(ev: string): void {
    if (this.cut?.data) {
      this.cut.data.link = ev || undefined;
    }
  }

  private updateSelectedImage(): void {
    if (this.cut && this.imageList) {
      this.selectedImage = this.imageList.find((image) => image._id === this.cut?.data?.mediaId);
    }
  }
}
