import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { WAI_LUXON_DATE_FORMAT } from '../../../constants/luxon-date-formats.constant';
import { InputTimeModule } from '../input-time';
import { InputWorkIdModule } from '../input-work-id';
import { GiveTicketModalComponent } from './give-ticket-modal.component';

@NgModule({
  declarations: [
    GiveTicketModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatLuxonDateModule,
    InputTimeModule,
    InputWorkIdModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: WAI_LUXON_DATE_FORMAT },
  ],
  exports: [
    GiveTicketModalComponent,
  ],
})
export class GiveTicketModalModule {}
