<img class="image" [src]="cut?.data?.url">
<mat-form-field appearance="fill">
  <mat-label>이미지</mat-label>
  <mat-select panelClass="select-height-auto" [value]="selectedImage" (selectionChange)="onChangeImage($event)">
    <mat-option *ngFor="let image of imageList" [value]="image">
      <div class="image-option">
        <img class="image-preview" [src]="image.url">
        <span>{{ image.name }}</span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>링크 (옵션)</mat-label>
  <input matInput [ngModel]="cut?.data.link" (ngModelChange)="onChangeLink($event)">
</mat-form-field>
