import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, from, map, merge, Observable, shareReplay, Subject, Subscription, takeUntil } from 'rxjs';
import { BusyTask } from './busy.type';

function isPromise(obj: any): obj is Promise<unknown> {
  return obj != null && 'then' in obj && typeof obj.then === 'function';
}

function fromSubscriptionClose(subscription: Subscription): Observable<Subscription> {
  return new Observable((subscriber) => {
    const teardown = () => {
      subscriber.next(subscription);
      subscriber.complete();
    };

    subscription.add(teardown);

    return () => {
      subscription.remove(teardown);
    };
  });
}

function fromPromiseFinally<T>(promise: Promise<T>): Observable<Promise<T>> {
  return from(promise).pipe(
    map(() => promise),
  );
}

@Injectable({
  providedIn: 'root'
})
export class BusyService implements OnDestroy {
  busy$: Observable<boolean>;

  private ngOnDestroySubject = new Subject<void>();
  private taskClosedMap = new Map<BusyTask, Subscription>();
  private taskClosedChangeSubject = new Subject<void>();
  private closedSubject = new Subject<BusyTask>();
  private busySubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.busy$ = from(this.busySubject).pipe(
      distinctUntilChanged(),
      takeUntil(this.ngOnDestroySubject),
      shareReplay(1),
    );

    from(this.taskClosedChangeSubject).pipe(
      takeUntil(this.ngOnDestroySubject),
    ).subscribe(() => {
      this.busySubject.next(this.taskClosedMap.size > 0);
    });

    from(this.closedSubject).pipe(
      takeUntil(this.ngOnDestroySubject),
    ).subscribe((task) => {
      this.taskClosedMap.delete(task);
      this.busySubject.next(this.taskClosedMap.size > 0);
    });
  }

  ngOnDestroy(): void {
    this.ngOnDestroySubject.next();
    this.ngOnDestroySubject.complete();
  }

  mark(task: BusyTask): boolean {
    if (this.taskClosedMap.has(task) || (!isPromise(task) && task.closed)) {
      return false;
    }

    const dd = merge(isPromise(task) ? fromPromiseFinally(task) : fromSubscriptionClose(task));
    const closedSubscription = dd.pipe(
      takeUntil(this.ngOnDestroySubject),
    ).subscribe(() => {
      this.closedSubject.next(task);
    });
    this.taskClosedMap.set(task, closedSubscription);
    this.taskClosedChangeSubject.next();
    return true;
  }

  unmark(task: BusyTask): boolean {
    if (!this.taskClosedMap.has(task)) {
      return false;
    }

    this.taskClosedMap.delete(task);
    this.taskClosedChangeSubject.next();
    return true;
  }

  // private checkBusy(): void {
  //   const busy = Array.from(this.taskClosedMap.keys()).some((sub) => !sub.closed);
  //   this.busySubject.next(busy);
  // }
}
