import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ApiService } from '../api';

async function getItem<T = any>(key: string): Promise<T | null> {
  const item = localStorage.getItem(key);
  if (item !== undefined && item !== null) {
    return JSON.parse(item);
  } else {
    return null;
  }
}

async function setItem<T = any>(key: string, data: T): Promise<void> {
  if (data === undefined) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify(data));
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private initPromise: Promise<void>;
  private initPromiseResolve: () => void;

  private accessTokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private apiService: ApiService,
  ) {
    this.initPromise = new Promise((resolve) => {
      this.initPromiseResolve = resolve;
    });
  }

  /**
   * `AuthService`를 초기화합니다.
   * 저장된 정보를 불러옵니다.
   */
  async init(): Promise<void> {
    const accessToken = await getItem<string>('waikiki.login.accessToken');
    await this.setAccessToken(accessToken);
    this.initPromiseResolve();
  }

  /**
   * 로그인을 합니다. 로그인 성공 시 액세스 토큰이 설정됩니다.
   * @param email 이메일
   * @param password 비밀번호
   */
  async login(email: string, password: string): Promise<void> {
    const platform = this.getPlatformString();
    const res = await firstValueFrom(this.apiService.accountV1SignIn({ email, password, platform }));
    await this.setAccessToken(res.result.accessToken);
  }

  /**
   * 액세스 토큰을 갱신합니다.
   */
  async renewToken(): Promise<void> {
    try {
      const res = await firstValueFrom(this.apiService.accountV1RenewToken());

      if (res.result.accessToken) {
        await this.setAccessToken(res.result.accessToken);
      }
    } catch (err) {
      console.error(err);
      await this.setAccessToken(null);
    }
  }

  /**
   * 로그아웃을 합니다.
   */
  async logout(): Promise<void> {
    try {
      await firstValueFrom(this.apiService.accountV1SignOut());
    } catch (err) {
      console.error(err);
    }
    await this.setAccessToken(null);
  }

  /**
   * 현재 사용중인 액세스 토큰을 반환합니다.
   * 로그인 상태가 아니면 `null`을 반환합니다.
   */
  getAccessToken(): string | null {
    return this.accessTokenSubject.value;
  }

  /**
   * `AuthService`가 초기화된 후 액세스 토큰 값으로 이행합니다.
   * 이미 초기화 상태인 경우 현재 사용중인 액세스 토큰 값으로 이행합니다.
   * 로그인 상태가 아니면 `null` 값으로 이행합니다.
   */
  async getAccessTokenPromise(): Promise<string | null> {
    await this.initPromise;
    return this.accessTokenSubject.value;
  }

  /**
   * 사용할 액세스 토큰이 변경될 때 새 액세스 토큰을 방출합니다.
   * `subscribe` 시 현재 사용중인 액세스 토큰을 방출합니다.
   */
  getAccessTokenObservable(): Observable<string | null> {
    return this.accessTokenSubject.asObservable();
  }

  /**
   * 사용할 액세스 토큰을 설정합니다.
   * @param accessToken 액세스 토큰
   */
  private async setAccessToken(accessToken: string | null): Promise<void> {
    setItem('waikiki.login.accessToken', accessToken);
    this.accessTokenSubject.next(accessToken);
  }

  /**
   * 플랫폼 정보 문자열을 가져옵니다.
   */
  private getPlatformString(): string {
    return `Web`;
  }
}
