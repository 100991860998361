import { MatDateFormats } from '@angular/material/core';

export const WAI_LUXON_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'yyyy-LL-dd',
  },
  display: {
    dateInput: 'yyyy-LL-dd',
    dateA11yLabel: 'DD',
    monthLabel: 'LL',
    monthYearLabel: 'yyyy LL',
    monthYearA11yLabel: 'LLLL yyyy',
  },
};
