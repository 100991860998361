/**
 * 문자열 또는 `Date` 객체의 `Date`로서의 유효 여부를 반환합니다.
 * @param date 유효 여부를 확인할 문자열 또는 `Date` 객체
 */
export function isValidDate(date: string | Date | null | undefined): date is string | Date {
  if (date == null) {
    return false;
  }

  try {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    if (!isNaN(dateObj.valueOf())) {
      return true;
    }
  } catch {
    // noop
  }
  return false;
}

/**
 * 문자열 또는 `Date` 객체를 ISO 8601 형식의 문자열로 변환합니다. 실패 시 `null`을 반환합니다.
 * @param date 변환할 문자열 또는 `Date` 객체
 */
export function normalizeDate(date: string | Date): string | null {
  try {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    if (!isNaN(dateObj.valueOf())) {
      return dateObj.toISOString();
    }
  } catch {
    // noop
  }
  return null;
}

/**
 * 문자열 또는 `Date` 객체를 Unix timestamp로 변환합니다. 실패 시 `NaN`을 반환합니다.
 * @param date 변환할 문자열 또는 `Date` 객체
 */
export function dateToNumber(date: string | Date): number {
  try {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return dateObj.valueOf();
  } catch {
    // noop
  }
  return NaN;
}

/**
 * 주어진 `Date`를 기준으로 `Date` 범위를 만듭니다. 범위 길이는 음수가 될 수 있습니다. 주어진 값이 올바르지 않은 경우 `startDate`와 `endDate` 모두 Invalid Date가 됩니다.
 * @param baseDate 기준이 되는 `Date`
 * @param length 범위 길이 (ms)
 */
export function makeDateRange(baseDate: Date, length: number = 0): { startDate: Date; endDate: Date; } {
  const date1 = new Date((baseDate?.getTime() ?? NaN));
  const date2 = new Date((baseDate?.getTime() ?? NaN) + length);
  return date2.getTime() > date1.getTime() ? {
    startDate: date1,
    endDate: date2,
  } : {
    startDate: date2,
    endDate: date1,
  };
}
