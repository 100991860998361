<ng-container *ngIf="direction !== 'horizontal'; else horizontalTemplate">
  <div *ngFor="let cut of contentsAll" class="content-cut" [ngSwitch]="cut.type">
    <app-content-text *ngSwitchCase="'text'" [cut]="cut" [scrollMagic]="scrollMagic" [style.height.px]="calcHeight(cut)" (clickStatic)="clickStatic.emit($event)"></app-content-text>
    <app-content-image *ngSwitchCase="'image'" [cut]="cut" [scrollMagic]="scrollMagic" [style.height.px]="calcHeight(cut)" (clickStatic)="clickStatic.emit($event)" (clickLink)="clickLink.emit($event)"></app-content-image>
    <app-content-video *ngSwitchCase="'video'" [cut]="cut" [scrollMagic]="scrollMagic" [download]="download" [style.height.px]="calcHeight(cut)"></app-content-video>
    <ng-container *ngSwitchCase="'ng-template'">
      <ng-template [ngTemplateOutlet]="cut.data.template"></ng-template>
    </ng-container>
  </div>
</ng-container>

<ng-template #horizontalTemplate>
  <swiper #slides [speed]="200" (slideChange)="onSlideChange($event)">
    <ng-template *ngFor="let cut of contentsAll; let i = index" swiperSlide let-slide>
      <div class="slide-wrapper" [style.width.px]="width" [style.height.px]="height" (click)="onClickSlide($event)">
        <div class="content-cut content-cut-horizontal" [ngSwitch]="cut.type">
          <app-content-text *ngSwitchCase="'text'" [cut]="cut" [slideVisible]="slide.isActive" [style.width.px]="calcWidth(cut)" [style.height.px]="calcHeight(cut)" (clickStatic)="clickStatic.emit($event)"></app-content-text>
          <app-content-image *ngSwitchCase="'image'" [cut]="cut" [slideVisible]="slide.isActive" [style.width.px]="calcWidth(cut)" [style.height.px]="calcHeight(cut)" (clickStatic)="clickStatic.emit($event)" (clickLink)="clickLink.emit($event)"></app-content-image>
          <app-content-video *ngSwitchCase="'video'" [cut]="cut" [slideVisible]="slide.isActive" [download]="download" [style.width.px]="calcWidth(cut)" [style.height.px]="calcHeight(cut)"></app-content-video>
          <ng-container *ngSwitchCase="'ng-template'">
            <ng-template [ngTemplateOutlet]="cut.data.template"></ng-template>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </swiper>
</ng-template>
