import type { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { APIError } from '../shared/services/api/api.type';
import { DBAdminPermission } from '../types/mysql.type';
import { escapeRegExp } from '../utils/regexp.util';

/**
 * 문자열을 클립보드로 복사합니다.
 * @param str 복사될 문자열
 */
export async function copy(str: string): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    try {
      const ta = document.createElement('textarea');
      document.body.appendChild(ta);
      ta.value = str;
      ta.select();
      document.execCommand('copy');
      document.body.removeChild(ta);
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });
}

/**
 * 오류를 문자열 형태로 클립보드로 복사합니다.
 * @param err 문자열로 복사될 오류
 */
export async function copyError(err: Error | APIError | Array<Error | APIError>): Promise<boolean> {
  const arr = (Array.isArray(err) ? err : [err])
    .map((err2) => err2 instanceof APIError ?
      JSON.stringify(err2.response) :
      JSON.stringify({ name: err2.name, message: err2.message, stack: environment.production ? undefined : err2.stack }),
    );
  const str = arr.length > 1 ? `[${arr.join(',')}]` : arr[0];
  return await copy(str);
}

/**
 * 무작위 Hex 문자열을 생성합니다.
 * @param length 길이 (기본 값: `32`)
 */
export function randomHexString(length: number = 32): string {
  const arr = new Uint8Array(length / 2);
  crypto.getRandomValues(arr);
  return Array.from(arr, (num) => num.toString(16).padStart(2, '0')).join('');
}

/**
 * 가장 가까운 스크롤 가능한 부모를 가져옵니다.
 * @param elem 부모를 찾기 시작할 엘리먼트
 */
export function getScrollParent(elem: HTMLElement): HTMLElement {
  const elemStyle = getComputedStyle(elem);
  const excludeStaticParent = elemStyle.position === 'absolute';
  const overflowRegex = /auto|scroll/;

  if (elemStyle.position === 'fixed') {
    return window.document.body;
  }

  let parent: HTMLElement | null = elem;
  while (true) {
    // shadow dom
    if (parent.assignedSlot) {
      const slotParent = getScrollParent(parent.assignedSlot);

      if (slotParent !== window.document.body) {
        const slotStyle = getComputedStyle(slotParent);

        if (overflowRegex.test(slotStyle.overflow + slotStyle.overflowY + slotStyle.overflowX)) {
          return slotParent;
        }
      }
    }

    parent = parent.parentElement;
    if (!parent) {
      break;
    }

    const style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === 'static') {
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent;
    }
  }

  return window.document.body;
}

/**
 * 관리자 권한 정보를 권한 체크용 RegExp로 변환합니다.
 * @param permission 관리자 권한 정보
 */
export function permissionToRegex(permission: DBAdminPermission | string): RegExp {
  const converted = (typeof permission === 'string' ? permission : permission.permission)
    .split('.')
    .map((part) => part === '*' ? '.+' : escapeRegExp(part))
    .join('\\.');
  return new RegExp(`^${converted}$`);
}

/**
 * 포커스된 엘리먼트를 가져옵니다. 섀도우 DOM 안쪽으로도 들어갑니다.
 */
export function getFocusedElementPierceShadowDom(): HTMLElement | null {
  let elem = typeof document !== 'undefined' ? document?.activeElement as HTMLElement : null;

  while (elem?.shadowRoot) {
    const newElem = elem.shadowRoot.activeElement as HTMLElement;
    if (newElem === elem) {
      break;
    } else {
      elem = newElem;
    }
  }

  return elem;
}

export function isMobileScreen(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }

  return window.matchMedia('screen and (max-width: 767px)').matches;
}

export function showErrorSnackbar(matSnackBar: MatSnackBar, err: any, afterShow?: Function) {
  if (err != null) {
    const snackBar = matSnackBar.open(`${err?.message ?? '알 수 없는 오류가 발생했습니다.'}`, '오류 내용 복사', { duration: 5000 });
    snackBar.onAction().subscribe(() => {
      copyError(err);
    });
  } else {
    matSnackBar.open(`${err?.message ?? '알 수 없는 오류가 발생했습니다.'}`, '확인', { duration: 3000 });
  }
  afterShow?.();
}

export function isNotNullish<T>(v: T): v is NonNullable<T> {
  return v != null;
}
