import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
import { catchError, defer, EMPTY, filter, finalize, map, merge, Observable, Subscription, tap } from 'rxjs';
import { UserUserGiveTicketParams } from '../../../types/api/user.type';
import { showErrorSnackbar } from '../../../utils/etc.util';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';
import { ApiService } from '../../services/api';
import { GiveTicketCloseEventDetail, GiveTicketCloseEventDetailData, GiveTicketOptions } from '../../services/user';

const BACKDROP = 'backdrop';
const INPUT = Symbol('input');

@Component({
  selector: 'app-give-ticket-modal',
  templateUrl: './give-ticket-modal.component.html',
  styleUrls: ['./give-ticket-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiveTicketModalComponent implements OnInit, OnDestroy {
  isGivingTicket = false;

  ticketObtainedFromPreset: Array<string | typeof INPUT> = [
    '마스터 지급',
    '프로모션 참여',
    '오류 보상',
    '테스트',
    INPUT,
  ];
  INPUT = INPUT;

  userUid = '';
  ticketType: GiveTicketOptions['ticketType'] = 'free';
  ticketAmount = 0;
  ticketExpiresAt = DateTime.local();
  ticketObtainedFromSelect: string | typeof INPUT = this.ticketObtainedFromPreset[0] ?? INPUT;
  ticketObtainedFromInput = '';
  ticketForWorkId = '';

  private backdropCloseSubscription?: Subscription;
  private giveTicketSubscription?: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: GiveTicketOptions,
    private matDialogRef: MatDialogRef<GiveTicketModalComponent, GiveTicketCloseEventDetail>,
    private matSnackBar: MatSnackBar,
    private apiService: ApiService,
  ) {}

  ngOnInit(): void {
    const { userUid, ticketType, ticketAmount, ticketExpiresAt, ticketObtainedFrom, ticketForWorkId } = this.data;
    this.userUid = userUid ?? this.userUid;
    this.ticketType = ticketType ?? this.ticketType;
    this.ticketAmount = ticketAmount ?? this.ticketAmount;
    this.ticketExpiresAt = ticketExpiresAt == null ?
      this.ticketExpiresAt :
      DateTime.isDateTime(ticketExpiresAt) ?
      ticketExpiresAt :
      DateTime.fromJSDate(ticketExpiresAt);
    if (ticketObtainedFrom) {
      const ticketObtainedFromIndex = this.ticketObtainedFromPreset.indexOf(ticketObtainedFrom);
      this.ticketObtainedFromSelect = ticketObtainedFromIndex > -1 ? this.ticketObtainedFromPreset[ticketObtainedFromIndex] : INPUT;
      this.ticketObtainedFromInput = ticketObtainedFromIndex > -1 ? '' : ticketObtainedFrom;
    }
    this.ticketForWorkId = ticketForWorkId ?? this.ticketForWorkId;

    this.backdropCloseSubscription = merge(
      this.matDialogRef.keydownEvents().pipe(
        filter((ev) => ev.key === 'Escape'),
      ),
      this.matDialogRef.backdropClick(),
    ).pipe(
      // TODO: 지급 중에는 닫기 방지
    ).subscribe(() => {
      this.close(undefined, BACKDROP)
    });
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.backdropCloseSubscription,
      this.giveTicketSubscription,
    ]);
  }

  close(data?: GiveTicketCloseEventDetailData, role?: string): void {
    this.matDialogRef.close({ data, role });
  }

  onClickGive(): void {
    const userUid = this.userUid;
    const ticketAmount = this.ticketAmount;
    const ticketExpiresAt = this.ticketExpiresAt;
    const ticketObtainedFrom = this.ticketObtainedFromSelect === INPUT ? this.ticketObtainedFromInput : this.ticketObtainedFromSelect;
    const ticketForWorkId = this.ticketForWorkId || undefined;

    unsubscribeAll([this.giveTicketSubscription]);
    this.giveTicketSubscription = defer(() => {
      const obs = this.ticketType === 'free' ?
        this.giveTicketFree(userUid, ticketAmount, ticketExpiresAt, ticketObtainedFrom) :
        this.ticketType === 'pass' ?
        this.giveTicketPass(userUid, ticketAmount, ticketExpiresAt, ticketObtainedFrom, ticketForWorkId) :
        EMPTY;

      this.isGivingTicket = true;
      this.changeDetectorRef.markForCheck();

      return obs.pipe(
        tap(() => {
          this.close({ gave: true });
        }),
        catchError((err) => {
          showErrorSnackbar(this.matSnackBar, err);
          return EMPTY;
        }),
        finalize(() => {
          this.isGivingTicket = false;
          this.changeDetectorRef.markForCheck();
        })
      );
    }).subscribe();
  }

  private giveTicketFree(userUid: string, ticketAmount: number, ticketExpiresAt: DateTime, ticketObtainedFrom: string): Observable<void> {
    const params: UserUserGiveTicketParams = {
      userUid,
      ticketType: 'free',
      ticketAmount,
      ticketExpiresAt: ticketExpiresAt.toJSDate(),
      ticketObtainedFrom,
    };
    return this.apiService.userV1UserGiveTicket(params).pipe(
      map((res) => res.result),
    );
  }

  private giveTicketPass(userUid: string, ticketAmount: number, ticketExpiresAt: DateTime, ticketObtainedFrom: string, ticketForWorkId?: string): Observable<void> {
    const params: UserUserGiveTicketParams = {
      userUid,
      ticketType: 'pass',
      ticketAmount,
      ticketExpiresAt: ticketExpiresAt.toJSDate(),
      ticketObtainedFrom,
      ticketForWorkId,
    };
    return this.apiService.userV1UserGiveTicket(params).pipe(
      map((res) => res.result),
    );
  }
}
