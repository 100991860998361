import { Directionality } from '@angular/cdk/bidi';
import { hasModifierKey } from '@angular/cdk/keycodes';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatepickerDropdownPositionX, DatepickerDropdownPositionY, MatDateRangePicker, MAT_DATEPICKER_SCROLL_STRATEGY } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { merge, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { getFocusedElementPierceShadowDom } from '../../../utils/etc.util';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';
import { DateRangePickerContentComponent } from './date-range-picker-content';
import { DateRange, DateTimeRange } from './date-range-picker.type';

function toDateTime(date: DateTime | Date | null | undefined): DateTime | null {
  if (date == null) {
    return null;
  }
  return DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
}

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent implements ControlValueAccessor, OnDestroy {
  @ViewChild(MatDateRangePicker, { static: true }) dateRangePicker: MatDateRangePicker<DateTime>;

  @Input() startPlaceholder = '';
  @Input() endPlaceholder = '';
  @Input() xPosition: DatepickerDropdownPositionX = 'start';
  @Input() yPosition: DatepickerDropdownPositionY = 'below';

  /* eslint-disable @angular-eslint/no-output-rename */
  @Output('opened') openedStream: EventEmitter<void> = new EventEmitter();
  @Output('closed') closedStream: EventEmitter<void> = new EventEmitter();
  /* eslint-enable @angular-eslint/no-output-rename */

  presetOpened = false;

  private startDateInner: DateTime | null = null;
  private endDateInner: DateTime | null = null;
  private isDisabled: boolean;

  private focusedElementBeforeOpen: HTMLElement | null;
  private presetOverlayRef: OverlayRef | null;
  private presetComponentRef: ComponentRef<DateRangePickerContentComponent> | null;

  private onChangeCallback: (value: DateTimeRange | null | undefined) => void;
  private onTouchedCallback: () => void;

  private selectPresetSubscription: Subscription;

  constructor(
    @Inject(MAT_DATEPICKER_SCROLL_STRATEGY) private scrollStrategy: any,
    private changeDetectorRef: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    @Optional() private dir: Directionality,
  ) {}

  get startDate(): DateTime | null {
    return this.startDateInner;
  }

  @Input()
  set startDate(startDate: DateTime | null) {
    this.writeStartDate(startDate);
    this.onChangeCallback?.(this.value);
  }

  get endDate(): DateTime | null {
    return this.endDateInner;
  }

  @Input()
  set endDate(endDate: DateTime | null) {
    this.writeEndDate(endDate);
    this.onChangeCallback?.(this.value);
  }

  get value(): DateTimeRange {
    return { start: this.startDate, end: this.endDate };
  }

  @Input()
  set value(value: DateTimeRange) {
    this.writeValue(value);
    this.onChangeCallback?.(this.value);
  }

  get disabled(): boolean {
    return this.isDisabled;
  }

  @Input()
  set disabled(value: boolean) {
    this.isDisabled = !!value;
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.selectPresetSubscription,
    ])
  }

  onClickOpenPreset(ev: Event): void {
    if (!this.disabled) {
      ev.stopPropagation();
      this.openPreset();
    }
  }

  writeStartDate(startDate: DateTime | Date | null | undefined): void {
    this.startDateInner = toDateTime(startDate);
  }

  writeEndDate(endDate: DateTime | Date | null | undefined): void {
    this.endDateInner = toDateTime(endDate);
  }

  writeValue(value: DateTimeRange | DateRange | [DateTime, DateTime] | [Date, Date]): void {
    if (value != null && typeof value === 'object') {
      if (Array.isArray(value)) {
        this.startDate = toDateTime(value[0]);
        this.endDate = toDateTime(value[1]);
        this.changeDetectorRef.markForCheck();
        return;
      } else if ('start' in value && 'end' in value) {
        this.startDate = value.start != null ? toDateTime(value.start) : null;
        this.endDate = value.end != null ? toDateTime(value.end) : null;
        this.changeDetectorRef.markForCheck();
        return;
      }
    }

    this.startDate = null;
    this.endDate = null;
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(callback: (value: DateTimeRange) => void): void {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  openCalendar(): void {
    this.dateRangePicker.open();
  }

  closeCalendar(): void {
    this.dateRangePicker.close();
  }

  openPreset(): void {
    if (this.presetOpened || this.disabled) {
      return;
    }

    this.focusedElementBeforeOpen = getFocusedElementPierceShadowDom();
    this.openPresetOverlay();
    this.presetOpened = true;
    this.openedStream.emit();
  }

  closePreset(): void {
    if (!this.presetOpened) {
      return;
    }

    if (this.presetComponentRef) {
      const instance = this.presetComponentRef.instance;
      instance.startExitAnimation();
      instance.animationDone.pipe(take(1)).subscribe(() => this.destroyPresetOverlay());
    }

    const completeClose = () => {
      if (this.presetOpened) {
        this.presetOpened = false;
        this.closedStream.emit();
        this.focusedElementBeforeOpen = null;
      }
    };

    if (typeof this.focusedElementBeforeOpen?.focus === 'function') {
      this.focusedElementBeforeOpen.focus();
      setTimeout(completeClose);
    } else {
      completeClose();
    }
  }

  private openPresetOverlay(): void {
    const portal = new ComponentPortal(DateRangePickerContentComponent, this.viewContainerRef);
    const overlayRef = this.presetOverlayRef = this.overlay.create(new OverlayConfig({
      positionStrategy: this.getDropdownStrategy(),
      hasBackdrop: true,
      backdropClass: ['mat-overlay-transparent-backdrop'],
      direction: this.dir,
      scrollStrategy: this.scrollStrategy(),
      panelClass: `mat-datepicker-popup`,
    }));
    const overlayElement = overlayRef.overlayElement;
    overlayElement.setAttribute('role', 'dialog');

    this.getCloseStream(overlayRef).subscribe((event) => {
      if (event) {
        event.preventDefault();
      }
      this.closePreset();
    });

    this.presetComponentRef = overlayRef.attach(portal);
    this.selectPresetSubscription = this.presetComponentRef.instance.selectPreset.subscribe((dateTimeRange) => {
      this.value = { start: dateTimeRange.start, end: dateTimeRange.end?.minus({ day: 1 }) ?? null };
      this.closePreset();
    });
  }

  private destroyPresetOverlay(): void {
    if (this.presetOverlayRef) {
      this.presetOverlayRef.dispose();
      this.presetOverlayRef = this.presetComponentRef = null;
    }
  }

  private getDropdownStrategy(): FlexibleConnectedPositionStrategy {
    const strategy = this.overlay.position()
      .flexibleConnectedTo(this.dateRangePicker.datepickerInput.getConnectedOverlayOrigin())
      .withTransformOriginOn('.mat-datepicker-content')
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withLockedPosition();

    return this.setConnectedPositions(strategy);
  }

  private setConnectedPositions(strategy: FlexibleConnectedPositionStrategy): FlexibleConnectedPositionStrategy {
    const primaryX = this.xPosition === 'end' ? 'end' : 'start';
    const secondaryX = primaryX === 'start' ? 'end' : 'start';
    const primaryY = this.yPosition === 'above' ? 'bottom' : 'top';
    const secondaryY = primaryY === 'top' ? 'bottom' : 'top';

    return strategy.withPositions([
      {
        originX: primaryX,
        originY: secondaryY,
        overlayX: primaryX,
        overlayY: primaryY
      },
      {
        originX: primaryX,
        originY: primaryY,
        overlayX: primaryX,
        overlayY: secondaryY
      },
      {
        originX: secondaryX,
        originY: secondaryY,
        overlayX: secondaryX,
        overlayY: primaryY
      },
      {
        originX: secondaryX,
        originY: primaryY,
        overlayX: secondaryX,
        overlayY: secondaryY
      }
    ]);
  }

  private getCloseStream(overlayRef: OverlayRef): Observable<Event | void> {
    return merge(
      overlayRef.backdropClick(),
      overlayRef.detachments(),
      overlayRef.keydownEvents().pipe(
        filter((event) => event.key === 'Escape' && !hasModifierKey(event)),
      ),
    );
  }
}
