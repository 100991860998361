import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlertModalComponent } from '../../../shared/components/alert-modal';
import { APIError } from '../api';
import { AlertButton, AlertButtonFill, AlertCloseEventDetail, AlertInput, AlertOptions } from './alert.type';

const DEFAULT_OK_TEXT = '확인';
const DEFAULT_CANCEL_TEXT = '취소';
const DEFAULT_BUTTON_FILL: AlertButtonFill = 'clear';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private matDialog: MatDialog,
  ) {}

  /**
   * 알림 팝업을 표시합니다. 알림 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 이행합니다.
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   * @param buttonFill 버튼 fill
   */
  async alert(
    header: string,
    message: string,
    okButton: string | AlertButton = DEFAULT_OK_TEXT,
    buttonFill: AlertButtonFill = DEFAULT_BUTTON_FILL,
  ): Promise<boolean> {
    const alert = this.matDialog.open<AlertModalComponent, AlertOptions, AlertCloseEventDetail<any>>(AlertModalComponent, {
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'alert-dialog-panel',
      disableClose: true,
      data: {
        header,
        message,
        buttons: [okButton],
        buttonFill,
      },
    });

    const dismissDetail = await firstValueFrom(alert.beforeClosed().pipe(
      take(1),
    ));
    return dismissDetail != null && dismissDetail.role !== 'cancel' && dismissDetail.role !== 'backdrop';
  }

  /**
   * 확인 팝업을 표시합니다. 확인 팝업이 닫힐 때 확인 버튼이 눌렸는지 여부를 이행합니다.
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   * @param buttonFill 버튼 fill
   */
  async confirm(
    header: string,
    message: string,
    okButton: string | AlertButton = DEFAULT_OK_TEXT,
    cancelButton: string | AlertButton = DEFAULT_CANCEL_TEXT,
    buttonFill: AlertButtonFill = DEFAULT_BUTTON_FILL,
  ): Promise<boolean> {
    const cancelButton2: AlertButton =
      typeof cancelButton === 'string' ?
      { text: cancelButton, role: 'cancel' } :
      cancelButton;

    const alert = this.matDialog.open<AlertModalComponent, AlertOptions, AlertCloseEventDetail>(AlertModalComponent, {
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'alert-dialog-panel',
      disableClose: true,
      data: {
        header,
        message,
        buttons: [okButton, cancelButton2],
        buttonFill,
      },
    });

    const dismissDetail = await firstValueFrom(alert.beforeClosed().pipe(
      take(1),
    ));
    return dismissDetail != null && dismissDetail.role !== 'cancel' && dismissDetail.role !== 'backdrop';
  }

  /**
   * 프롬프트 팝업을 표시합니다. 확인 팝업이 닫힐 때 입력한 데이터를 이행합니다.
   * @param header 팝업 헤더 텍스트
   * @param message 팝업 메시지
   * @param prompt 프롬프트
   * @param okButton 확인 버튼 텍스트
   * @param cancelButton 취소 버튼 텍스트
   * @param buttonFill 버튼 fill
   */
  async prompt<T = string>(
    header: string,
    message: string,
    prompt: string | AlertInput,
    okButton: string | AlertButton = DEFAULT_OK_TEXT,
    cancelButton: string | AlertButton = DEFAULT_CANCEL_TEXT,
    buttonFill: AlertButtonFill = DEFAULT_BUTTON_FILL,
  ): Promise<T | undefined> {
    const cancelButton2: AlertButton =
      typeof cancelButton === 'string' ?
      { text: cancelButton, role: 'cancel' } :
      cancelButton;

    const alert = this.matDialog.open<AlertModalComponent, AlertOptions, AlertCloseEventDetail>(AlertModalComponent, {
      maxWidth: '100%',
      maxHeight: '100%',
      panelClass: 'alert-dialog-panel',
      disableClose: true,
      data: {
        header,
        message,
        inputs: [
          prompt == null || typeof prompt === 'string'
            ? { type: 'text', value: prompt }
            : prompt,
        ],
        buttons: [okButton, cancelButton2],
        buttonFill,
      },
    });

    const dismissDetail = await firstValueFrom(alert.beforeClosed().pipe(
      take(1),
    ));
    return dismissDetail != null && dismissDetail.role !== 'cancel' && dismissDetail.role !== 'backdrop'
      ? dismissDetail.data?.values[0]
      : undefined;
  }

  /**
   * `err` 가 `APIError` 인 경우 알림 팝업을 표시합니다. 그렇지 않은 경우 `console.error(err)` 를 호출합니다.
   * @param err 오류
   */
  async alertAPIError(err: Error): Promise<void> {
    if (err instanceof APIError) {
      await this.alert('알림', err.message);
    } else {
      console.error(err);
    }
  }
}
