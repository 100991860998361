import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WithId } from 'mongodb';
import { auditTime, BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, endWith, from, map, Observable, of, scan, shareReplay, startWith, Subject, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { AdminPermission } from '../../../constants/admin-permission.constant';
import { DBSubCap } from '../../../types/mongodb.type';
import { coerceBooleanProperty } from '../../../utils/coerce-boolean-property';
import { permissionToRegex, showErrorSnackbar } from '../../../utils/etc.util';
import { AdminService } from '../../services/admin';
import { ApiService } from '../../services/api';

interface PermissionType { canListSubCap: boolean; canReadSubCap: boolean; }
type ValueType = string | Array<string> | undefined;
type ValueArrayType = Array<string>;
type SubCapRowFound = WithId<DBSubCap> & { found: true; };
type SubCapRowNotFound = Pick<WithId<DBSubCap>, '_id'> & { found: false; };
type SubCapRow = SubCapRowFound | SubCapRowNotFound;

@Component({
  selector: 'app-input-sub-cap-id',
  templateUrl: './input-sub-cap-id.component.html',
  styleUrls: ['./input-sub-cap-id.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSubCapIdComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSubCapIdComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label: string;
  @Input() labelText: string;
  get clearable(): boolean { return this.clearableSubject.value; }
  @Input() set clearable(value: boolean) { this.clearableSubject.next(coerceBooleanProperty(value)); }
  get multiple(): boolean { return this.multipleSubject.value; }
  @Input() set multiple(value: boolean) { this.multipleSubject.next(coerceBooleanProperty(value)); }
  get value(): ValueType { return this.valueSubject.value; }
  @Input() set value(value: ValueType) { this.writeValue(value); this.onChangeCallback?.(this.value); }
  get valueCommaString(): string { const value = this.valueSubject.value; return (Array.isArray(value) ? value : [value]).join(', '); }
  @Input() set valueCommaString(value: string) { this.writeValueCommaString(value); this.onChangeCallback?.(this.value); }
  get disabled(): boolean { return this.disabledSubject.value; }
  @Input() set disabled(value: boolean) { this.disabledSubject.next(coerceBooleanProperty(value)); }

  permissions: PermissionType = { canListSubCap: false, canReadSubCap: false };
  subCapList: Array<SubCapRow>;

  readonly clearable$: Observable<boolean>;
  readonly multiple$: Observable<boolean>;
  readonly value$: Observable<ValueType>;
  readonly valueArray$: Observable<ValueArrayType>;
  readonly disabled$: Observable<boolean>;
  readonly permissions$: Observable<PermissionType>;
  readonly canListSubCap$: Observable<boolean>;
  readonly searchSubCapList$: Observable<Array<WithId<DBSubCap>>>;
  readonly selectedSubCap$: Observable<Array<SubCapRow>>;
  readonly subCapList$: Observable<Array<SubCapRow>>;

  private onChangeCallback: (value: ValueType) => void;
  private onTouchedCallback: () => void;

  private ngOnDestroySubject = new Subject<void>();
  private clearableSubject = new BehaviorSubject<boolean>(false);
  private multipleSubject = new BehaviorSubject<boolean>(false);
  private valueSubject = new BehaviorSubject<ValueType>(undefined);
  private disabledSubject = new BehaviorSubject<boolean>(false);
  private searchInputSubject = new BehaviorSubject<string>('');

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    private apiService: ApiService,
    private adminService: AdminService,
  ) {
    this.clearable$ = from(this.clearableSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.multiple$ = from(this.multipleSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.value$ = from(this.valueSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.valueArray$ = this.value$.pipe(
      map((value) => Array.isArray(value) ? value : value != null ? [value] : []),
    );
    this.disabled$ = from(this.disabledSubject).pipe(takeUntil(this.ngOnDestroySubject));

    this.permissions$ = this.adminService.getPermissionListObservable().pipe(
      map(({ permissionList }) => {
        const permissions = permissionList?.map((permission) => permissionToRegex(permission)) ?? [];
        return {
          canListSubCap: permissions.some((permission) => permission.test(AdminPermission.SUB_CAP_LIST)),
          canReadSubCap: permissions.some((permission) => permission.test(AdminPermission.SUB_CAP_READ)),
        };
      }),
      takeUntil(this.ngOnDestroySubject),
      endWith({ canListSubCap: false, canReadSubCap: false }),
      shareReplay(1),
    );

    this.searchSubCapList$ = this.permissions$.pipe(
      switchMap(({ canListSubCap, canReadSubCap }) => canListSubCap && canReadSubCap ?
        from(this.searchInputSubject).pipe(
          distinctUntilChanged(),
          debounceTime(500),
          switchMap((search) => this.apiService.subCapV1SubCapList({ page: 1, title: search || undefined })),
          map((res) => res.result.list),
          catchError((err) => {
            showErrorSnackbar(this.matSnackBar, err);
            return of([] as Array<WithId<DBSubCap>>);
          }),
        ) :
        of([] as Array<WithId<DBSubCap>>),
      ),
      startWith([] as Array<WithId<DBSubCap>>),
      takeUntil(this.ngOnDestroySubject),
      shareReplay(1),
    );

    this.selectedSubCap$ = combineLatest({
      permissions: this.permissions$,
      values: this.valueArray$,
    }).pipe(
      withLatestFrom(this.searchSubCapList$),
      scan((oldValues, [{ permissions: { canListSubCap, canReadSubCap }, values }, searchSubCapList]) => {
        if (!canListSubCap || !canReadSubCap) {
          return new Map<string, Observable<SubCapRow>>();
        }

        const values2 = new Map<string, Observable<SubCapRow>>();
        for (const value of values) {
          if (oldValues.has(value)) {
            values2.set(value, oldValues.get(value)!);
            continue;
          }

          const searchSubCap = searchSubCapList.find((item) => item._id === value);
          values2.set(value, this.apiService.subCapV1SubCapRead({ _id: value }).pipe(
            map((res) => res.result.subCap),
            startWith(searchSubCap),
            map((value2) => value2 != null ?
              {
                ...value2,
                found: true,
              } as SubCapRowFound : {
                _id: value,
                found: false,
              } as SubCapRowNotFound,
            ),
            shareReplay(1),
          ));
        }
        return values2;
      }, new Map<string, Observable<SubCapRow>>()),
      map((value) => Array.from(value.values())),
      switchMap((value) => value.length ? combineLatest(value) : of([])),
      takeUntil(this.ngOnDestroySubject),
      shareReplay(1),
    );

    this.subCapList$ = combineLatest({
      list: this.searchSubCapList$,
      values: this.valueArray$,
      selectedList: this.selectedSubCap$,
    }).pipe(
      auditTime(0),
      map(({ list, values, selectedList }) => ([] as Array<SubCapRow>).concat(
        selectedList,
        list
          .filter((item) => !values.includes(item._id))
          .map((item) => ({ ...item, found: true })),
      )),
      takeUntil(this.ngOnDestroySubject),
      shareReplay(1),
    );
  }

  ngOnInit(): void {
    this.permissions$.subscribe((permissions) => {
      this.permissions = permissions;
      this.changeDetectorRef.markForCheck();
    });

    this.subCapList$.subscribe((subCapList) => {
      this.subCapList = subCapList;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.ngOnDestroySubject.next();
    this.ngOnDestroySubject.complete();
  }

  onInput(ev: Event): void {
    const elem = ev.target as HTMLInputElement;
    const value = elem.value ?? '';
    this.writeValueCommaString(value);
    this.onChangeCallback?.(this.value);
  }

  onBlur(): void {
    this.onTouchedCallback?.();
  }

  onClickClear(ev: Event): void {
    ev.stopPropagation();
    this.value = undefined;
    this.changeDetectorRef.markForCheck();
  }

  onOpenedChange(input: HTMLInputElement, open: boolean): void {
    if (open) {
      input.focus();
      input.value = '';
      this.searchInputSubject.next('');
    }
  }

  searchWork(text: string): void {
    this.searchInputSubject.next(text ?? '');
  }

  trackSubCap(_: number, value: SubCapRow): string {
    return value._id;
  }

  writeValueCommaString(value: string): void {
    this.writeValue(value.split(',').filter((id) => id).map((id) => id.trim()));
  }

  writeValue(value: ValueType): void {
    this.valueSubject.next(value);
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(callback: (value: ValueType) => void): void {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
