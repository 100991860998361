import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { WithId } from 'mongodb';
import { DBMedia } from '../../../../types/mongodb.type';
import { MediaUploading } from '../../../services/upload';
import { ContentVideo } from '../../content-viewer/content-video';

@Component({
  selector: 'app-content-video-edit',
  templateUrl: './content-video-edit.component.html',
  styleUrls: ['./content-video-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentVideoEditComponent implements OnChanges {
  @Input() cut: ContentVideo;
  @Input() mediaList: Array<WithId<DBMedia | MediaUploading>>;

  videoList: Array<WithId<DBMedia>>;

  selectedVideo?: WithId<DBMedia> | null;
  controls: boolean;
  muted: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cut) {
      this.updateSelectedVideo();
      this.cut.data.muted = this.cut.data?.muted ?? true;
      this.cut.data.loop = this.cut.data?.loop ?? true;
    }
    if (changes.mediaList) {
      this.videoList = (this.mediaList || []).filter((media) => media.isUploaded && media.type.startsWith('video'));
      this.updateSelectedVideo();
    }
  }

  onChangeVideo(ev: MatSelectChange): void {
    const data = this.cut?.data;
    if (data && typeof data === 'object') {
      const media = ev.value as WithId<DBMedia>;
      this.cut.data.mediaId = media._id;
      this.cut.data.url = media.url!;
      this.cut.data.posterUrl = media.posterUrl ?? undefined;
      this.cut.width = media.width;
      this.cut.height = media.height;
    }
  }

  private updateSelectedVideo(): void {
    if (this.cut && this.videoList) {
      this.selectedVideo = this.videoList.find((video) => video._id === this.cut?.data?.mediaId);
    }
  }
}
