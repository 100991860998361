import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Renderer2, RendererStyleFlags2, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { GuardsCheckStart, Router } from '@angular/router';
import { fromEvent, NEVER } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { MAT_ICON_LIST } from './constants/mat-icon.constant';
import { BusyGuard } from './shared/guards/busy';
import { AdminService } from './shared/services/admin';
import { ApiService } from './shared/services/api';
import { AuthService } from './shared/services/auth';
import { BusyService } from './shared/services/busy';
import { isMobileScreen } from './utils/etc.util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('sidenavContent', { static: true }) sidenavContent: MatSidenavContent;

  title = 'Waikiki Backoffice';
  sidenavOpenedFirst = !isMobileScreen();

  private authed = false;
  private resizeObserver: ResizeObserver;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer2: Renderer2,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private authService: AuthService,
    private apiService: ApiService,
    private adminService: AdminService,
    private busyService: BusyService,
  ) {}

  ngOnInit(): void {
    this.enableContentSize();
    this.registerIcons();
    this.initAuthService();
    this.enableLogoutOnUnauthorized();
    this.preventGoBackWhileBusy();
  }

  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
  }

  onClickNavLink(): void {
    this.sidenav?.close();
  }

  private enableContentSize(): void {
    this.resizeObserver = new ResizeObserver((entries) => {
      const elem = this.elementRef.nativeElement;
      for (const entry of entries) {
        this.renderer2.setStyle(elem, '--content-width', `${entry.contentRect.width}px`, RendererStyleFlags2.DashCase);
        this.renderer2.setStyle(elem, '--content-height', `${entry.contentRect.height}px`, RendererStyleFlags2.DashCase);
      }
    });
    this.resizeObserver.observe(this.sidenavContent.getElementRef().nativeElement);
  }

  private registerIcons(): void {
    for (const icon of MAT_ICON_LIST) {
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/mat-icon/${icon}.svg`));
    }
  }

  private async initAuthService(): Promise<void> {
    await this.authService.init();
    this.authService.getAccessTokenObservable().subscribe((accessToken) => {
      this.authed = !!accessToken;
      this.apiService.setAuthToken(accessToken);
      this.adminService.updatePermissionList(this.authed);
    });
    this.authService.getAccessTokenObservable().pipe(first()).subscribe((accessToken) => {
      if (accessToken) {
        this.apiService.setAuthToken(accessToken);
        this.authService.renewToken();
      }
    });
  }

  private async enableLogoutOnUnauthorized(): Promise<void> {
    this.apiService.getUnauthorizedObservable().subscribe(() => {
      if (this.authed) {
        this.authService.logout().then(() => {
          const urlMatch = this.router.url.match(/^[^?]+/);
          if (urlMatch?.[0] !== '/login') {
            this.router.navigateByUrl(`/login?r=${encodeURIComponent(this.router.url)}`);
          }
        });
      }
    });
  }

  private preventGoBackWhileBusy(): void {
    this.busyService.busy$.pipe(
      switchMap((busy) => busy ? fromEvent(window, 'beforeunload') : NEVER),
    ).subscribe((ev) => {
      ev.preventDefault();
      (ev as any).returnValue = '';
    });

    this.router.events.pipe(
      filter((ev): ev is GuardsCheckStart => ev instanceof GuardsCheckStart),
    ).subscribe((ev) => {
      let child = ev.state.root;
      while (child?.firstChild) {
        child = child.firstChild
      }
      if (child?.routeConfig && !child.routeConfig.canDeactivate?.includes(BusyGuard)) {
        child.routeConfig.canDeactivate = [...child.routeConfig.canDeactivate ?? [], BusyGuard];
      }
    });
  }
}
