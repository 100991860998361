/**
 * 입력값을 정수로 변환합니다.
 * @param input 정수로 변환할 값
 * @param radix 기수 (기본값: `10`)
 * @param fallback 변환 실패 시 사용할 값
 */
export function parseInt(input: any, radix: number): number;
export function parseInt<T, U = T extends undefined ? never : T>(input: any, radix: number, fallback: U): number | U;
export function parseInt<T, U = T extends undefined ? never : T>(input: any, radix: number = 10, fallback?: U): number | U {
  if (typeof input === 'number') { return Math.floor(input); }

  let num = NaN;
  try {
    num = Number.parseInt(input + '', radix);
  } catch {
    // noop
  }
  return typeof num === 'number' && !isNaN(num) ? num : fallback !== undefined ? fallback : NaN;
}

/**
 * 입력값을 실수로 변환합니다.
 * @param input 실수로 변환할 값
 * @param fallback 변환 실패 시 사용할 값
 */
export function parseFloat(input: any): number;
export function parseFloat<T, U = T extends undefined ? never : T>(input: any, fallback: U): number | U;
export function parseFloat<T, U = T extends undefined ? never : T>(input: any, fallback?: U): number | U {
  if (typeof input === 'number') { return input; }

  let num = NaN;
  try {
    num = Number.parseFloat(input + '');
  } catch {
    // noop
  }
  return typeof num === 'number' && !isNaN(num) ? num : fallback !== undefined ? fallback : NaN;
}

/**
 * min과 max 사이로 고정된 입력값을 가져옵니다.
 * @param val 고정될 값
 * @param min 최소
 * @param max 최대
 */
export function clamp(val: number, min: number, max: number): number {
  if (min > max) {
    // eslint-disable-next-line no-param-reassign
    [min, max] = [max, min];
  }
  return Math.min(Math.max(val, min), max);
}
