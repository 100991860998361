import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ContentViewerModule } from '../content-viewer';
import { ContentPreviewModalComponent } from './content-preview-modal.component';

@NgModule({
  declarations: [
    ContentPreviewModalComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    ContentViewerModule,
  ],
  exports: [
    ContentPreviewModalComponent,
  ],
})
export class ContentPreviewModalModule {}
