import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VideoPreviewComponent } from './video-preview.component';

@NgModule({
  declarations: [
    VideoPreviewComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    VideoPreviewComponent,
  ],
})
export class VideoPreviewModule {}
