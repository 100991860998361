<div class="date-range-picker-common">
  <div class="inner">
    <mat-form-field>
      <mat-date-range-input [rangePicker]="picker" [disabled]="disabled">
        <input matStartDate [placeholder]="startPlaceholder" [value]="startDate" (dateChange)="startDate = $event.value">
        <input matEndDate [placeholder]="endPlaceholder" [value]="endDate" (dateChange)="endDate = $event.value">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <button matSuffix mat-icon-button class="date-range-preset-toggle" [class.date-range-preset-toggle-active]="presetOpened" [disabled]="disabled" (click)="onClickOpenPreset($event)">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-date-range-picker #picker [xPosition]="xPosition" [yPosition]="yPosition" (opened)="openedStream.emit()" (closed)="closedStream.emit()"></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>
