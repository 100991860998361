import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isMobileScreen, permissionToRegex } from '../../../utils/etc.util';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';
import { AdminService } from '../../services/admin';
import { AuthService } from '../../services/auth';
import { NavigationItem } from './navigation-item.type';
import { ALL_NAVIGATIONS } from './navigation.constants';

function updateBlockedDeep(navs: Array<NavigationItem>, permissions: Array<RegExp>): void {
  for (const nav of navs) {
    nav.blocked = !(nav.requiredPermissions || []).every((ptc) => permissions.some((permission) => permission.test(ptc)));

    if (nav.subItems) {
      updateBlockedDeep(nav.subItems, permissions);
      nav.blocked = nav.blocked && nav.subItems.every((item) => item.blocked);
    }
  }
}

function openActiveNavs(router: Router, navs: Array<NavigationItem>): void {
  if (!Array.isArray(navs)) {
    return;
  }
  for (const nav of navs) {
    if (router.isActive(nav.calculatedPath!, false)) {
      nav.isOpen = true;
      if (Array.isArray(nav.subItems)) {
        openActiveNavs(router, nav.subItems);
      }
    }
  }
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Output() clickLink = new EventEmitter<void>();

  navs: Array<NavigationItem>;

  isAuthed: boolean;

  private routerEventsSubscription: Subscription;
  private accessTokenSubscription: Subscription;
  private permissionListSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService,
    private adminService: AdminService,
  ) {}

  ngOnInit(): void {
    this.navs = ALL_NAVIGATIONS;

    this.routerEventsSubscription = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        openActiveNavs(this.router, this.navs);
        this.changeDetectorRef.markForCheck();
      }
    });

    this.accessTokenSubscription = this.authService.getAccessTokenObservable().subscribe((accessToken) => {
      this.isAuthed = !!accessToken;
      this.changeDetectorRef.markForCheck();
    });

    this.permissionListSubscription = this.adminService.getPermissionListObservable().subscribe((permissionListUpdate) => {
      if (permissionListUpdate.permissionList) {
        const permissions = permissionListUpdate.permissionList.map((permission) => permissionToRegex(permission));
        updateBlockedDeep(this.navs, permissions);
      } else {
        updateBlockedDeep(this.navs, [permissionToRegex('*')]);
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.routerEventsSubscription,
      this.accessTokenSubscription,
      this.permissionListSubscription,
    ])
  }

  onClickExpand(ev: MouseEvent, item: NavigationItem): void {
    ev.stopPropagation();
    item.isOpen = !item.isOpen;
  }

  onClickLink(): void {
    if (isMobileScreen()) {
      this.clickLink.emit();
    }
  }

  onClickLogout(): void {
    this.authService.logout().then(() => {
      if (this.router.url !== '/login') {
        this.router.navigateByUrl('/login');
      }
    });

    if (isMobileScreen()) {
      this.clickLink.emit();
    }
  }
}
