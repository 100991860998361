import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WithId } from 'mongodb';
import { auditTime, BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, endWith, from, map, Observable, of, scan, shareReplay, startWith, Subject, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { AdminPermission } from '../../../constants/admin-permission.constant';
import { DBWork } from '../../../types/mongodb.type';
import { coerceBooleanProperty } from '../../../utils/coerce-boolean-property';
import { permissionToRegex, showErrorSnackbar } from '../../../utils/etc.util';
import { AdminService } from '../../services/admin';
import { ApiService } from '../../services/api';

interface PermissionType { canListWork: boolean; canReadWork: boolean; }
type ValueType = string | Array<string> | undefined;
type ValueArrayType = Array<string>;
type WorkRowFound = WithId<DBWork> & { found: true; };
type WorkRowNotFound = Pick<WithId<DBWork>, '_id'> & { found: false; };
type WorkRow = WorkRowFound | WorkRowNotFound;

@Component({
  selector: 'app-input-work-id',
  templateUrl: './input-work-id.component.html',
  styleUrls: ['./input-work-id.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWorkIdComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputWorkIdComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label: string;
  @Input() labelText: string;
  get clearable(): boolean { return this.clearableSubject.value; }
  @Input() set clearable(value: boolean) { this.clearableSubject.next(coerceBooleanProperty(value)); }
  get multiple(): boolean { return this.multipleSubject.value; }
  @Input() set multiple(value: boolean) { this.multipleSubject.next(coerceBooleanProperty(value)); }
  get value(): ValueType { return this.valueSubject.value; }
  @Input() set value(value: ValueType) { this.writeValue(value); this.onChangeCallback?.(this.value); }
  get valueCommaString(): string { const value = this.valueSubject.value; return (Array.isArray(value) ? value : [value]).join(', '); }
  @Input() set valueCommaString(value: string) { this.writeValueCommaString(value); this.onChangeCallback?.(this.value); }
  get disabled(): boolean { return this.disabledSubject.value; }
  @Input() set disabled(value: boolean) { this.disabledSubject.next(coerceBooleanProperty(value)); }

  permissions: PermissionType = { canListWork: false, canReadWork: false };
  workList: Array<WorkRow>;

  readonly clearable$: Observable<boolean>;
  readonly multiple$: Observable<boolean>;
  readonly value$: Observable<ValueType>;
  readonly valueArray$: Observable<ValueArrayType>;
  readonly disabled$: Observable<boolean>;
  readonly permissions$: Observable<PermissionType>;
  readonly canListWork$: Observable<boolean>;
  readonly searchWorkList$: Observable<Array<WithId<DBWork>>>;
  readonly selectedWork$: Observable<Array<WorkRow>>;
  readonly workList$: Observable<Array<WorkRow>>;

  private onChangeCallback: (value: ValueType) => void;
  private onTouchedCallback: () => void;

  private ngOnDestroySubject = new Subject<void>();
  private clearableSubject = new BehaviorSubject<boolean>(false);
  private multipleSubject = new BehaviorSubject<boolean>(false);
  private valueSubject = new BehaviorSubject<ValueType>(undefined);
  private disabledSubject = new BehaviorSubject<boolean>(false);
  private searchInputSubject = new BehaviorSubject<string>('');

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    private apiService: ApiService,
    private adminService: AdminService,
  ) {
    this.clearable$ = from(this.clearableSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.multiple$ = from(this.multipleSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.value$ = from(this.valueSubject).pipe(takeUntil(this.ngOnDestroySubject));
    this.valueArray$ = this.value$.pipe(
      map((value) => Array.isArray(value) ? value : value != null ? [value] : []),
    );
    this.disabled$ = from(this.disabledSubject).pipe(takeUntil(this.ngOnDestroySubject));

    this.permissions$ = this.adminService.getPermissionListObservable().pipe(
      map(({ permissionList }) => {
        const permissions = permissionList?.map((permission) => permissionToRegex(permission)) ?? [];
        return {
          canListWork: permissions.some((permission) => permission.test(AdminPermission.SUB_CAP_LIST)),
          canReadWork: permissions.some((permission) => permission.test(AdminPermission.SUB_CAP_READ)),
        };
      }),
      takeUntil(this.ngOnDestroySubject),
      endWith({ canListWork: false, canReadWork: false }),
      shareReplay(1),
    );

    this.searchWorkList$ = this.permissions$.pipe(
      switchMap(({ canListWork, canReadWork }) => canListWork && canReadWork ?
        from(this.searchInputSubject).pipe(
          distinctUntilChanged(),
          debounceTime(500),
          switchMap((search) => this.apiService.workV1WorkList({ page: 1, title: search || undefined })),
          map((res) => res.result.list),
          catchError((err) => {
            showErrorSnackbar(this.matSnackBar, err);
            return of([] as Array<WithId<DBWork>>);
          }),
        ) :
        of([] as Array<WithId<DBWork>>),
      ),
      startWith([] as Array<WithId<DBWork>>),
      takeUntil(this.ngOnDestroySubject),
      shareReplay(1),
    );

    this.selectedWork$ = combineLatest({
      permissions: this.permissions$,
      values: this.valueArray$,
    }).pipe(
      withLatestFrom(this.searchWorkList$),
      scan((oldValues, [{ permissions: { canListWork, canReadWork }, values }, searchWorkList]) => {
        if (!canListWork || !canReadWork) {
          return new Map<string, Observable<WorkRow>>();
        }

        const values2 = new Map<string, Observable<WorkRow>>();
        for (const value of values) {
          if (oldValues.has(value)) {
            values2.set(value, oldValues.get(value)!);
            continue;
          }

          const searchWork = searchWorkList.find((item) => item._id === value);
          values2.set(value, this.apiService.workV1WorkRead({ _id: value }).pipe(
            map((res) => res.result.work),
            startWith(searchWork),
            map((value2) => value2 != null ?
              {
                ...value2,
                found: true,
              } as WorkRowFound : {
                _id: value,
                found: false,
              } as WorkRowNotFound,
            ),
            shareReplay(1),
          ));
        }
        return values2;
      }, new Map<string, Observable<WorkRow>>()),
      map((value) => Array.from(value.values())),
      switchMap((value) => value.length ? combineLatest(value) : of([])),
      takeUntil(this.ngOnDestroySubject),
      shareReplay(1),
    );

    this.workList$ = combineLatest({
      list: this.searchWorkList$,
      values: this.valueArray$,
      selectedList: this.selectedWork$,
    }).pipe(
      auditTime(0),
      map(({ list, values, selectedList }) => ([] as Array<WorkRow>).concat(
        selectedList,
        list
          .filter((item) => !values.includes(item._id))
          .map((item) => ({ ...item, found: true })),
      )),
      takeUntil(this.ngOnDestroySubject),
      shareReplay(1),
    );
  }

  ngOnInit(): void {
    this.permissions$.subscribe((permissions) => {
      this.permissions = permissions;
      this.changeDetectorRef.markForCheck();
    });

    this.workList$.subscribe((workList) => {
      this.workList = workList;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.ngOnDestroySubject.next();
    this.ngOnDestroySubject.complete();
  }

  onInput(ev: Event): void {
    const elem = ev.target as HTMLInputElement;
    const value = elem.value ?? '';
    this.writeValueCommaString(value);
    this.onChangeCallback?.(this.value);
  }

  onBlur(): void {
    this.onTouchedCallback?.();
  }

  onClickClear(ev: Event): void {
    ev.stopPropagation();
    this.value = undefined;
    this.changeDetectorRef.markForCheck();
  }

  onOpenedChange(input: HTMLInputElement, open: boolean): void {
    if (open) {
      input.focus();
      input.value = '';
      this.searchInputSubject.next('');
    }
  }

  searchWork(text: string): void {
    this.searchInputSubject.next(text ?? '');
  }

  trackWork(_: number, value: WorkRow): string {
    return value._id;
  }

  writeValueCommaString(value: string): void {
    this.writeValue(value.split(',').filter((id) => id).map((id) => id.trim()));
  }

  writeValue(value: ValueType): void {
    this.valueSubject.next(value);
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(callback: (value: ValueType) => void): void {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
