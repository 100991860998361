<ul class="page-list">
  <li>
    <button mat-icon-button [disabled]="this.page < 2" (click)="onClickPrev()">
      <mat-icon>navigate_before</mat-icon>
    </button>
  </li>
  <li *ngFor="let pageNum of pageNumList" >
    <button mat-icon-button [color]="pageNum === page ? 'primary' : 'base'" (click)="onClickPageNum(pageNum)">
      {{ pageNum }}
    </button>
  </li>
  <li>
    <button mat-icon-button [disabled]="this.page > this.endPage - 1" (click)="onClickNext()">
      <mat-icon>navigate_next</mat-icon>
    </button>
  </li>
</ul>
