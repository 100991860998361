<div class="select-media" (dragenter)="onDragEnterMediaList($event, 0)" (dragover)="onDragOverMediaList($event, 0)" (drop)="onDropMediaList($event, 0)">
  <button class="open-dialog-button" [disabled]="disabled" (click)="openDialog()" (blur)="onBlur()">
    <input type="file" [id]="'upload-media-' + random" name="upload-media" [attr.multiple]="multiple ? '' : null" [disabled]="disabled || readonly" (change)="onChangeFile($event)">
    <div class="label-text">{{ label }}</div>
    <div class="select-media-list" [class.multiple]="multiple">
      <div *ngFor="let media of getSelectedMediaList()" class="media-preview-box">
        <img *ngIf="!media.type.startsWith('video')" [src]="media.dataUrl || media.url">
        <app-video-preview *ngIf="media.type.startsWith('video')" [src]="media.dataUrl || media.url"></app-video-preview>
        <button mat-button *ngIf="clearable != null && clearable !== false" class="clear-button" mat-icon-button aria-label="Clear" [disabled]="disabled" (click)="onClickUnselect($event, media._id)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div *ngIf="!getSelectedMediaList()?.length" class="media-preview-box">
        <div class="add-icon-box">
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </div>
  </button>
  <div *ngIf="isDraggingOver[0]" class="media-drop-overlay" (dragleave)="onDragLeaveMediaList($event, 0)" (click)="onClickDropOverlay(0)">{{ getMediaTypeName() }} 파일을 놓아 추가</div>
</div>

<ng-template #dialogTemplate>
  <div class="select-media-dialog" (dragenter)="onDragEnterMediaList($event, 1)" (dragover)="onDragOverMediaList($event, 1)" (drop)="onDropMediaList($event, 1)">
    <input type="file" [id]="'upload-media-' + random + '-dialog'" name="upload-media" [attr.multiple]="multiple ? '' : null" [disabled]="disabled || readonly" (change)="onChangeFile($event)">
    <div class="label-text">{{ label }}</div>
    <div class="select-media-option-list">
      <label #uploadMediaLabel [for]="'upload-media-' + random + '-dialog'">
        <div class="media-preview-box">
          <div class="add-icon-box">
            <mat-icon>add</mat-icon>
          </div>
        </div>
      </label>
      <button *ngFor="let media of mediaUploadingList; trackBy: trackMedia" class="select-media-option" [class.selected]="hasValue(media._id)" [disabled]="disabled" (click)="onClickSelectMediaUploading(media._id)">
        <div class="media-preview-box">
          <img *ngIf="!media.type.startsWith('video')" [src]="media.dataUrl || media.url">
          <app-video-preview *ngIf="media.type.startsWith('video')" [src]="media.dataUrl || media.url"></app-video-preview>
          <div *ngIf="media.progress != null" class="media-preview-uploading">업로드하는 중... ({{ formatPercentage(media.progress) }})</div>
        </div>
        <div class="media-name">{{ media.name }}</div>
      </button>
      <button *ngFor="let media of mediaList; trackBy: trackMedia" class="select-media-option" [class.selected]="hasValue(media._id)" [disabled]="disabled" (click)="onClickSelectMedia(media._id)">
        <div class="media-preview-box">
          <img *ngIf="!media.type.startsWith('video')" [src]="media.dataUrl || media.url">
          <app-video-preview *ngIf="media.type.startsWith('video')" [src]="media.dataUrl || media.url"></app-video-preview>
          <button mat-button *ngIf="!disabled && !readonly" class="delete-button" mat-icon-button aria-label="Delete Forever" (click)="onClickDeleteMedia($event, media._id)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
        <div class="media-name">{{ media.name }}</div>
      </button>
    </div>
    <div *ngIf="isDraggingOver[1]" class="media-drop-overlay" (dragleave)="onDragLeaveMediaList($event, 1)" (click)="onClickDropOverlay(1)">{{ getMediaTypeName() }} 파일을 놓아 추가</div>
  </div>
</ng-template>
