import { Pipe, PipeTransform } from '@angular/core';
import { parseFloat } from '../../../utils/number.util';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;

@Pipe({
  name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform {
  /**
   * `number` 또는 `string` 형식의 기간을 문자열로 변환합니다.
   * @param value 기간값
   */
  transform(value: number | string | null | undefined): string | null {
    if (value == null) {
      return null;
    }

    let num = parseFloat(value, null);

    if (num == null || Number.isNaN(num)) {
      return null;
    }

    num = Math.round(num);

    const sign = Math.sign(num);
    num = Math.abs(num);

    const second = Math.round(num) % ONE_MINUTE / ONE_SECOND;
    const minute = Math.floor(num / ONE_MINUTE) % (ONE_HOUR / ONE_MINUTE);
    const hour = Math.floor(num / ONE_HOUR) % (ONE_DAY / ONE_HOUR);
    const day = Math.floor(num / ONE_DAY);

    let result = '';
    if (day) {
      result += day + '일 ';
    }
    if (hour) {
      result += hour + '시간 ';
    }
    if (minute) {
      result += minute + '분 ';
    }
    if (second) {
      result += second + '초 ';
    }

    return (sign === -1 ? '-' : '') + (result.trim() || '0초');
  }
}
