import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { showErrorSnackbar } from '../../../utils/etc.util';
import { ApiService } from '../api';
import { PermissionListUpdate } from './admin.type';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private isUpdatingPermissionList = false;

  private permissionListUpdatePromise: Promise<Array<string> | null> | null;

  private permissionListSubject = new BehaviorSubject<PermissionListUpdate>({ state: 'idle', permissionList: null });

  constructor(
    private matSnackBar: MatSnackBar,
    private apiService: ApiService,
  ) {}

  /**
   * 현재 권한 목록을 반환합니다.
   */
  getPermissionList(): Array<string> | null {
    return this.permissionListSubject.value?.permissionList;
  }

  /**
   * 현재 권한 목록이 변경될 때 새 권한 목록을 방출합니다.
   * `subscribe` 시 현재 사용중인 권한 목록을 방출합니다.
   */
  getPermissionListObservable(): Observable<PermissionListUpdate> {
    return this.permissionListSubject.asObservable();
  }

  /**
   * 권한 목록을 다시 불러옵니다.
   * @param isAuthed 로그인 여부
   */
  async updatePermissionList(isAuthed: boolean): Promise<Array<string> | null> {
    if (this.isUpdatingPermissionList) {
      return this.permissionListUpdatePromise;
    }

    if (!isAuthed) {
      this.permissionListSubject.next({
        state: 'idle',
        permissionList: null,
      });
      return null;
    }

    this.isUpdatingPermissionList = true;
    this.permissionListSubject.next({
      state: 'updating',
      permissionList: this.permissionListSubject.value?.permissionList,
    });

    this.permissionListUpdatePromise = firstValueFrom(this.apiService.accountV1PermissionList())
      .then((res) => res.result.permissionList, (err) => {
        showErrorSnackbar(this.matSnackBar, err);
        return this.permissionListSubject.value?.permissionList;
      });
    const newPermissionList = await this.permissionListUpdatePromise;
    this.permissionListSubject.next({
      state: 'idle',
      permissionList: newPermissionList,
    });

    this.permissionListUpdatePromise = null;
    this.isUpdatingPermissionList = false;
    return newPermissionList;
  }
}
