import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormatNumberModule } from '../../pipes/format-number';
import { InputTicketBuyOptionIdComponent } from './input-ticket-buy-option-id.component';

@NgModule({
  declarations: [
    InputTicketBuyOptionIdComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    FormatNumberModule,
  ],
  exports: [
    InputTicketBuyOptionIdComponent,
  ],
})
export class InputTicketBuyOptionIdModule {}
