import { Component, ChangeDetectionStrategy, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPreviewComponent {
  @ViewChild('videoPreview', { static: true }) video: ElementRef<HTMLVideoElement>;

  @Input() poster: string;
  @Input() src: string;

  progressPercentage = 0;

  private isHover = false;

  onLoadedMetadata(): void {
    const video = this.video.nativeElement;
    if (video.paused && this.isHover) {
      video.play();
    }
  }

  onMouseEnter(): void {
    this.isHover = true;
    const video = this.video.nativeElement;
    if (video.paused) {
      video.play();
    }
  }

  onMouseLeave(): void {
    this.isHover = false;
    this.progressPercentage = 0;
    const video = this.video.nativeElement;
    if (!video.paused) {
      video.pause();
    }
  }

  onTimeUpdate(ev: Event): void {
    const video = ev.target as HTMLVideoElement;
    this.progressPercentage = this.isHover && video.duration ? Math.min(Math.max(video.currentTime / video.duration * 100, 0), 100) : 0;
  }
}
