<mat-toolbar>
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <img class="logo" src="assets/logo.svg" [alt]="title">
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" [opened]="sidenavOpenedFirst">
    <app-navigation (clickLink)="onClickNavLink()"></app-navigation>
  </mat-sidenav>
  <mat-sidenav-content #sidenavContent>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
