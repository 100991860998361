import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';
import { ContentPreviewCloseEventDetail, ContentPreviewOptions } from '../../services/content-preview';
import { ContentCut, ContentDirection } from '../content-viewer';

const BACKDROP = 'backdrop';

@Component({
  selector: 'app-content-preview-modal',
  templateUrl: './content-preview-modal.component.html',
  styleUrls: ['./content-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPreviewModalComponent implements OnInit, OnDestroy {
  contents: Array<ContentCut>;
  direction: ContentDirection = 'vertical';

  private keydownEventsSubscription: Subscription;
  private backdropClickSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ContentPreviewOptions,
    private matDialogRef: MatDialogRef<ContentPreviewModalComponent, ContentPreviewCloseEventDetail>,
  ) {}

  ngOnInit(): void {
    this.contents = this.data.contents;
    this.direction = this.data.direction === 'horizontal' ? 'horizontal' : 'vertical';

    this.keydownEventsSubscription = this.matDialogRef.keydownEvents().subscribe((ev) => {
      if (ev.key === 'Escape') {
        this.close(undefined, BACKDROP);
      }
    });
    this.backdropClickSubscription = this.matDialogRef.backdropClick().subscribe(() => {
      this.close(undefined, BACKDROP);
    });
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.keydownEventsSubscription,
      this.backdropClickSubscription,
    ])
  }

  onClickClose(): void {
    this.close(undefined, 'ok');
  }

  close(data?: any, role?: string): void {
    this.matDialogRef.close({ data, role });
  }
}
