import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as ScrollMagic from 'scrollmagic';
import { ContentVideo } from './content-video.type';

@Component({
  selector: 'app-content-video',
  templateUrl: './content-video.component.html',
  styleUrls: ['./content-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentVideoComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @ViewChild('video', { static: true }) videoElem!: ElementRef<HTMLVideoElement>;

  @Input() cut?: ContentVideo;
  @Input() scrollMagic: ScrollMagic.Controller | null = null;
  @Input() slideVisible: boolean | null = null;
  @Input() download?: boolean;

  @Output() clickStatic = new EventEmitter<MouseEvent>();

  hasAudio = false;
  muted = false;
  mutedChanged = false;
  autoplayFailed = false;
  controlsList = 'noremoteplayback';

  private scene?: ScrollMagic.Scene;
  private lastHeight?: number;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    const elem: Element = this.elementRef.nativeElement;
    this.scene = new ScrollMagic.Scene({
      triggerElement: elem,
      duration: () => window.innerHeight / 2 + elem.clientHeight,
    })
    .on('enter leave', (ev) => {
      const video = this.videoElem.nativeElement;
      if (ev.type === 'enter' && (this.slideVisible == null || this.slideVisible)) {
        if (video.paused) {
          this.play();
        }
      } else {
        if (!video.paused) {
          video.pause();
        }
      }
    });
    this.lastHeight = elem.clientHeight;

    if (this.scrollMagic) {
      this.scrollMagic.addScene(this.scene);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cut && changes.cut.previousValue?.data.url !== changes.cut.currentValue?.data.url) {
      this.hasAudio = false;
      this.muted = this.cut?.data.muted ?? false;
      this.mutedChanged = false;
    }
    if (changes.scrollMagic) {
      const oldController: ScrollMagic.Controller = changes.scrollMagic.previousValue;
      const newController: ScrollMagic.Controller = changes.scrollMagic.currentValue;

      if (this.scene) {
        oldController?.removeScene(this.scene);
        newController?.addScene(this.scene);
      }
    }
    if (changes.slideVisible) {
      const video = this.videoElem.nativeElement;
      if (this.slideVisible == null || this.slideVisible) {
        if (video.paused) {
          this.play();
        }
      } else {
        if (!video.paused) {
          video.pause();
        }
      }
    }
    if (changes.download) {
      const controlsList = ['noremoteplayback'];
      if (!this.download) {
        controlsList.push('nodownload');
      }
      this.controlsList = controlsList.join(' ');
    }
  }

  ngAfterViewChecked(): void {
    const elem = this.elementRef.nativeElement;
    const newHeight = elem.clientHeight;

    if (this.lastHeight !== newHeight) {
      this.lastHeight = newHeight;
      this.scene?.duration(() => window.innerHeight / 2 + newHeight);
    }
  }

  ngOnDestroy(): void {
    if (this.scene) {
      this.scrollMagic?.removeScene(this.scene);
    }
  }

  onPlayingVideo(): void {
    const video = this.videoElem.nativeElement as HTMLVideoElement & {
      audioTracks?: Array<unknown>;
      webkitAudioDecodedByteCount?: number;
      mozHasAudio?: boolean;
    };
    this.hasAudio = Boolean(
      video.mozHasAudio ||
      video.webkitAudioDecodedByteCount ||
      video.audioTracks?.length
    );
    this.changeDetectorRef.markForCheck();
  }

  onClickVideo(ev: MouseEvent): void {
    const video = this.videoElem.nativeElement;
    if (!this.cut?.data.controls && video.paused) {
      video.play();
      ev.preventDefault();
      ev.stopPropagation();
    } else if (!this.cut?.data.controls) {
      this.clickStatic.emit(ev);
      ev.preventDefault();
      ev.stopPropagation();
    }
  }

  onClickMutedOverlay(ev: MouseEvent): void {
    this.mutedChanged = true;
    this.muted = false;
    ev.preventDefault();
    ev.stopPropagation();
  }

  onClickAutoplayFailedOverlay(ev: MouseEvent): void {
    this.autoplayFailed = false;
    if (!this.cut?.data.muted) {
      this.muted = false;
    }
    this.mutedChanged = true;
    this.play();
    ev.preventDefault();
    ev.stopPropagation();
  }

  private async play(): Promise<void> {
    const video = this.videoElem.nativeElement;

    if (this.muted) {
      if (this.hasAudio && !this.cut?.data.muted) {
        this.muted = false;
        this.changeDetectorRef.detectChanges();
      }
      await new Promise((resolve) => setTimeout(resolve, 0));
    }

    // try {
    //   await video.play();
    //   return;
    // } catch {}
    // this.muted = true;
    // this.changeDetectorRef.markForCheck();
    // await new Promise((resolve) => setTimeout(resolve, 0));

    try {
      await video.play();
      return;
    } catch {}
    this.autoplayFailed = true;
    this.changeDetectorRef.markForCheck();
  }
}
