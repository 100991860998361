import { Pipe, PipeTransform } from '@angular/core';
import { parseFloat } from '../../../utils/number.util';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {
  /**
   * 수를 퍼센트 표기법으로 변환합니다.
   * @param num 퍼센트로 표시할 수
   * @param fractionDigits 소수점 자리수
   */
  transform(num: number | string | null | undefined, fractionDigits: number = 0): string | null {
    const parsed = parseFloat(num, null);
    if (parsed == null) {
      return null;
    } else if (parsed === Number.POSITIVE_INFINITY) {
      return '∞%';
    } else if (parsed === Number.NEGATIVE_INFINITY) {
      return '-∞%';
    }
    return `${(Math.round(parsed * (10 ** (fractionDigits + 2))) / (10 ** fractionDigits)).toFixed(fractionDigits)}%`;
  }
}
