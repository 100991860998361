import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { VideoPreviewModule } from '../video-preview';
import { SelectMediaComponent } from './select-media.component';

@NgModule({
  declarations: [
    SelectMediaComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
    VideoPreviewModule,
  ],
  exports: [
    SelectMediaComponent,
  ],
})
export class SelectMediaModule {}
