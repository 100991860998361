<div class="modal-wrapper">
  <div class="modal">
    <div class="modal-title">{{ header }}</div>
    <div class="modal-content">
      <div class="alert-message">{{ message }}</div>
      <div *ngIf="inputs?.length" class="alert-inputs">
        <mat-form-field *ngFor="let input of inputs; let i = index" appearance="fill">
          <input
            matInput
            [type]="input.type || 'text'"
            [name]="input.name || i"
            [placeholder]="input.placeholder || ''"
            [value]="input.value || input.checked"
            [disabled]="!!input.disabled"
            [min]="input.min"
            [max]="input.max"
            (change)="onChangeInput(input, i, $event)">
        </mat-form-field>
      </div>
      <div class="alert-buttons">
        <ng-container *ngFor="let button of buttons" [ngSwitch]="buttonFill">
          <button *ngSwitchCase="solid" mat-raised-button color="primary" (click)="onClickButton(button)">{{ button.text }}</button>
          <button *ngSwitchCase="outline" mat-stroked-button color="primary" (click)="onClickButton(button)">{{ button.text }}</button>
          <button *ngSwitchDefault mat-button color="primary" (click)="onClickButton(button)">{{ button.text }}</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
