import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  /**
   * `DateTime`, `Date`, `string` 또는 `number` 형식의 날짜를 문자열로 변환합니다.
   * @param value 날짜값
   * @param type 종류 (default: `'datetime'`)
   * - `'date'` - 날짜만
   * - `'time'` - 시간만
   * - `'datetime'` - 날짜와 시간 모두
   */
  transform(
    value: DateTime | Date | string | number | null | undefined,
    type: 'date' | 'time' | 'datetime' | string = 'datetime',
  ): string | null {
    if (value == null) {
      return null;
    }

    const date = value instanceof DateTime ? value : DateTime.fromJSDate(new Date(value));

    if (type === 'date') {
      return date.toFormat('yyyy-MM-dd');
    } else if (type === 'time') {
      return date.toFormat('HH:mm:ss');
    }
    return date.toFormat(`yyyy-MM-dd HH:mm:ss`);
  }
}
