import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormatNumberDeltaPipe } from './format-number-delta.pipe';

@NgModule({
  declarations: [
    FormatNumberDeltaPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FormatNumberDeltaPipe,
  ],
})
export class FormatNumberDeltaModule {}
