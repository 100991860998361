import { ContentCut } from '../content-viewer.type';

export interface ContentImage extends ContentCut {
  type: 'image';
  data: {
    mediaId: string;
    url: string;
    link?: string;
  };
}
