import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WithId } from 'mongodb';
import { bufferCount, catchError, concatAll, concatMap, defer, filter, firstValueFrom, from, map, merge, mergeMap, Observable, of, pairwise, scan, share, startWith, throwError } from 'rxjs';
import { DBMedia } from '../../../types/mongodb.type';
import { isNotNullish } from '../../../utils/etc.util';
import { ApiService } from '../api';
import { Dimension, UploadProgress, UploadProgressComplete, UploadProgressError, UploadProgressFileRead, UploadProgressPrepare, UploadProgressProgress, UploadProgressUpload } from './upload.type';

function isNotUploadV2ProgressError<T>(uploadProgress: T): uploadProgress is Exclude<T, UploadProgressError> {
  return uploadProgress != null && !Object.prototype.hasOwnProperty.call(uploadProgress, 'error');
}


@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
  ) {}

  upload(
    files: File | Array<File> | FileList,
    uploadedContentType: string,
    uploadedContentId: string,
    categoryOrCategories?: string | Array<string>,
  ): Observable<UploadProgress> {
    return defer(() => {
      const fileList = this.normalizeFileList(files).map((file, index) => ({ uploadId: index, file }));
      const totalCount = fileList.length;

      const fileReadObservable = from(fileList).pipe(
        concatMap(({ uploadId, file }, index) => {
          if (file == null) {
            return of({ uploadId, error: new Error('Invalid file') } as UploadProgressError);
          }

          const baseType = file.type.split('/')[0];
          if (baseType !== 'image' && baseType !== 'video') {
            return of({ uploadId, error: new Error('Invalid media type') } as UploadProgressError);
          }

          const category = Array.isArray(categoryOrCategories) ? (categoryOrCategories[index] ?? 'media') : (categoryOrCategories ?? 'media');

          return from(this.readFileAsDataUrl(file)).pipe(
            concatMap((dataUrl) =>
              (baseType === 'video' ? this.getVideoDimension(dataUrl) : this.getImageDimension(dataUrl))
                .then((dimension) => ({ dataUrl, dimension })),
            ),
            map(({ dataUrl, dimension }) => ({
              uploadId,
              file,
              dataUrl,
              media: {
                name: file.name,
                category,
                type: file.type,
                width: dimension.width,
                height: dimension.height,
                uploadedContentType,
                uploadedContentId,
                progress: 0,
                dataUrl,
              },
            } as UploadProgressFileRead)),
            catchError((err) => {
              console.error(err);
              return of({ uploadId, error: err } as UploadProgressError);
            }),
          );
        }),
        share(),
      );

      const prepareChunkObservable = fileReadObservable.pipe(
        filter(isNotUploadV2ProgressError),
        bufferCount(10),
        concatMap((chunk) =>
          this.apiService.mediaV1MediaPrepare({
            media: chunk.map((fileData) => {
              const { progress, dataUrl, ...media } = fileData.media;
              return media;
            }),
          }).pipe(
            map((res) => {
              const mediaList = Array.isArray(res.result.media) ? res.result.media : [res.result.media];
              return mediaList.map((media, index) => media != null ? {
                uploadId: chunk[index].uploadId,
                file: chunk[index].file,
                mediaUploading: {
                  ...media,
                  progress: 0,
                  dataUrl: chunk[index].dataUrl,
                },
              } as UploadProgressPrepare : {
                uploadId: chunk[index].uploadId,
                error: new Error('media is null'),
              } as UploadProgressError);
            }),
            catchError((err) => {
              console.error(err);
              return of(chunk.map((fileRead) => ({ uploadId: fileRead.uploadId, error: err } as UploadProgressError)));
            }),
          ),
        ),
        share(),
      );

      const uploadObservable = prepareChunkObservable.pipe(
        concatAll(),
        filter(isNotUploadV2ProgressError),
        concatMap((prepareData) => {
          const uploadId = prepareData.uploadId;
          const presignedUrl = prepareData.mediaUploading.presignedUrl;
          if (!presignedUrl) {
            return of({ uploadId, error: new Error('presignedUrl 없음') } as UploadProgressError);
          }

          return this.httpClient.put(presignedUrl, prepareData.file, {
            headers: { 'Content-Type': prepareData.file.type, 'Content-Disposition': 'attachment' },
            observe: 'events',
            reportProgress: true,
          }).pipe(
            map((ev: HttpEvent<ArrayBuffer>) => {
              switch (ev.type) {
              case HttpEventType.UploadProgress:
                const progress = ev.total ? (ev.loaded / ev.total) : 0;
                return { uploadId, completed: false, progress, mediaUploading: { ...prepareData.mediaUploading, progress } } as UploadProgressUpload;
              case HttpEventType.Response:
                return { uploadId, completed: true, progress: 1, mediaUploading: { ...prepareData.mediaUploading, progress: 1 } } as UploadProgressUpload;
              }
            }),
            filter(isNotNullish),
            startWith({ uploadId, completed: false, progress: 0, mediaUploading: { ...prepareData.mediaUploading, progress: 0 } } as UploadProgressUpload),
            catchError((err) => {
              console.error(err);
              return this.apiService.mediaV1MediaDelete({ _id: prepareData.mediaUploading._id }).pipe(
                catchError((err2) => {
                  console.error(err2);
                  return of(null);
                }),
                map(() => ({ uploadId, error: err } as UploadProgressError)),
              );
            }),
          );
        }),
        share(),
      );

      const completeObservable = uploadObservable.pipe(
        filter(isNotUploadV2ProgressError),
        filter((upload) => upload.completed),
        concatMap(({ uploadId, mediaUploading }) =>
          this.apiService.mediaV1MediaComplete({
            _id: mediaUploading._id,
            mediaAdditionalInfo: {
              uploadedContentType: mediaUploading.uploadedContentType,
              uploadedContentId: mediaUploading.uploadedContentId,
            },
          }).pipe(
            map((res) => ({ uploadId, uploadedMedia: res.result.media ?? undefined } as UploadProgressComplete)),
            catchError((err) => {
              console.error(err);
              return this.apiService.mediaV1MediaDelete({ _id: mediaUploading._id }).pipe(
                catchError((err2) => {
                  console.error(err2);
                  return of(null);
                }),
                map(() => ({ uploadId, completed: false, progress: 0, error: err } as UploadProgressError)),
              );
            }),
          ),
        ),
        share(),
      );

      return from(fileList).pipe(
        mergeMap(({ uploadId }) => {
          return merge(
            fileReadObservable.pipe(
              filter((fileRead) => fileRead.uploadId === uploadId),
              concatMap((fileRead) =>
                isNotUploadV2ProgressError(fileRead) ?
                of({ uploadId, state: 'fileRead', progress: 0, media: fileRead.media } as UploadProgressProgress) :
                throwError(() => fileRead.error),
              ),
            ),
            prepareChunkObservable.pipe(
              concatAll(),
              filter((prepareChunk) => prepareChunk.uploadId === uploadId),
              concatMap((prepare) =>
                isNotUploadV2ProgressError(prepare) ?
                of({ uploadId, state: 'prepare', progress: 0, media: prepare.mediaUploading } as UploadProgressProgress) :
                throwError(() => prepare.error),
              ),
            ),
            uploadObservable.pipe(
              filter((upload) => upload.uploadId === uploadId),
              concatMap((upload) =>
                isNotUploadV2ProgressError(upload) ?
                of({ uploadId, state: 'upload', progress: upload.progress, media: upload.mediaUploading } as UploadProgressProgress) :
                throwError(() => upload.error),
              ),
            ),
            completeObservable.pipe(
              filter((complete) => complete.uploadId === uploadId),
              concatMap((complete) =>
                isNotUploadV2ProgressError(complete) ?
                of({ uploadId, state: 'completed', progress: 1, media: complete.uploadedMedia } as UploadProgressProgress) :
                throwError(() => complete.error),
              ),
            ),
          ).pipe(
            scan(
              (prev, curr) => ({ ...curr, media: curr.media ?? prev.media }),
              ({ uploadId, state: 'fileRead', progress: 0 } as UploadProgressProgress),
            ),
            catchError((err) => {
              return of({ uploadId, state: 'error', progress: 1, error: err } as UploadProgressProgress);
            }),
          );
        }),
        scan((acc, cur) => {
          return {
            ...acc,
            [cur.uploadId]: cur,
          } as Record<number, UploadProgressProgress>;
        }, {} as Record<number, UploadProgressProgress>),
        map((progressMap) => {
          const successCount = Object.values(progressMap).reduce((acc, progress) => acc + (progress.state === 'completed' ? 1 : 0), 0);
          const failureCount = Object.values(progressMap).reduce((acc, progress) => acc + (progress.state === 'error' ? 1 : 0), 0);
          const mediaUploadingList = Object.values(progressMap).filter((progress) => progress.state !== 'completed' && progress.state !== 'error').map((progress) => progress.media).filter(isNotNullish);
          const mediaUploadedList = Object.values(progressMap).filter((progress) => progress.state === 'completed').map((progress) => progress.media);
          const errorList = Object.values(progressMap).filter((progress) => progress.state === 'error').map((progress) => progress.error);

          return {
            completed: successCount + failureCount === totalCount,
            totalCount,
            successCount,
            failureCount,
            // progress: totalCount !== 0 ? (successCount + failureCount) / totalCount : 0,
            progress: Object.values(progressMap).reduce((acc, progress) => acc + progress.progress, 0) / totalCount,
            mediaUploadingList,
            mediaUploadedList,
            errorList: errorList.length ? errorList : undefined,
          } as UploadProgress;
        }),
        startWith(null),
        pairwise(),
        map(([prev, curr]) => ({
          ...curr,
          mediaUploadingNewList: curr!.mediaUploadingList.filter((media) => prev != null ? prev.mediaUploadingList.every((oldMedia) => oldMedia._id !== media._id) : true),
          mediaUploadedNewList: curr!.mediaUploadedList.filter((media) => prev != null ? prev.mediaUploadedList.every((oldMedia) => oldMedia._id !== media._id) : true),
        } as UploadProgress)),
        filter(isNotNullish),
        share(),
      );
    });
  }

  /**
   * 등록된 미디어를 삭제합니다.
   * @param idOrMedia 미디어 id 또는 미디어
   */
  async delete(idOrMedia: string | WithId<DBMedia>): Promise<void> {
    const id = typeof idOrMedia === 'string' ? idOrMedia : idOrMedia._id;
    await firstValueFrom(this.apiService.mediaV1MediaDelete({ _id: id }));
  }

  /**
   * 파일을 읽어 Data URL 형태로 반환합니다.
   * @param file 파일
   */
  readFileAsDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = function(): void {
        resolve(this.result as string);
      };
      fileReader.onerror = function(): void {
        reject(this.error);
      };
      fileReader.readAsDataURL(file);
    });
  }

  /**
   * 이미지의 해상도를 가져옵니다.
   * @param url 이미지 url
   */
  private getImageDimension(url: string): Promise<Dimension> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        resolve({ width: image.naturalWidth, height: image.naturalHeight });
      };
      image.onerror = reject;
      image.src = url;
    });
  }

  /**
   * 동영상의 해상도를 가져옵니다.
   * @param url 동영상 url
   */
  private getVideoDimension(url: string): Promise<Dimension> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.onerror = reject;
      video.src = url;
    });
  }

  private normalizeFileList(fileList: File | Array<File> | FileList): Array<File | null> {
    return Array.isArray(fileList) ?
      fileList.map((file) => file ?? null) :
      fileList instanceof File ?
      [fileList ?? null] :
      Array.from(fileList).map((file) => file ?? null);
  }
}
