import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormatDurationPipe } from './format-duration.pipe';

@NgModule({
  declarations: [
    FormatDurationPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FormatDurationPipe,
  ],
})
export class FormatDurationModule {}
