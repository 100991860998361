import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, retryWhen, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as AccountType from '../../../types/api/account.type';
import * as ContentType from '../../../types/api/content.type';
import * as CouponType from '../../../types/api/coupon.type';
import * as CurationType from '../../../types/api/curation.type';
import * as MediaType from '../../../types/api/media.type';
import * as NewsType from '../../../types/api/news.type';
import * as QnaType from '../../../types/api/qna.type';
import * as SearchType from '../../../types/api/search.type';
import * as StatType from '../../../types/api/stat.type';
import * as SubCapType from '../../../types/api/sub-cap.type';
import * as TicketType from '../../../types/api/ticket.type';
import * as UserType from '../../../types/api/user.type';
import * as WorkType from '../../../types/api/work.type';
import { DateWrapped } from '../../../types/common';
import { unwrapDate } from '../../../utils/date-wrapper';
import { paramsToQueryString } from '../../../utils/url.util';
import { APIError, APIResponse, APIResponseFailure } from './api.type';

const LOCAL_MODE = false;
const API_URL = LOCAL_MODE ? 'http://localhost:3000' : environment.apiUrl;
const SERVICE = {
  ACCOUNT: LOCAL_MODE ? '/dev' : '/account',
  WORK: LOCAL_MODE ? '/dev' : '/work',
  MEDIA: LOCAL_MODE ? '/dev' : '/media',
  SUB_CAP: LOCAL_MODE ? '/dev' : '/sub-cap',
  USER: LOCAL_MODE ? '/dev' : '/user',
  TICKET: LOCAL_MODE ? '/dev' : '/ticket',
  CURATION: LOCAL_MODE ? '/dev' : '/curation',
  NEWS: LOCAL_MODE ? '/dev' : '/news',
  STAT: LOCAL_MODE ? '/dev' : '/stat',
  QNA: LOCAL_MODE ? '/dev' : '/qna',
  SEARCH: LOCAL_MODE ? '/dev' : '/search',
  COUPON: LOCAL_MODE ? '/dev' : '/coupon',
  CONTENT: LOCAL_MODE ? '/dev' : '/content',
}

interface HeadersMap { [header: string]: string | string[]; }

type APIResponseObservable<T> = Observable<APIResponse<T>>;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private authToken: string | null;

  private unauthorizedSubject: Subject<void>;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.unauthorizedSubject = new Subject();
  }

  /* eslint-disable  max-len */
  accountV1Test() { return this.get<AccountType.AccountTestResult>(`${SERVICE.ACCOUNT}/v1/test`); }
  accountV1SignIn(params: AccountType.AccountSignInParams) { return this.post<AccountType.AccountSignInResult>(`${SERVICE.ACCOUNT}/v1/sign-in`, params); }
  accountV1RenewToken() { return this.post<AccountType.AccountRenewTokenResult>(`${SERVICE.ACCOUNT}/v1/renew-token`); }
  accountV1SignOut() { return this.post<void>(`${SERVICE.ACCOUNT}/v1/sign-out`); }
  accountV1PermissionList() { return this.get<AccountType.AccountPermissionListResult>(`${SERVICE.ACCOUNT}/v1/permission-list`); }
  accountV1Register(params: AccountType.AccountRegisterParams) { return this.post<AccountType.AccountRegisterResult>(`${SERVICE.ACCOUNT}/v1/register`, params); }
  accountV1AdminList(params: AccountType.AccountAdminListParams) { return this.get<AccountType.AccountAdminListResult>(`${SERVICE.ACCOUNT}/v1/admin-list`, params); }
  accountV1AdminRead(params: AccountType.AccountAdminReadParams) { return this.get<AccountType.AccountAdminReadResult>(`${SERVICE.ACCOUNT}/v1/admin-read`, params); }
  accountV1AdminUpdate(params: AccountType.AccountAdminUpdateParams) { return this.post<AccountType.AccountAdminUpdateResult>(`${SERVICE.ACCOUNT}/v1/admin-update`, params); }
  accountV1AdminDelete(params: AccountType.AccountAdminDeleteParams) { return this.post<void>(`${SERVICE.ACCOUNT}/v1/admin-delete`, params); }
  workV1WorkList(params: WorkType.WorkWorkListParams) { return this.get<WorkType.WorkWorkListResult>(`${SERVICE.WORK}/v1/work-list`, params); }
  workV1WorkCreate(params: WorkType.WorkWorkCreateParams) { return this.post<WorkType.WorkWorkCreateResult>(`${SERVICE.WORK}/v1/work-create`, params); }
  workV1WorkRead(params: WorkType.WorkWorkReadParams) { return this.get<WorkType.WorkWorkReadResult>(`${SERVICE.WORK}/v1/work-read`, params); }
  workV1WorkUpdate(params: WorkType.WorkWorkUpdateParams) { return this.post<WorkType.WorkWorkUpdateResult>(`${SERVICE.WORK}/v1/work-update`, params); }
  workV1WorkDelete(params: WorkType.WorkWorkDeleteParams) { return this.post<void>(`${SERVICE.WORK}/v1/work-delete`, params); }
  workV1WorkUpdateOpen(params: WorkType.WorkWorkUpdateOpenParams) { return this.post<void>(`${SERVICE.WORK}/v1/work-update-open`, params); }
  workV1MovieCapList(params: WorkType.WorkMovieCapListParams) { return this.get<WorkType.WorkMovieCapListResult>(`${SERVICE.WORK}/v1/movie-cap-list`, params); }
  workV1MovieCapCreate(params: WorkType.WorkMovieCapCreateParams) { return this.post<WorkType.WorkMovieCapCreateResult>(`${SERVICE.WORK}/v1/movie-cap-create`, params); }
  workV1MovieCapRead(params: WorkType.WorkMovieCapReadParams) { return this.get<WorkType.WorkMovieCapReadResult>(`${SERVICE.WORK}/v1/movie-cap-read`, params); }
  workV1MovieCapUpdate(params: WorkType.WorkMovieCapUpdateParams) { return this.post<WorkType.WorkMovieCapUpdateResult>(`${SERVICE.WORK}/v1/movie-cap-update`, params); }
  workV1MovieCapDelete(params: WorkType.WorkMovieCapDeleteParams) { return this.post<void>(`${SERVICE.WORK}/v1/movie-cap-delete`, params); }
  workV1UpcomingList(params: WorkType.WorkUpcomingListParams) { return this.get<WorkType.WorkUpcomingListResult>(`${SERVICE.WORK}/v1/upcoming-list`, params); }
  workV1UpcomingCreate(params: WorkType.WorkUpcomingCreateParams) { return this.post<WorkType.WorkUpcomingCreateResult>(`${SERVICE.WORK}/v1/upcoming-create`, params); }
  workV1UpcomingRead(params: WorkType.WorkUpcomingReadParams) { return this.get<WorkType.WorkUpcomingReadResult>(`${SERVICE.WORK}/v1/upcoming-read`, params); }
  workV1UpcomingUpdate(params: WorkType.WorkUpcomingUpdateParams) { return this.post<WorkType.WorkUpcomingUpdateResult>(`${SERVICE.WORK}/v1/upcoming-update`, params); }
  workV1UpcomingDelete(params: WorkType.WorkUpcomingDeleteParams) { return this.post<void>(`${SERVICE.WORK}/v1/upcoming-delete`, params); }
  workV1UpcomingUpdateOpen(params: WorkType.WorkUpcomingUpdateOpenParams) { return this.post<void>(`${SERVICE.WORK}/v1/upcoming-update-open`, params); }
  workV1UpcomingSendPush(params: WorkType.WorkUpcomingSendPushParams) { return this.post<void>(`${SERVICE.WORK}/v1/upcoming-send-push`, params); }
  workV1WorkWatchedList(params: WorkType.WorkWorkWatchedListParams) { return this.get<WorkType.WorkWorkWatchedListResult>(`${SERVICE.WORK}/v1/work-watched-list`, params); }
  workV1MovieCapWatchedList(params: WorkType.WorkMovieCapWatchedListParams) { return this.get<WorkType.WorkMovieCapWatchedListResult>(`${SERVICE.WORK}/v1/movie-cap-watched-list`, params); }
  mediaV1MediaList(params: MediaType.MediaMediaListParams) { return this.get<MediaType.MediaMediaListResult>(`${SERVICE.MEDIA}/v1/media-list`, params); }
  mediaV1MediaPrepare(params: MediaType.MediaMediaPrepareParams) { return this.post<MediaType.MediaMediaPrepareResult>(`${SERVICE.MEDIA}/v1/media-prepare`, params); }
  mediaV1MediaComplete(params: MediaType.MediaMediaCompleteParams) { return this.post<MediaType.MediaMediaCompleteResult>(`${SERVICE.MEDIA}/v1/media-complete`, params); }
  mediaV1MediaRead(params: MediaType.MediaMediaReadParams) { return this.get<MediaType.MediaMediaReadResult>(`${SERVICE.MEDIA}/v1/media-read`, params); }
  mediaV1MediaRename(params: MediaType.MediaMediaRenameParams) { return this.post<MediaType.MediaMediaRenameResult>(`${SERVICE.MEDIA}/v1/media-rename`, params); }
  mediaV1MediaDelete(params: MediaType.MediaMediaDeleteParams) { return this.post<void>(`${SERVICE.MEDIA}/v1/media-delete`, params); }
  subCapV1SubCapList(params: SubCapType.SubCapSubCapListParams) { return this.get<SubCapType.SubCapSubCapListResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-list`, params); }
  subCapV1SubCapCreate(params: SubCapType.SubCapSubCapCreateParams) { return this.post<SubCapType.SubCapSubCapCreateResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-create`, params); }
  subCapV1SubCapRead(params: SubCapType.SubCapSubCapReadParams) { return this.get<SubCapType.SubCapSubCapReadResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-read`, params); }
  subCapV1SubCapUpdate(params: SubCapType.SubCapSubCapUpdateParams) { return this.post<SubCapType.SubCapSubCapUpdateResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-update`, params); }
  subCapV1SubCapDelete(params: SubCapType.SubCapSubCapDeleteParams) { return this.post<void>(`${SERVICE.SUB_CAP}/v1/sub-cap-delete`, params); }
  subCapV1SubCapUpdateOpen(params: SubCapType.SubCapSubCapUpdateOpenParams) { return this.post<void>(`${SERVICE.SUB_CAP}/v1/sub-cap-update-open`, params); }
  subCapV1SubCapCategoryList(params: SubCapType.SubCapSubCapCategoryListParams) { return this.get<SubCapType.SubCapSubCapCategoryListResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-category-list`, params); }
  subCapV1SubCapCategoryCreate(params: SubCapType.SubCapSubCapCategoryCreateParams) { return this.post<SubCapType.SubCapSubCapCategoryCreateResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-category-create`, params); }
  subCapV1SubCapCategoryRead(params: SubCapType.SubCapSubCapCategoryReadParams) { return this.get<SubCapType.SubCapSubCapCategoryReadResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-category-read`, params); }
  subCapV1SubCapCategoryUpdate(params: SubCapType.SubCapSubCapCategoryUpdateParams) { return this.post<SubCapType.SubCapSubCapCategoryUpdateResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-category-update`, params); }
  subCapV1SubCapCategoryDelete(params: SubCapType.SubCapSubCapCategoryDeleteParams) { return this.post<void>(`${SERVICE.SUB_CAP}/v1/sub-cap-category-delete`, params); }
  subCapV1SubCapWatchedList(params: SubCapType.SubCapSubCapWatchedListParams) { return this.get<SubCapType.SubCapSubCapWatchedListResult>(`${SERVICE.SUB_CAP}/v1/sub-cap-watched-list`, params); }
  userV1UserList(params: UserType.UserUserListParams) { return this.get<UserType.UserUserListResult>(`${SERVICE.USER}/v1/user-list`, params); }
  userV1UserRead(params: UserType.UserUserReadParams) { return this.get<UserType.UserUserReadResult>(`${SERVICE.USER}/v1/user-read`, params); }
  userV1UserMovieCapRentList(params: UserType.UserUserMovieCapRentListParams) { return this.get<UserType.UserUserMovieCapRentListResult>(`${SERVICE.USER}/v1/user-movie-cap-rent-list`, params); }
  userV1UserWorkWatchedList(params: UserType.UserUserWorkWatchedListParams) { return this.get<UserType.UserUserWorkWatchedListResult>(`${SERVICE.USER}/v1/user-work-watched-list`, params); }
  userV1UserTicketFreeList(params: UserType.UserUserTicketFreeListParams) { return this.get<UserType.UserUserTicketFreeListResult>(`${SERVICE.USER}/v1/user-ticket-free-list`, params); }
  userV1UserTicketPaidList(params: UserType.UserUserTicketPaidListParams) { return this.get<UserType.UserUserTicketPaidListResult>(`${SERVICE.USER}/v1/user-ticket-paid-list`, params); }
  userV1UserTicketPassList(params: UserType.UserUserTicketPassListParams) { return this.get<UserType.UserUserTicketPassListResult>(`${SERVICE.USER}/v1/user-ticket-pass-list`, params); }
  userV1UserQnaList(params: UserType.UserUserQnaListParams) { return this.get<UserType.UserUserQnaListResult>(`${SERVICE.USER}/v1/user-qna-list`, params); }
  userV1UserRefundList(params: UserType.UserUserRefundListParams) { return this.get<UserType.UserUserRefundListResult>(`${SERVICE.USER}/v1/user-refund-list`, params); }
  userV1UserMovieCapWatchedList(params: UserType.UserUserMovieCapWatchedListParams) { return this.get<UserType.UserUserMovieCapWatchedListResult>(`${SERVICE.USER}/v1/user-movie-cap-watched-list`, params); }
  userV1UserSubCapWatchedList(params: UserType.UserUserSubCapWatchedListParams) { return this.get<UserType.UserUserSubCapWatchedListResult>(`${SERVICE.USER}/v1/user-sub-cap-watched-list`, params); }
  userV1UserSessionAccessList(params: UserType.UserUserSessionAccessListParams) { return this.get<UserType.UserUserSessionAccessListResult>(`${SERVICE.USER}/v1/user-session-access-list`, params); }
  userV1UserUpdate(params: UserType.UserUserUpdateParams) { return this.post<UserType.UserUserUpdateResult>(`${SERVICE.USER}/v1/user-update`, params); }
  userV1UserDelete(params: UserType.UserUserDeleteParams) { return this.post<void>(`${SERVICE.USER}/v1/user-delete`, params); }
  userV1UserSendNotification(params: UserType.UserUserSendNotificationParams) { return this.post<void>(`${SERVICE.USER}/v1/user-send-notification`, params); }
  userV1UserGiveTicket(params: UserType.UserUserGiveTicketParams) { return this.post<void>(`${SERVICE.USER}/v1/user-give-ticket`, params); }
  ticketV1TicketPurchaseList(params: TicketType.TicketTicketPurchaseListParams) { return this.get<TicketType.TicketTicketPurchaseListResult>(`${SERVICE.TICKET}/v1/ticket-purchase-list`, params); }
  ticketV1TicketFreeList(params: TicketType.TicketTicketFreeListParams) { return this.get<TicketType.TicketTicketFreeListResult>(`${SERVICE.TICKET}/v1/ticket-free-list`, params); }
  ticketV1TicketPaidList(params: TicketType.TicketTicketPaidListParams) { return this.get<TicketType.TicketTicketPaidListResult>(`${SERVICE.TICKET}/v1/ticket-paid-list`, params); }
  ticketV1TicketPassList(params: TicketType.TicketTicketPassListParams) { return this.get<TicketType.TicketTicketPassListResult>(`${SERVICE.TICKET}/v1/ticket-pass-list`, params); }
  ticketV1TicketUsageList(params: TicketType.TicketTicketUsageListParams) { return this.get<TicketType.TicketTicketUsageListResult>(`${SERVICE.TICKET}/v1/ticket-usage-list`, params); }
  ticketV1RefundList(params: TicketType.TicketRefundListParams) { return this.get<TicketType.TicketRefundListResult>(`${SERVICE.TICKET}/v1/refund-list`, params); }
  ticketV1TicketBuyOptionList(params: TicketType.TicketTicketBuyOptionListParams) { return this.get<TicketType.TicketTicketBuyOptionListResult>(`${SERVICE.TICKET}/v1/ticket-buy-option-list`, params); }
  ticketV1TicketFreeRevoke(params: TicketType.TicketTicketFreeRevokeParams) { return this.post<TicketType.TicketTicketFreeRevokeResult>(`${SERVICE.TICKET}/v1/ticket-free-revoke`, params); }
  ticketV1TicketPaidRevoke(params: TicketType.TicketTicketPaidRevokeParams) { return this.post<TicketType.TicketTicketPaidRevokeResult>(`${SERVICE.TICKET}/v1/ticket-paid-revoke`, params); }
  ticketV1TicketPassRevoke(params: TicketType.TicketTicketPassRevokeParams) { return this.post<TicketType.TicketTicketPassRevokeResult>(`${SERVICE.TICKET}/v1/ticket-pass-revoke`, params); }
  curationV1WorkCollectionList(params: CurationType.CurationWorkCollectionListParams) { return this.get<CurationType.CurationWorkCollectionListResult>(`${SERVICE.CURATION}/v1/work-collection-list`, params); }
  curationV1WorkCollectionCreate(params: CurationType.CurationWorkCollectionCreateParams) { return this.post<CurationType.CurationWorkCollectionCreateResult>(`${SERVICE.CURATION}/v1/work-collection-create`, params); }
  curationV1WorkCollectionRead(params: CurationType.CurationWorkCollectionReadParams) { return this.get<CurationType.CurationWorkCollectionReadResult>(`${SERVICE.CURATION}/v1/work-collection-read`, params); }
  curationV1WorkCollectionUpdate(params: CurationType.CurationWorkCollectionUpdateParams) { return this.post<CurationType.CurationWorkCollectionUpdateResult>(`${SERVICE.CURATION}/v1/work-collection-update`, params); }
  curationV1WorkCollectionDelete(params: CurationType.CurationWorkCollectionDeleteParams) { return this.post<void>(`${SERVICE.CURATION}/v1/work-collection-delete`, params); }
  curationV1WorkCollectionUpdateOpen(params: CurationType.CurationWorkCollectionUpdateOpenParams) { return this.post<void>(`${SERVICE.CURATION}/v1/work-collection-update-open`, params); }
  curationV1SubCapRecommendationList(params: CurationType.CurationSubCapRecommendationListParams) { return this.get<CurationType.CurationSubCapRecommendationListResult>(`${SERVICE.CURATION}/v1/sub-cap-recommendation-list`, params); }
  curationV1SubCapRecommendationCreate(params: CurationType.CurationSubCapRecommendationCreateParams) { return this.post<CurationType.CurationSubCapRecommendationCreateResult>(`${SERVICE.CURATION}/v1/sub-cap-recommendation-create`, params); }
  curationV1SubCapRecommendationRead(params: CurationType.CurationSubCapRecommendationReadParams) { return this.get<CurationType.CurationSubCapRecommendationReadResult>(`${SERVICE.CURATION}/v1/sub-cap-recommendation-read`, params); }
  curationV1SubCapRecommendationUpdate(params: CurationType.CurationSubCapRecommendationUpdateParams) { return this.post<CurationType.CurationSubCapRecommendationUpdateResult>(`${SERVICE.CURATION}/v1/sub-cap-recommendation-update`, params); }
  curationV1SubCapRecommendationDelete(params: CurationType.CurationSubCapRecommendationDeleteParams) { return this.post<void>(`${SERVICE.CURATION}/v1/sub-cap-recommendation-delete`, params); }
  curationV1SubCapRecommendationUpdateOpen(params: CurationType.CurationSubCapRecommendationUpdateOpenParams) { return this.post<void>(`${SERVICE.CURATION}/v1/sub-cap-recommendation-update-open`, params); }
  curationV1SubCapKeywordList(params: CurationType.CurationSubCapKeywordListParams) { return this.get<CurationType.CurationSubCapKeywordListResult>(`${SERVICE.CURATION}/v1/sub-cap-keyword-list`, params); }
  curationV1SubCapKeywordCreate(params: CurationType.CurationSubCapKeywordCreateParams) { return this.post<CurationType.CurationSubCapKeywordCreateResult>(`${SERVICE.CURATION}/v1/sub-cap-keyword-create`, params); }
  curationV1SubCapKeywordRead(params: CurationType.CurationSubCapKeywordReadParams) { return this.get<CurationType.CurationSubCapKeywordReadResult>(`${SERVICE.CURATION}/v1/sub-cap-keyword-read`, params); }
  curationV1SubCapKeywordUpdate(params: CurationType.CurationSubCapKeywordUpdateParams) { return this.post<CurationType.CurationSubCapKeywordUpdateResult>(`${SERVICE.CURATION}/v1/sub-cap-keyword-update`, params); }
  curationV1SubCapKeywordDelete(params: CurationType.CurationSubCapKeywordDeleteParams) { return this.post<void>(`${SERVICE.CURATION}/v1/sub-cap-keyword-delete`, params); }
  curationV1SubCapKeywordUpdateOpen(params: CurationType.CurationSubCapKeywordUpdateOpenParams) { return this.post<void>(`${SERVICE.CURATION}/v1/sub-cap-keyword-update-open`, params); }
  newsV1NoticeList(params: NewsType.NewsNoticeListParams) { return this.get<NewsType.NewsNoticeListResult>(`${SERVICE.NEWS}/v1/notice-list`, params); }
  newsV1NoticeCreate(params: NewsType.NewsNoticeCreateParams) { return this.post<NewsType.NewsNoticeCreateResult>(`${SERVICE.NEWS}/v1/notice-create`, params); }
  newsV1NoticeRead(params: NewsType.NewsNoticeReadParams) { return this.get<NewsType.NewsNoticeReadResult>(`${SERVICE.NEWS}/v1/notice-read`, params); }
  newsV1NoticeUpdate(params: NewsType.NewsNoticeUpdateParams) { return this.post<NewsType.NewsNoticeUpdateResult>(`${SERVICE.NEWS}/v1/notice-update`, params); }
  newsV1NoticeDelete(params: NewsType.NewsNoticeDeleteParams) { return this.post<void>(`${SERVICE.NEWS}/v1/notice-delete`, params); }
  newsV1NoticeUpdateOpen(params: NewsType.NewsNoticeUpdateOpenParams) { return this.post<void>(`${SERVICE.NEWS}/v1/notice-update-open`, params); }
  newsV1PromotionList(params: NewsType.NewsPromotionListParams) { return this.get<NewsType.NewsPromotionListResult>(`${SERVICE.NEWS}/v1/promotion-list`, params); }
  newsV1PromotionCreate(params: NewsType.NewsPromotionCreateParams) { return this.post<NewsType.NewsPromotionCreateResult>(`${SERVICE.NEWS}/v1/promotion-create`, params); }
  newsV1PromotionRead(params: NewsType.NewsPromotionReadParams) { return this.get<NewsType.NewsPromotionReadResult>(`${SERVICE.NEWS}/v1/promotion-read`, params); }
  newsV1PromotionUpdate(params: NewsType.NewsPromotionUpdateParams) { return this.post<NewsType.NewsPromotionUpdateResult>(`${SERVICE.NEWS}/v1/promotion-update`, params); }
  newsV1PromotionDelete(params: NewsType.NewsPromotionDeleteParams) { return this.post<void>(`${SERVICE.NEWS}/v1/promotion-delete`, params); }
  newsV1PromotionUpdateOpen(params: NewsType.NewsPromotionUpdateOpenParams) { return this.post<void>(`${SERVICE.NEWS}/v1/promotion-update-open`, params); }
  newsV1NotificationList(params: NewsType.NewsNotificationListParams) { return this.get<NewsType.NewsNotificationListResult>(`${SERVICE.NEWS}/v1/notification-list`, params); }
  newsV1NotificationCreate(params: NewsType.NewsNotificationCreateParams) { return this.post<NewsType.NewsNotificationCreateResult>(`${SERVICE.NEWS}/v1/notification-create`, params); }
  newsV1NotificationRead(params: NewsType.NewsNotificationReadParams) { return this.get<NewsType.NewsNotificationReadResult>(`${SERVICE.NEWS}/v1/notification-read`, params); }
  newsV1NotificationUpdate(params: NewsType.NewsNotificationUpdateParams) { return this.post<NewsType.NewsNotificationUpdateResult>(`${SERVICE.NEWS}/v1/notification-update`, params); }
  newsV1NotificationDelete(params: NewsType.NewsNotificationDeleteParams) { return this.post<void>(`${SERVICE.NEWS}/v1/notification-delete`, params); }
  newsV1NotificationSend(params: NewsType.NewsNotificationSendParams) { return this.post<void>(`${SERVICE.NEWS}/v1/notification-send`, params); }
  newsV1VersionList() { return this.get<NewsType.NewsVersionReadResult>(`${SERVICE.NEWS}/v1/version-list`); }
  newsV1VersionSetRequiredMin(params: NewsType.NewsVersionSetRequiredMinParams) { return this.post<void>(`${SERVICE.NEWS}/v1/version-set-required-min`, params); }
  newsV1LoginScreenList(params: NewsType.NewsLoginScreenListParams) { return this.get<NewsType.NewsLoginScreenListResult>(`${SERVICE.NEWS}/v1/login-screen-list`, params); }
  newsV1LoginScreenCreate(params: NewsType.NewsLoginScreenCreateParams) { return this.post<NewsType.NewsLoginScreenCreateResult>(`${SERVICE.NEWS}/v1/login-screen-create`, params); }
  newsV1LoginScreenRead(params: NewsType.NewsLoginScreenReadParams) { return this.get<NewsType.NewsLoginScreenReadResult>(`${SERVICE.NEWS}/v1/login-screen-read`, params); }
  newsV1LoginScreenUpdate(params: NewsType.NewsLoginScreenUpdateParams) { return this.post<NewsType.NewsLoginScreenUpdateResult>(`${SERVICE.NEWS}/v1/login-screen-update`, params); }
  newsV1LoginScreenDelete(params: NewsType.NewsLoginScreenDeleteParams) { return this.post<void>(`${SERVICE.NEWS}/v1/login-screen-delete`, params); }
  newsV1LoginScreenUpdateOpen(params: NewsType.NewsLoginScreenUpdateOpenParams) { return this.post<void>(`${SERVICE.NEWS}/v1/login-screen-update-open`, params); }
  newsV1BannerList(params: NewsType.NewsBannerListParams) { return this.get<NewsType.NewsBannerListResult>(`${SERVICE.NEWS}/v1/banner-list`, params); }
  newsV1BannerCreate(params: NewsType.NewsBannerCreateParams) { return this.post<NewsType.NewsBannerCreateResult>(`${SERVICE.NEWS}/v1/banner-create`, params); }
  newsV1BannerRead(params: NewsType.NewsBannerReadParams) { return this.get<NewsType.NewsBannerReadResult>(`${SERVICE.NEWS}/v1/banner-read`, params); }
  newsV1BannerUpdate(params: NewsType.NewsBannerUpdateParams) { return this.post<NewsType.NewsBannerUpdateResult>(`${SERVICE.NEWS}/v1/banner-update`, params); }
  newsV1BannerDelete(params: NewsType.NewsBannerDeleteParams) { return this.post<void>(`${SERVICE.NEWS}/v1/banner-delete`, params); }
  newsV1BannerUpdateOpen(params: NewsType.NewsBannerUpdateOpenParams) { return this.post<void>(`${SERVICE.NEWS}/v1/banner-update-open`, params); }
  newsV1PromotionSettingGet(params: NewsType.NewsPromotionSettingGetParams) { return this.post<NewsType.NewsPromotionSettingGetResult>(`${SERVICE.NEWS}/v1/promotion-setting-get`, params); }
  newsV1PromotionSettingSet(params: NewsType.NewsPromotionSettingSetParams) { return this.post<NewsType.NewsPromotionSettingSetResult>(`${SERVICE.NEWS}/v1/promotion-setting-set`, params); }
  statV1StatContent(params: StatType.StatStatContentParams) { return this.get<StatType.StatStatContentResult>(`${SERVICE.STAT}/v1/stat-content`, params); }
  statV1StatUser(params: StatType.StatStatUserParams) { return this.get<StatType.StatStatUserResult>(`${SERVICE.STAT}/v1/stat-user`, params); }
  statV1StatPurchase(params: StatType.StatStatPurchaseParams) { return this.get<StatType.StatStatPurchaseResult>(`${SERVICE.STAT}/v1/stat-purchase`, params); }
  statV1StatWorkTicketUsage(params: StatType.StatStatWorkTicketUsageParams) { return this.get<StatType.StatStatWorkTicketUsageResult>(`${SERVICE.STAT}/v1/stat-work-ticket-usage`, params); }
  statV1StatWorkTicketUsageDetail(params: StatType.StatStatWorkTicketUsageDetailParams) { return this.get<StatType.StatStatWorkTicketUsageDetailResult>(`${SERVICE.STAT}/v1/stat-work-ticket-usage-detail`, params); }
  statV1StatWorkTicketUsageDetailRent(params: StatType.StatStatWorkTicketUsageDetailRentParams) { return this.get<StatType.StatStatWorkTicketUsageDetailRentResult>(`${SERVICE.STAT}/v1/stat-work-ticket-usage-detail-rent`, params); }
  statV1StatWorkTicketUsageDetailDaily(params: StatType.StatStatWorkTicketUsageDetailDailyParams) { return this.get<StatType.StatStatWorkTicketUsageDetailDailyResult>(`${SERVICE.STAT}/v1/stat-work-ticket-usage-detail-daily`, params); }
  statV1StatWorkTicketUsageDetailDailyEpisode(params: StatType.StatStatWorkTicketUsageDetailDailyEpisodeParams) { return this.get<StatType.StatStatWorkTicketUsageDetailDailyEpisodeResult>(`${SERVICE.STAT}/v1/stat-work-ticket-usage-detail-daily-episode`, params); }
  statV1StatDashboard(params: StatType.StatStatDashboardParams) { return this.get<StatType.StatStatDashboardResult>(`${SERVICE.STAT}/v1/stat-dashboard`, params); }
  statV1StatPromotion(params: StatType.StatStatPromotionParams) { return this.get<StatType.StatStatPromotionResult>(`${SERVICE.STAT}/v1/stat-promotion`, params); }
  qnaV1QnaList(params: QnaType.QnaQnaListParams) { return this.get<QnaType.QnaQnaListResult>(`${SERVICE.QNA}/v1/qna-list`, params); }
  qnaV1QnaRead(params: QnaType.QnaQnaReadParams) { return this.get<QnaType.QnaQnaReadResult>(`${SERVICE.QNA}/v1/qna-read`, params); }
  qnaV1QnaAnswer(params: QnaType.QnaQnaAnswerParams) { return this.post<QnaType.QnaQnaAnswerResult>(`${SERVICE.QNA}/v1/qna-answer`, params); }
  searchV1SearchTextList(params: SearchType.SearchSearchTextListParams) { return this.get<SearchType.SearchSearchTextListResult>(`${SERVICE.SEARCH}/v1/search-text-list`, params); }
  searchV1SearchTextUpdateVisibility(params: SearchType.SearchSearchTextUpdateVisibilityParams) { return this.post<SearchType.SearchSearchTextUpdateVisibilityResult>(`${SERVICE.SEARCH}/v1/search-text-update-visibility`, params); }
  searchV1SearchTextRankingList(params: SearchType.SearchSearchTextRankingListParams) { return this.get<SearchType.SearchSearchTextRankingListResult>(`${SERVICE.SEARCH}/v1/search-text-ranking-list`, params); }
  couponV1CouponTypeList(params: CouponType.CouponCouponTypeListParams) { return this.get<CouponType.CouponCouponTypeListResult>(`${SERVICE.COUPON}/v1/coupon-type-list`, params); }
  couponV1CouponTypeCreate(params: CouponType.CouponCouponTypeCreateParams) { return this.post<CouponType.CouponCouponTypeCreateResult>(`${SERVICE.COUPON}/v1/coupon-type-create`, params); }
  couponV1CouponTypeRead(params: CouponType.CouponCouponTypeReadParams) { return this.get<CouponType.CouponCouponTypeReadResult>(`${SERVICE.COUPON}/v1/coupon-type-read`, params); }
  couponV1CouponTypeUpdate(params: CouponType.CouponCouponTypeUpdateParams) { return this.post<CouponType.CouponCouponTypeUpdateResult>(`${SERVICE.COUPON}/v1/coupon-type-update`, params); }
  couponV1CouponTypeDelete(params: CouponType.CouponCouponTypeDeleteParams) { return this.post<void>(`${SERVICE.COUPON}/v1/coupon-type-delete`, params); }
  couponV1CouponCodeList(params: CouponType.CouponCouponCodeListParams) { return this.get<CouponType.CouponCouponCodeListResult>(`${SERVICE.COUPON}/v1/coupon-code-list`, params); }
  couponV1CouponCodeCreate(params: CouponType.CouponCouponCodeCreateParams) { return this.post<CouponType.CouponCouponCodeCreateResult>(`${SERVICE.COUPON}/v1/coupon-code-create`, params); }
  couponV1CouponCodeGenerate(params: CouponType.CouponCouponCodeGenerateParams) { return this.post<CouponType.CouponCouponCodeGenerateResult>(`${SERVICE.COUPON}/v1/coupon-code-generate`, params); }
  couponV1CouponCodeRead(params: CouponType.CouponCouponCodeReadParams) { return this.get<CouponType.CouponCouponCodeReadResult>(`${SERVICE.COUPON}/v1/coupon-code-read`, params); }
  couponV1CouponCodeUpdate(params: CouponType.CouponCouponCodeUpdateParams) { return this.post<CouponType.CouponCouponCodeUpdateResult>(`${SERVICE.COUPON}/v1/coupon-code-update`, params); }
  couponV1CouponCodeDelete(params: CouponType.CouponCouponCodeDeleteParams) { return this.post<void>(`${SERVICE.COUPON}/v1/coupon-code-delete`, params); }
  couponV1CouponCodeUsageList(params: CouponType.CouponCouponCodeUsageListParams) { return this.get<CouponType.CouponCouponCodeUsageListResult>(`${SERVICE.COUPON}/v1/coupon-code-usage-list`, params); }
  contentV1ContentWatchedList(params: ContentType.ContentContentWatchedListParams) { return this.get<ContentType.ContentContentWatchedListResult>(`${SERVICE.CONTENT}/v1/content-watched-list`, params); }
  /* eslint-enable  max-len */

  /**
   * API 요청시 사용할 Auth Token을 설정합니다.
   */
  setAuthToken(authToken: string | null): void {
    this.authToken = authToken;
  }

  getUnauthorizedObservable(): Observable<void> {
    return from(this.unauthorizedSubject);
  }

  /**
   * HTTP 요청용 베이스 헤더를 반환합니다.
   */
  private getHeaders(): HeadersMap {
    const headers: HeadersMap = {};

    if (this.authToken) {
      headers.Authorization = `Bearer ${this.authToken}`;
    }

    return headers;
  }

  /**
   * API GET 요청을 보냅니다.
   * @param url 요청 보낼 주소
   * @param params 요청 파라미터
   */
  private get<T>(url: string, params?: any): APIResponseObservable<T> {
    return defer(() => {
      let requestTime = Date.now();
      const headers = this.getHeaders();
      const queryString = paramsToQueryString(params);
      return this.httpClient.get<APIResponse<DateWrapped<T>>>(API_URL + url + (queryString ? '?' + queryString : ''), { headers }).pipe(
        map((res) => ({ ...res, result: unwrapDate(res.result) })),
        retryWhen((errs) => errs.pipe(
          take(1),
          concatMap((err) => err instanceof HttpErrorResponse && err.status === 0 && Date.now() - requestTime >= 3000 ?
            of(err) :
            throwError(() => err),
          ),
          tap(() => requestTime = Date.now()),
        )),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.unauthorizedSubject.next();
            }
            const errorBody = err.error as APIResponseFailure;
            if (errorBody != null && typeof errorBody === 'object' && errorBody.error != null) {
              throw new APIError(err.status, errorBody.error);
            }
          }
          throw err;
        }),
      );
    });
  }

  /**
   * API POST 요청을 보냅니다.
   * @param url 요청 보낼 주소
   * @param params 요청 파라미터
   */
  private post<T>(url: string, params?: any): APIResponseObservable<T> {
    return defer(() => {
      let requestTime = Date.now();
      const headers = this.getHeaders();
      return this.httpClient.post<APIResponse<DateWrapped<T>>>(API_URL + url, params, { headers }).pipe(
        map((res) => ({ ...res, result: unwrapDate(res.result) })),
        retryWhen((errs) => errs.pipe(
          take(1),
          concatMap((err) => err instanceof HttpErrorResponse && err.status === 0 && Date.now() - requestTime >= 3000 ?
            of(err) :
            throwError(() => err),
          ),
          tap(() => requestTime = Date.now()),
        )),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.unauthorizedSubject.next();
            }
            const errorBody = err.error as APIResponseFailure;
            if (errorBody != null && typeof errorBody === 'object' && errorBody.error != null) {
              throw new APIError(err.status, errorBody.error);
            }
          }
          throw err;
        }),
      );
    });
  }
}
