import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

  @Input() data: Chart.ChartData;
  @Input() options: Chart.ChartOptions;

  chart?: Chart;

  constructor() {}

  ngOnInit(): void {
    this.makeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      let shouldUpdate = false;
      if (changes.data) {
        this.chart.data = this.data;
        shouldUpdate = true;
      }
      if (changes.options) {
        this.chart.options = this.options;
        shouldUpdate = true;
      }
      if (shouldUpdate) {
        this.chart.update();
      }
    }
  }

  ngOnDestroy(): void {
    this.chart?.destroy();
    this.chart = undefined;
  }

  private makeChart(): void {
    this.chart = new Chart(this.canvas.nativeElement, {
      type: 'bar',
      data: this.data,
      options: this.options,
    });
  }
}
