<mat-form-field *ngIf="canListPromotion && canReadPromotion" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select [disabled]="disabled" [(ngModel)]="value" (openedChange)="onOpenedChange(searchInput, $event)">
    <mat-form-field class="select-search-field">
      <mat-label>프로모션 제목 검색</mat-label>
      <input #searchInput matInput (input)="searchPromotion($event.target.value)">
    </mat-form-field>
    <mat-option *ngIf="!notFoundPromotion && !selectedPromotion && !searchPromotionList?.length" disabled [value]="undefined">{{ label }}</mat-option>
    <mat-option *ngIf="notFoundPromotion && !selectedPromotion" class="input-promotion-id-search-promotion-warn" [value]="notFoundPromotion._id">{{ notFoundPromotion._id }} (찾을 수 없음)</mat-option>
    <mat-option *ngIf="selectedPromotion" [class.input-promotion-id-search-promotion-warn]="selectedPromotion.deletedAt" [value]="selectedPromotion._id">{{ selectedPromotion.title }}{{ selectedPromotion.deletedAt ? ' (삭제됨)' : '' }}</mat-option>
    <mat-option *ngFor="let promotion of searchPromotionList" [value]="promotion._id">{{ promotion.title }}</mat-option>
  </mat-select>
  <button mat-button *ngIf="clearable && value" matSuffix mat-icon-button aria-label="Clear" (click)="onClickClear($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<mat-form-field *ngIf="!(canListPromotion && canReadPromotion)" appearance="fill">
  <mat-label>{{ labelText }}</mat-label>
  <input matInput type="string" [disabled]="disabled" [(ngModel)]="value">
</mat-form-field>
