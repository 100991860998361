import { AdminPermission } from '../../../constants/admin-permission.constant';
import { NavigationItem } from './navigation-item.type';

export const ALL_NAVIGATIONS: Array<NavigationItem> = [
  {
    // icon: 'home',
    name: '대시보드',
    path: 'dashboard',
    requiredPermissions: [AdminPermission.STAT_DASHBOARD],
  },
  {
    // icon: 'text_snippet',
    name: '콘텐츠 관리',
    path: 'content',
    subItems: [
      {
        // icon: 'movie',
        name: '콘텐츠 대시보드',
        path: 'dashboard',
        requiredPermissions: [AdminPermission.STAT_CONTENT],
      },
      {
        name: '콘텐츠 감상내역',
        path: 'content-watched',
        requiredPermissions: [AdminPermission.CONTENT_WATCHED_LIST],
        subItems: [
          {
            name: '작품 감상내역',
            path: 'work',
            requiredPermissions: [AdminPermission.WORK_WATCHED_LIST],
          },
          {
            name: '무비캡 감상내역',
            path: 'movie-cap',
            requiredPermissions: [AdminPermission.MOVIE_CAP_WATCHED_LIST],
          },
          {
            name: '서브캡 감상내역',
            path: 'sub-cap',
            requiredPermissions: [AdminPermission.SUB_CAP_WATCHED_LIST],
          },
        ],
      },
      {
        // icon: 'movie',
        name: '무비캡',
        path: 'movie-cap',
        subItems: [
          {
            // icon: 'movie',
            name: '무비캡 생성 등록',
            path: 'work',
            requiredPermissions: [AdminPermission.WORK_LIST],
          },
          // {
          //   // icon: 'inbox',
          //   name: '무비캡 컬렉션',
          //   path: 'work-collection',
          //   requiredPermissions: [AdminPermission.WORK_COLLECTION_LIST],
          // },
          {
            // icon: '',
            name: '개봉 예정 작품 관리',
            path: 'upcoming',
            requiredPermissions: [AdminPermission.UPCOMING_LIST],
          },
        ],
      },
      {
        // icon: 'view_array',
        name: '서브캡',
        path: 'sub-cap',
        subItems: [
          {
            // icon: 'view_array',
            name: '서브캡 생성 관리',
            path: 'list',
            requiredPermissions: [AdminPermission.SUB_CAP_LIST],
          },
          {
            // icon: '',
            name: '서브캡 카테고리',
            path: 'category',
            requiredPermissions: [AdminPermission.SUB_CAP_CATEGORY_LIST],
          },
        ],
      },
    ],
  },
  {
    // icon: '',
    name: '큐레이션 관리',
    path: 'curation',
    subItems: [
      {
        // icon: '',
        name: '서브캡 추천',
        path: 'sub-cap-recommendation',
        requiredPermissions: [AdminPermission.SUB_CAP_RECOMMENDATION_LIST],
      },
      {
        // icon: '',
        name: '서브캡 키워드',
        path: 'sub-cap-keyword',
        requiredPermissions: [AdminPermission.SUB_CAP_KEYWORD_LIST],
      },
    ],
  },
  {
    // icon: 'person',
    name: '회원 관리',
    path: 'user',
    subItems: [
      {
        name: '회원 대시보드',
        path: 'dashboard',
        requiredPermissions: [AdminPermission.STAT_USER],
      },
      {
        // icon: 'person',
        name: '회원 정보 관리',
        path: 'list',
        requiredPermissions: [AdminPermission.USER_LIST],
      },
      {
        // icon: 'question_answer',
        name: '회원 문의',
        path: 'question',
        requiredPermissions: [AdminPermission.QNA_LIST],
      },
    ],
  },
  {
    // icon: 'attach_money',
    name: '매출 관리',
    path: 'sales',
    subItems: [
      {
        name: '결제',
        path: 'payment',
        subItems: [
          {
            // icon: 'attach_money',
            name: '결제 현황',
            path: 'dashboard',
            requiredPermissions: [AdminPermission.TICKET_LIST],
          },
          {
            // icon: 'payment',
            name: '통합 결제 내역',
            path: 'ticket-purchase',
            requiredPermissions: [AdminPermission.TICKET_LIST],
          },
          {
            // icon: 'assignment_return',
            name: '통합 환불 내역',
            path: 'refund',
            requiredPermissions: [AdminPermission.REFUND_LIST],
          },
          {
            // icon: 'local_movies',
            name: '작품별 매출 내역',
            path: 'work-ticket-usage',
            requiredPermissions: [AdminPermission.TICKET_USAGE_LIST],
          },
        ],
      },
      {
        name: '티켓',
        path: 'ticket',
        subItems: [
          {
            // icon: 'local_offer',
            name: '티켓 현황',
            path: 'dashboard',
            requiredPermissions: [AdminPermission.TICKET_USAGE_LIST],
          },
          {
            // icon: '',
            name: '유료 지급 내역',
            path: 'ticket-paid',
            requiredPermissions: [AdminPermission.TICKET_LIST],
          },
          {
            // icon: 'money_off',
            name: '무료 지급 내역',
            path: 'ticket-free',
            requiredPermissions: [AdminPermission.TICKET_LIST],
          },
          {
            name: '이용권 지급 내역',
            path: 'ticket-pass',
            requiredPermissions: [AdminPermission.TICKET_LIST],
          },
          {
            // icon: 'local_offer',
            name: '티켓 사용 내역',
            path: 'ticket-usage',
            requiredPermissions: [AdminPermission.TICKET_USAGE_LIST],
          },
        ],
      },
    ]
  },
  {
    // icon: '',
    name: '소식 관리',
    path: 'news',
    subItems: [
      {
        // icon: 'announcement',
        name: '공지 관리',
        path: 'notice',
        requiredPermissions: [AdminPermission.NOTICE_LIST],
      },
      {
        // icon: 'calendar_today',
        name: '프로모션 관리',
        path: 'promotion',
        requiredPermissions: [AdminPermission.PROMOTION_LIST],
      },
      {
        // icon: '',
        name: '프로모션 설정',
        path: 'promotion-setting',
        requiredPermissions: [AdminPermission.PROMOTION_SETTING_GET],
      },
      {
        // icon: '',
        name: '배너 관리',
        path: 'banner',
        requiredPermissions: [AdminPermission.BANNER_LIST],
      },
      {
        // icon: '',
        name: '알림 관리',
        path: 'notification',
        requiredPermissions: [AdminPermission.NOTIFICATION_LIST],
      },
    ],
  },
  {
    // icon: 'mail',
    name: '기타 관리',
    path: 'etc',
    subItems: [
      {
        // icon: 'calendar_today',
        name: '쿠폰 관리',
        path: 'coupon',
        requiredPermissions: [AdminPermission.COUPON_TYPE_LIST],
      },
      {
        // icon: '',
        name: '검색어 순위',
        path: 'search-text-ranking',
        requiredPermissions: [AdminPermission.SEARCH_TEXT_LIST],
      },
      {
        // icon: '',
        name: '검색어 관리',
        path: 'search-text',
        requiredPermissions: [AdminPermission.SEARCH_TEXT_LIST],
      },
      {
        // icon: '',
        name: '버전 관리',
        path: 'version',
      },
      {
        // icon: '',
        name: '로그인 화면 관리',
        path: 'login-screen',
        requiredPermissions: [AdminPermission.LOGIN_SCREEN_LIST],
      },
    ],
  },
  {
    // icon: 'account_circle',
    name: '관리자 관리',
    path: 'admin',
    requiredPermissions: [AdminPermission.ADMIN_LIST],
  },
];

function calculatePath(navs: Array<NavigationItem>, basePath: string = '/'): void {
  for (const item of navs) {
    item.calculatedPath = new URL(
      item.path,
      new URL(
        `${basePath}${basePath.endsWith('/') ? '' : '/'}`,
        location.origin,
      ),
    ).pathname;
    if (item.subItems) {
      calculatePath(item.subItems, item.calculatedPath);
    }
  }
}

calculatePath(ALL_NAVIGATIONS);
