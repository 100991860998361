import { DateTime } from 'luxon';
import { Preset } from './date-range-picker-content.type';

export const PRESETS: Array<Preset> = [
  {
    name: '오늘',
    func: (now) => ({
      start: now.startOf('day'),
      end: now.startOf('day').plus({ day: 1 }),
    }),
  },
  {
    name: '어제',
    func: (now) => ({
      start: now.startOf('day').minus({ day: 1 }),
      end: now.startOf('day'),
    }),
  },
  {
    name: '이번 주 (월요일~일요일)',
    func: (now) => ({
      start: now.startOf('week'),
      end: now.startOf('week').plus({ week: 1 }),
    }),
  },
  {
    name: '지난 주 (월요일~일요일)',
    func: (now) => ({
      start: now.startOf('week').minus({ week: 1 }),
      end: now.startOf('week'),
    }),
  },
  {
    name: '지난 7일',
    func: (now) => ({
      start: now.startOf('day').minus({ day: 7 }),
      end: now.startOf('day'),
    }),
  },
  {
    name: '지난 28일',
    func: (now) => ({
      start: now.startOf('day').minus({ day: 28 }),
      end: now.startOf('day'),
    }),
  },
  {
    name: '지난 30일',
    func: (now) => ({
      start: now.startOf('day').minus({ day: 30 }),
      end: now.startOf('day'),
    }),
  },
  {
    name: '지난 90일',
    func: (now) => ({
      start: now.startOf('day').minus({ day: 90 }),
      end: now.startOf('day'),
    }),
  },
  {
    name: '지난 12개월',
    func: (now) => ({
      start: now.startOf('month'),
      end: now.startOf('month').plus({ year: 1 }),
    }),
  },
  {
    name: '달력상 작년',
    func: (now) => ({
      start: now.startOf('year').minus({ year: 1 }),
      end: now.startOf('year'),
    }),
  },
  {
    name: '올해 (1월부터 오늘까지)',
    func: (now) => ({
      start: now.startOf('year'),
      end: now.startOf('day'),
    }),
  },
  {
    name: '전체',
    func: (now) => ({
      start: DateTime.fromISO('2021-03-16T00:00:00+0900'),
      end: now.startOf('day').plus({ day: 1 }),
    }),
  },
];
