import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Controller } from 'scrollmagic';
import { ContentText } from './content-text.type';

@Component({
  selector: 'app-content-text',
  templateUrl: './content-text.component.html',
  styleUrls: ['./content-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentTextComponent {
  @Input() cut?: ContentText;
  @Input() scrollMagic: Controller | null = null;
  @Input() slideVisible: boolean | null = null;

  @Output() clickStatic = new EventEmitter<MouseEvent>();

  @HostListener('click', ['$event'])
  onClick(ev: MouseEvent): void {
    ev.preventDefault();
    ev.stopPropagation();
    this.clickStatic.emit(ev);
  }
}
