import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';
import { AlertButton, AlertButtonFill, AlertCloseEventDetail, AlertInput, AlertOptions } from '../../services/alert';

const BACKDROP = 'backdrop';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertModalComponent implements OnInit, OnDestroy {
  header?: string | null;
  message?: string | null;
  inputs?: Array<AlertInput> | null;
  buttons?: Array<AlertButton>;
  buttonFill?: AlertButtonFill;
  values?: { [name: string]: any };

  private keydownEventsSubscription: Subscription;
  private backdropClickSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AlertOptions,
    private matDialogRef: MatDialogRef<AlertModalComponent, AlertCloseEventDetail>,
  ) {}

  ngOnInit(): void {
    this.header = this.data.header;
    this.message = this.data.message;
    this.inputs = this.data.inputs;
    if (this.inputs) {
      this.values = this.inputs.reduce((acc, cur, idx) => {
        acc[cur.name || idx] = cur.value;
        return acc;
      }, {});
    }
    this.buttons = this.data.buttons
      ?.map((button: AlertButton | string) => typeof button === 'string' ? { text: button } : button);
    this.buttonFill = this.data.buttonFill ?? 'clear';

    this.keydownEventsSubscription = this.matDialogRef.keydownEvents().subscribe((ev) => {
      if (ev.key === 'Escape') {
        this.close(undefined, BACKDROP);
      }
    });
    this.backdropClickSubscription = this.matDialogRef.backdropClick().subscribe(() => {
      this.close(undefined, BACKDROP);
    });
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.keydownEventsSubscription,
      this.backdropClickSubscription,
    ])
  }

  onChangeInput(input: AlertInput, index: number, ev: Event): void {
    const elem = ev.target as HTMLInputElement;
    if (elem != null && this.values != null) {
      this.values[index] = elem.value;
      if (input.name) {
        this.values[input.name] = elem.value;
      }
    }
  }

  onClickButton(button: AlertButton): void {
    this.close({ values: this.values }, button.role);
  }

  close(data?: any, role?: string): void {
    this.matDialogRef.close({ data, role });
  }
}
