<video class="video" [poster]="cut?.data?.posterUrl" [src]="cut?.data?.url" controls [muted]="cut?.data.muted"></video>
<mat-form-field appearance="fill">
  <mat-label>동영상</mat-label>
  <mat-select panelClass="select-height-auto" [value]="selectedVideo" (selectionChange)="onChangeVideo($event)">
    <mat-option *ngFor="let video of videoList" [value]="video">
      <div class="video-option">
        <app-video-preview class="video-preview" [poster]="video.posterUrl" [src]="video.url" muted autoplay></app-video-preview>
        <span>{{ video.name }}</span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="flex">
  <mat-slide-toggle [(ngModel)]="cut?.data.controls">조작</mat-slide-toggle>
  <mat-slide-toggle [(ngModel)]="cut?.data.muted">음소거</mat-slide-toggle>
  <mat-slide-toggle [(ngModel)]="cut?.data.loop">반복</mat-slide-toggle>
</div>
