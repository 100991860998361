import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormatNumberModule } from '../../pipes/format-number';
import { StatComponent } from './stat.component';

@NgModule({
  declarations: [
    StatComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormatNumberModule,
  ],
  exports: [
    StatComponent,
  ],
})
export class StatModule {}
