import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './shared/guards/auth';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: 'dashboard', canActivate: [AuthGuard], loadChildren: () => import('./pages/dashboard').then(m => m.DashboardModule) },
  { path: 'admin', canActivate: [AuthGuard], loadChildren: () => import('./pages/admin').then(m => m.AdminModule) },
  { path: 'content', canActivate: [AuthGuard], loadChildren: () => import('./pages/content').then(m => m.ContentModule) },
  { path: 'curation', canActivate: [AuthGuard], loadChildren: () => import('./pages/curation').then(m => m.CurationModule) },
  { path: 'user', canActivate: [AuthGuard], loadChildren: () => import('./pages/user').then(m => m.UserModule) },
  { path: 'sales', canActivate: [AuthGuard], loadChildren: () => import('./pages/sales').then(m => m.SalesModule) },
  { path: 'news', canActivate: [AuthGuard], loadChildren: () => import('./pages/news').then(m => m.NewsModule) },
  { path: 'etc', canActivate: [AuthGuard], loadChildren: () => import('./pages/etc').then(m => m.EtcModule) },
  { path: 'login', loadChildren: () => import('./pages/login').then(m => m.LoginModule) },
  { path: '**', canActivate: [AuthGuard], component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
