import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { BusyService } from '../../services/busy';

@Injectable({
  providedIn: 'root'
})
export class BusyGuard<T = any> implements CanDeactivate<T> {
  constructor(
    private busyService: BusyService,
  ) {}

  canDeactivate(
    component: T,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.busyService.busy$.pipe(
      map((busy) => busy ? confirm('아직 처리 중인 작업이 있습니다. 페이지를 떠나면 데이터가 소실될 수 있습니다. 계속하시겠습니까?') : true),
    );
  }
}
