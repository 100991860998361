import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';

@Component({
  selector: 'app-countdown',
  template: '{{ countdownText }}',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnChanges, OnDestroy {
  @Input() format: 'hms' | 'ms' | 's' = 'hms';
  @Input() targetDate: Date;
  @Input() endText: string;
  @Input() overflow = false;

  countdownText = '00:00:00';

  private showHours = true;
  private showMinutes = true;
  private showSeconds = true;
  private intervalSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.format) {
      const format = ['hms', 'ms', 's'].indexOf(this.format) > -1 ? this.format : 'hms';
      this.showHours = format.indexOf('h') > -1;
      this.showMinutes = format.indexOf('m') > -1;
      this.showSeconds = format.indexOf('s') > -1;
    }
    if (changes.targetDate) {
      unsubscribeAll([this.intervalSubscription]);

      this.countdownText = this.getCountdownText();
      this.changeDetectorRef.markForCheck();

      this.intervalSubscription = timer(1000, 1000).pipe(
        takeWhile(() => this.targetDate.getTime() > Date.now(), true),
      ).subscribe(() => {
        this.countdownText = this.getCountdownText();
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.intervalSubscription,
    ])
  }

  getCountdownText(): string {
    const diff = Math.max(this.targetDate.getTime() - Date.now(), 0);
    if (diff === 0 && this.endText !== undefined && this.endText !== null) {
      return this.endText;
    }

    const hoursOverflow = this.overflow && this.showHours;
    const hoursNum = Math.floor(diff / 1000 / 60 / 60);
    const hours = this.showHours
      ? hoursOverflow ? (hoursNum + ':') : ('0' + (hoursNum % 24)).slice(-2) + ':'
      : '';

    const minutesOverflow = this.overflow && this.showMinutes && !hoursOverflow;
    const minutesNum = Math.floor(diff / 1000 / 60);
    const minutes = this.showMinutes
      ? minutesOverflow ? (minutesNum + ':') : ('0' + (minutesNum % 60)).slice(-2) + ':'
      : '';

    const secondsOverflow = this.overflow && this.showSeconds && !hoursOverflow && !minutesOverflow;
    const secondsNum = Math.floor(diff / 1000);
    const seconds = this.showSeconds
      ? secondsOverflow ? (secondsNum + '') : ('0' + (secondsNum % 60)).slice(-2)
      : '';

    return `${hours}${minutes}${seconds}`;
  }
}
