import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WithId } from 'mongodb';
import { BehaviorSubject, combineLatest, defer, EMPTY, from, Subject, Subscription } from 'rxjs';
import { auditTime, catchError, concatAll, finalize, share, startWith, switchMap, tap } from 'rxjs/operators';
import { MongoInsert } from '../../../types/db-insert.type';
import { DBContentCut, DBMedia } from '../../../types/mongodb.type';
import { copyError, isNotNullish, showErrorSnackbar } from '../../../utils/etc.util';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';
import { AlertService } from '../../services/alert';
import { ApiService } from '../../services/api';
import { BusyService } from '../../services/busy';
import { MediaUploading, UploadService } from '../../services/upload';
import { ContentImage } from '../content-viewer/content-image/content-image.type';
import { ContentVideo } from '../content-viewer/content-video/content-video.type';

interface CutAddButtonInfo {
  type: string;
  icon: string;
  name: string;
}

const CUT_ADD_LIST: Array<CutAddButtonInfo> = [
  {
    type: 'text',
    icon: 'notes',
    name: '텍스트',
  },
  {
    type: 'image',
    icon: 'image',
    name: '이미지',
  },
  {
    type: 'video',
    icon: 'movie',
    name: '동영상',
  },
];

function isValidId(id: string): boolean {
  const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');

  if (id == null) {
    return false;
  }

  if (typeof id === 'string') {
    return id.length === 12 || (id.length === 24 && checkForHexRegExp.test(id));
  }

  return false;
}

// TODO: 업로드 중 contentType이나 contentId가 바뀌었을 때의 처리
@Component({
  selector: 'app-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() contentType: string;
  @Input() contentId: string;
  @Input() contents: Array<DBContentCut>;
  @Input() contentSaved?: Subject<void>;

  isDraggingOver = false;
  draggingOverElement: Element | null;
  isPreparingUpload = false;
  isUploading = false;
  isToolbarOpenMobile = false;

  cutAddList: Array<CutAddButtonInfo> = CUT_ADD_LIST;

  mediaList: Array<WithId<DBMedia | MediaUploading>> = [];
  mediaUploadingList: Array<WithId<DBMedia | MediaUploading>> = [];
  mediaUsed: { [id: string]: boolean; };
  mediaListSortType: 'content' | 'nameAsc' | 'nameDesc' | 'createdAtDesc' | 'createdAtAsc' = 'content';
  mediaListViewType: 'list' | 'gallery' = 'list';

  private contentType$ = new BehaviorSubject<string | null>(null);
  private contentId$ = new BehaviorSubject<string | null>(null);
  private contents$ = new BehaviorSubject<Array<DBContentCut> | null>(null);
  private contentSaved$ = new BehaviorSubject<Subject<void> | null>(null);

  private mediaListSubscription: Subscription;
  private uploadFileSubscriptions: Array<Subscription> = [];
  private mediaUploadingSubjectSubject = new BehaviorSubject<Array<BehaviorSubject<Array<WithId<DBMedia | MediaUploading>>>>>([]);
  private mediaUploadingSubscription?: Subscription;
  private renameSubscriptions: Array<Subscription> = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    private apiService: ApiService,
    private alertService: AlertService,
    private uploadService: UploadService,
    private busyService: BusyService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contentType) {
      this.contentType$.next(this.contentType);
    }
    if (changes.contentId) {
      this.contentId$.next(this.contentId);
    }
    if (changes.contents) {
      this.contents$.next(this.contents);
    }
    if (changes.contentSaved) {
      this.contentSaved$.next(this.contentSaved ?? null);
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.contentType$,
      this.contentId$,
      from(this.contentSaved$).pipe(
        switchMap((subject) => subject ?? EMPTY),
        startWith(undefined),
      ),
    ]).pipe(
      auditTime(0),
    ).subscribe(([contentType, contentId]) => {
      this.mediaList = [];
      this.loadMediaList(contentType, contentId);
    });

    this.mediaUploadingSubscription = from(this.mediaUploadingSubjectSubject).pipe(
      switchMap((subject) => combineLatest(subject.map((v) => from(v)))),
      concatAll(),
    ).subscribe((mediaUploadingList) => {
      this.mediaUploadingList = mediaUploadingList.slice().reverse();
      this.changeDetectorRef.markForCheck();
    });

    this.contents$.subscribe(() => {
      this.sortMediaList();
    });
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.mediaListSubscription,
      ...this.uploadFileSubscriptions,
      this.mediaUploadingSubscription,
      ...this.renameSubscriptions,
    ])
  }

  /**
   * 컷의 순서를 바꿉니다.
   * @param cut 이동할 컷
   * @param indexOrDirection 이동할 위치 또는 방향
   */
  moveCut(cut: DBContentCut, indexOrDirection: number | 'up' | 'down'): void {
    const index = this.contents.indexOf(cut);
    if (index > -1) {
      const targetIndex = typeof indexOrDirection === 'number' ? indexOrDirection : index + (indexOrDirection === 'up' ? -1 : 1);
      this.contents.splice(index, 1);
      this.contents.splice(targetIndex, 0, cut);
      if (this.mediaListSortType === 'content') {
        this.sortMediaList();
      }
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * 컷을 삭제합니다.
   * @param cut 삭제할 컷
   */
  onClickDeleteCut(cut: DBContentCut): void {
    const index = this.contents.indexOf(cut);
    if (index > -1) {
      this.contents.splice(index, 1);
      if (this.mediaListSortType === 'content') {
        this.sortMediaList();
      }
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * 본문에 컷을 추가합니다.
   * @param type 컷 종류
   */
  onClickAddCut(type: string): void {
    this.contents.push({
      type,
      width: 0,
      height: 0,
      data: {},
    });
    if (this.mediaListSortType === 'content') {
      this.sortMediaList();
    }
  }

  /**
   * 파일 선택 시 파일을 업로드합니다.
   * @param ev 파일 선택 이벤트
   */
  onChangeFile(ev: Event): void {
    const input = ev.target as HTMLInputElement;

    if (input.files != null) {
      this.uploadFiles(Array.from(input.files));
      input.value = '';
    }
  }

  uploadFiles(files: Array<File>): void {
    const uploadObservable = this.uploadService.upload(files, this.contentType, this.contentId, 'content').pipe(
      share(),
    );
    const sub = defer(() => {
      const mediaUploadingSubject = new BehaviorSubject<Array<WithId<DBMedia | MediaUploading>>>([]);
      this.mediaUploadingSubjectSubject.next(this.mediaUploadingSubjectSubject.value.concat(mediaUploadingSubject));

      return uploadObservable.pipe(
        tap((progress) => {
          mediaUploadingSubject.next(progress.mediaUploadingList);
          if (progress.mediaUploadedNewList?.length) {
            this.mediaList = progress.mediaUploadedNewList.concat(this.mediaList);
            this.sortMediaList();
            for (const media of progress.mediaUploadedNewList) {
              this.onClickAddToContents(media);
            }
            this.changeDetectorRef.markForCheck();
          }

          this.changeDetectorRef.markForCheck();

          if (progress.completed) {
            if (progress.errorList?.length) {
              const snackBar = this.matSnackBar.open('전체 또는 일부 업로드 과정에서 오류가 발생했습니다.', '오류 내용 복사', { duration: 5000 });
              snackBar.onAction().subscribe(() => {
                copyError(progress.errorList!);
              });
            }
          }
        }),
        catchError((err) => {
          const snackBar = this.matSnackBar.open(`${err.message ?? '알 수 없는 오류가 발생했습니다.'}`, '오류 내용 복사', { duration: 5000 });
          snackBar.onAction().subscribe(() => {
            copyError(err);
          });
          return EMPTY;
        }),
        finalize(() => {
          this.mediaUploadingSubjectSubject.next(this.mediaUploadingSubjectSubject.value.filter((subject) => subject !== mediaUploadingSubject));
        }),
      );
    }).pipe(
      finalize(() => {
        this.uploadFileSubscriptions = this.uploadFileSubscriptions.filter((item) => item !== sub);
      }),
    ).subscribe();
    this.uploadFileSubscriptions = this.uploadFileSubscriptions.concat(sub);
    this.busyService.mark(sub);
  }

  onChangeMediaListSortType(): void {
    this.sortMediaList();
  }

  /**
   * 들어온 드래그가 파일을 포함하면 오버레이를 표시합니다.
   * @param ev 드래그 이벤트
   */
  onDragEnterMediaList(ev: DragEvent): void {
    const draggingOverElement = (ev.target as Element).closest('.media-tab');
    if (draggingOverElement && ev.dataTransfer != null && ev.dataTransfer.types.indexOf('Files') > -1) {
      ev.preventDefault();
      this.draggingOverElement = draggingOverElement;
      this.isDraggingOver = true;
    }
  }

  /**
   * 드래그가 나가면 오버레이를 숨깁니다.
   * @param ev 드래그 이벤트
   */
  onDragLeaveMediaList(ev: DragEvent): void {
    if (this.isDraggingOver) {
      ev.preventDefault();
      this.draggingOverElement = null;
      this.isDraggingOver = false;
    }
  }

  /**
   * 드래그가 파일을 포함하면 브라우저 기본 동작을 막습니다.
   * @param ev 드래그 이벤트
   */
  onDragOverMediaList(ev: DragEvent): void {
    if (this.isDraggingOver) {
      ev.preventDefault();
    }
  }

  /**
   * 파일을 드랍한 경우 파일을 업로드합니다.
   * @param ev 드래그 이벤트
   */
  onDropMediaList(ev: DragEvent): void {
    if (this.isDraggingOver) {
      ev.preventDefault();
      this.draggingOverElement = null;
      this.isDraggingOver = false;

      if (ev.dataTransfer != null) {
        this.uploadFiles(Array.from(ev.dataTransfer.files));
      }
    }
  }

  /**
   * 미디어를 본문에 바로 추가합니다.
   * 이미지와 동영상만 지원합니다.
   * @param media 추가할 미디어
   */
  onClickAddToContents(media: WithId<DBMedia | MediaUploading>): void {
    if (media.type.startsWith('image')) {
      const cut: ContentImage = {
        type: 'image',
        width: media.width,
        height: media.height,
        data: {
          mediaId: media._id,
          url: media.url!,
        },
      };
      this.contents.push(cut);
      if (this.mediaListSortType === 'content') {
        this.sortMediaList();
      }
    } else if (media.type.startsWith('video')) {
      const cut: ContentVideo = {
        type: 'video',
        width: media.width,
        height: media.height,
        data: {
          mediaId: media._id,
          url: media.url!,
          posterUrl: media.posterUrl ?? undefined,
          muted: true,
          controls: false,
          loop: true,
        },
      };
      this.contents.push(cut);
      if (this.mediaListSortType === 'content') {
        this.sortMediaList();
      }
    }
  }

  /**
   * 미디어의 이름을 변경합니다.
   * @param media 이름 변경할 미디어
   */
  async onClickRenameMedia(media: WithId<DBMedia | MediaUploading>): Promise<void> {
    const newName = await this.alertService.prompt('이름 변경', '새 미디어 이름을 입력해주세요.', media.name);

    if (newName == null) {
      return;
    }

    if (newName === '') {
      this.matSnackBar.open('변경할 이름을 입력해주세요..', '확인', { duration: 3000 });
      return;
    }

    this.renameMedia(media._id, newName);
  }

  /**
   * 미디어 삭제 버튼을 눌렀을 때 미디어를 삭제합니다.
   * @param media 삭제할 미디어
   */
  onClickDeleteMedia(media: WithId<DBMedia | MediaUploading>): void {
    if (!media) {
      return;
    }
    if (!media.isUploaded && 'progress' in media) {
      this.matSnackBar.open('업로드가 완료되지 않은 미디어는 삭제할 수 없습니다.', '확인', { duration: 3000 });
      return;
    }

    this.uploadService.delete(media._id).then(() => {
      const index = this.mediaList.indexOf(media);
      if (index > -1) {
        this.mediaList.splice(index, 1);
        this.mediaList = this.mediaList.slice();
        this.sortMediaList();
        this.changeDetectorRef.markForCheck();
      }
      this.matSnackBar.open('미디어가 삭제되었습니다.', '확인', { duration: 3000 });
    }, (err) => showErrorSnackbar(this.matSnackBar, err));
  }

  onClickViewerEmpty(): void {
    while (this.contents?.length) {
      this.contents.shift();
    }
  }

  /**
   * 컷의 타입에 해당하는 이름을 반환합니다.
   * @param type 컷 타입
   */
  getTypeName(type: string): string | null {
    const cut = CUT_ADD_LIST.find((c) => c.type === type);
    return cut ? cut.name : null;
  }

  trackMedia(_: number, media: MongoInsert<DBMedia | MediaUploading | MediaUploading>): string {
    return media._id != null ? media._id : Math.random() + '';
  }

  formatPercentage(ratio: number): string {
    return `${Math.round(ratio * 100)}%`;
  }

  async deleteUnusedMedia(): Promise<void> {
    const unusedList = this.mediaList.filter((media) =>
      media.isUploaded
      && !this.contents.some((content) =>
        ['image', 'video'].includes(content.type)
        && (content as ContentImage | ContentVideo).data.mediaId === media._id,
      ),
    );

    for (const media of unusedList) {
      await this.uploadService.delete(media._id);
      const index = this.mediaList.indexOf(media);
      if (index > -1) {
        this.mediaList.splice(index, 1);
        this.mediaList = this.mediaList.slice();
        this.sortMediaList();
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private loadMediaList(contentType: string | null, contentId: string | null): void {
    unsubscribeAll([this.mediaListSubscription]);

    if (contentType == null || contentId == null) {
      this.mediaList = [];
      return;
    }

    this.mediaListSubscription = this.apiService.mediaV1MediaList({
      uploadedContentType: contentType,
      uploadedContentId: contentId,
      category: 'content',
    }).subscribe((res) => {
      this.mediaList = [...this.mediaList, ...res.result.list];
      this.sortMediaList();
      this.changeDetectorRef.markForCheck();
    });
  }

  private renameMedia(id: string, name: string): void {
    const sub = this.apiService.mediaV1MediaRename({ _id: id, name }).pipe(
      tap((res) => {
        const newMedia = res.result.media;

        if (newMedia == null) {
          this.mediaList = [];
          this.loadMediaList(this.contentType, this.contentId);
          return;
        }

        const index = this.mediaList.findIndex((media) => media._id === id);
        this.mediaList = index > -1 ?
          this.mediaList.map((v, i) => i === index ? newMedia : v) :
          this.mediaList.concat(newMedia);
        this.sortMediaList();

        this.changeDetectorRef.markForCheck();
        this.matSnackBar.open('미디어의 이름이 변경되었습니다.', '확인', { duration: 3000 });
      }),
      catchError((err) => {
        showErrorSnackbar(this.matSnackBar, err);
        return EMPTY;
      }),
      finalize(() => {
        this.renameSubscriptions = this.renameSubscriptions.filter((v) => v !== sub);
        this.changeDetectorRef.markForCheck();
      }),
    ).subscribe();
    this.renameSubscriptions.push(sub);
  }

  /**
   * 미디어 목록을 정렬합니다.
   */
  private sortMediaList(): void {
    const contentMediaIdList = this.extractMediaIdContents(this.contents);

    let sortFunction: (a: WithId<DBMedia | MediaUploading>, b: WithId<DBMedia | MediaUploading>) => number = () => 0;
    switch (this.mediaListSortType) {
      case 'createdAtDesc':
        sortFunction = (a, b) => (new Date(b.createdAt || 0)).getTime() - (new Date(a.createdAt || 0)).getTime();
        break;
      case 'createdAtAsc':
        sortFunction = (a, b) => (new Date(a.createdAt || 0)).getTime() - (new Date(b.createdAt || 0)).getTime();
        break;
      case 'nameAsc':
        sortFunction = (a, b) => a.name.localeCompare(b.name);
        break;
      case 'nameDesc':
        sortFunction = (a, b) => b.name.localeCompare(a.name);
        break;
      case 'content':
      default:
        sortFunction = (a, b) => {
          const aIndex = contentMediaIdList.indexOf(a._id);
          const bIndex = contentMediaIdList.indexOf(b._id);

          if (aIndex >= 0 && bIndex >= 0) {
            return aIndex - bIndex;
          } else if (aIndex >= 0) {
            return -1;
          } else if (bIndex >= 0) {
            return 1;
          }
          return (new Date(a.createdAt || 0)).getTime() - (new Date(b.createdAt || 0)).getTime();
        };
    }

    this.mediaList = this.mediaList.slice().sort(sortFunction);
    this.mediaUsed = this.mediaList.reduce((acc, media) => (acc[media._id] = contentMediaIdList.includes(media._id), acc), {});
    this.changeDetectorRef.markForCheck();
  }

  private extractMediaIdContents(obj: any): Array<string> {
    if (obj == null || (typeof obj !== 'object' && typeof obj !== 'string')) {
      return [];
    }
    if (isValidId(obj)) {
      return [obj];
    } else if (Array.isArray(obj)) {
      return obj.map((val) => this.extractMediaIdContents(val))
        .reduce<Array<string>>((acc, cur) => [...acc, ...(cur ?? [])], []);
    } else {
      return Object.keys(obj)
        .map((key) =>
          key === 'mediaId' && isValidId(obj[key]) ?
          [obj[key]] :
          typeof obj[key] === 'object' ?
          this.extractMediaIdContents(obj[key]) :
          null,
        )
        .filter(isNotNullish)
        .reduce<Array<string>>((acc, cur) => [...acc, ...(cur ?? [])], []);
    }
  }
}
