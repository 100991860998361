import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WithId } from 'mongodb';
import { BehaviorSubject, catchError, combineLatest, debounceTime, delay, distinctUntilChanged, from, map, of, shareReplay, startWith, Subscription, switchMap } from 'rxjs';
import { AdminPermission } from '../../../constants/admin-permission.constant';
import { DBPromotion } from '../../../types/mongodb.type';
import { permissionToRegex, showErrorSnackbar } from '../../../utils/etc.util';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';
import { AdminService } from '../../services/admin';
import { ApiService } from '../../services/api';

@Component({
  selector: 'app-input-promotion-id',
  templateUrl: './input-promotion-id.component.html',
  styleUrls: ['./input-promotion-id.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPromotionIdComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPromotionIdComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label: string;
  @Input() labelText: string;
  get clearable(): boolean { return this.innerClearable; }
  @Input() set clearable(value: boolean) { this.innerClearable = value != null && value !== false; }

  canListPromotion = false;
  canReadPromotion = false;

  searchPromotionList?: Array<WithId<DBPromotion>>;
  notFoundPromotion?: { _id: string };
  selectedPromotion?: WithId<DBPromotion>;

  private innerClearable = false;
  private isDisabled: boolean;
  private innerValue?: string;

  private onChangeCallback: (value: string | undefined) => void;
  private onTouchedCallback: () => void;

  private permissionListSubscription?: Subscription;
  private valueChangeSubject = new BehaviorSubject<string | undefined>(undefined);
  private searchInputSubject = new BehaviorSubject<string>('');
  private searchPromotionListSubscription?: Subscription;
  private selectedPromotionSubscription?: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    private apiService: ApiService,
    private adminService: AdminService,
  ) {}

  get value(): string | undefined {
    return this.innerValue;
  }

  @Input()
  set value(value: string | undefined) {
    this.writeValue(value);
    this.onChangeCallback?.(this.value);
  }

  get disabled(): boolean {
    return this.isDisabled;
  }

  @Input()
  set disabled(value: boolean) {
    this.isDisabled = !!value;
  }

  ngOnInit(): void {
    const permissionObservable = this.adminService.getPermissionListObservable().pipe(
      map(({ permissionList }) => {
        const permissions = permissionList?.map((permission) => permissionToRegex(permission)) ?? [];
        return {
          canListPromotion: permissions.some((permission) => permission.test(AdminPermission.PROMOTION_LIST)),
          canReadPromotion: permissions.some((permission) => permission.test(AdminPermission.PROMOTION_READ)),
        };
      }),
      shareReplay(1),
    );

    this.permissionListSubscription = permissionObservable.subscribe(({ canListPromotion, canReadPromotion }) => {
      this.canListPromotion = canListPromotion;
      this.canReadPromotion = canReadPromotion;
      this.changeDetectorRef.markForCheck();
    });

    this.searchPromotionListSubscription = combineLatest({
      list: permissionObservable.pipe(
        switchMap(({ canListPromotion, canReadPromotion }) => canListPromotion && canReadPromotion ?
          from(this.searchInputSubject).pipe(
            distinctUntilChanged(),
            debounceTime(500),
            switchMap((search) => this.apiService.newsV1PromotionList({ page: 1, title: search })),
            map((res) => res.result.list),
            catchError((err) => {
              showErrorSnackbar(this.matSnackBar, err);
              return of([] as Array<WithId<DBPromotion>>);
            }),
          ) :
          of([] as Array<WithId<DBPromotion>>),
        ),
      ),
      value: from(this.valueChangeSubject).pipe(
        delay(0),
      ),
    }).subscribe(({ list, value }) => {
      this.searchPromotionList = list.filter((item) => item._id !== value);
      this.changeDetectorRef.markForCheck();
    });

    this.selectedPromotionSubscription = permissionObservable.pipe(
      switchMap(({ canListPromotion, canReadPromotion }) => canListPromotion && canReadPromotion ?
        from(this.valueChangeSubject).pipe(
          switchMap((value) => {
            if (value != null && value.length === 24) {
              const searchPromotionMatch = this.searchPromotionList?.find((item) => item._id === value);
              return this.apiService.newsV1PromotionRead({ _id: value }).pipe(
                map((res) => ({ notFoundPromotion: undefined, selectedPromotion: res.result.promotion ?? undefined })),
                catchError((err) => {
                  showErrorSnackbar(this.matSnackBar, err);
                  return of({ notFoundPromotion: { _id: value }, selectedPromotion: undefined });
                }),
                startWith(searchPromotionMatch != null ?
                  { notFoundPromotion: undefined, selectedPromotion: searchPromotionMatch } :
                  { notFoundPromotion: { _id: value }, selectedPromotion: undefined },
                ),
              );
            }
            return of({ notFoundPromotion: value != null && value !== '' ? { _id: value } : undefined, selectedPromotion: undefined })
          }),
        ) :
        of({ notFoundPromotion: undefined, selectedPromotion: undefined }),
      ),
    ).subscribe(({ notFoundPromotion, selectedPromotion }) => {
      this.notFoundPromotion = notFoundPromotion;
      this.selectedPromotion = selectedPromotion;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.permissionListSubscription,
      this.searchPromotionListSubscription,
      this.selectedPromotionSubscription,
    ]);
  }

  onInput(ev: Event): void {
    const elem = ev.target as HTMLInputElement;
    this.writeValue(elem.value);
    this.onChangeCallback?.(this.value);
  }

  onBlur(): void {
    this.onTouchedCallback?.();
  }

  onOpenedChange(input: HTMLInputElement, open: boolean): void {
    if (open) {
      input.focus();
      input.value = '';
      this.searchInputSubject.next('');
    }
  }

  searchPromotion(text: string): void {
    this.searchInputSubject.next(text ?? '');
  }

  onClickClear(ev: Event): void {
    ev.stopPropagation();
    this.value = undefined;
    this.changeDetectorRef.markForCheck();
  }

  writeValue(value: string | undefined): void {
    this.innerValue = value;
    this.valueChangeSubject.next(value);
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(callback: (value: string | undefined) => void): void {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
