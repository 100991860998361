export interface NavigationItem {
  /**
   * 표시할 아이콘
   */
  icon?: string;
  /**
   * 표시될 이름
   */
  name: string;
  /**
   * 경로
   */
  path: string;
  /**
   * 필요한 권한
   */
  requiredPermissions?: Array<string>;
  /**
   * 하위 항목
   */
  subItems?: Array<NavigationItem>;
  /**
   * 계산된 경로
   */
  calculatedPath?: string;
  /**
   * 펼침 여부
   */
  isOpen?: boolean;
  /**
   * 이용 불가 여부
   */
  blocked?: boolean;
}
