import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Controller } from 'scrollmagic';
import { ContentImage } from './content-image.type';

@Component({
  selector: 'app-content-image',
  templateUrl: './content-image.component.html',
  styleUrls: ['./content-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentImageComponent {
  @Input() cut?: ContentImage;
  @Input() scrollMagic: Controller | null = null;
  @Input() slideVisible: boolean | null = null;

  @Output() clickLink = new EventEmitter<CustomEvent<string>>();
  @Output() clickStatic = new EventEmitter<MouseEvent>();

  @HostListener('click', ['$event'])
  onClick(ev: MouseEvent): void {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.cut?.data.link) {
      this.clickLink.emit(new CustomEvent('clickLink', { detail: this.cut.data.link }));
    } else {
      this.clickStatic.emit(ev);
    }
  }

  onClickButton(ev: MouseEvent): void {
    ev.preventDefault();
    ev.stopPropagation();
    this.clickLink.emit(new CustomEvent('clickLink', { detail: this.cut?.data.link }));
  }
}
