import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../../services/api';

@Pipe({
  name: 'mediaUrl'
})
export class MediaUrlPipe implements PipeTransform {
  constructor(
    private apiSerivce: ApiService,
  ) {}

  /**
   * 미디어 id를 미디어 url로 변환합니다.
   * @param value 미디어 id
   */
  transform(value: string | null | undefined): Observable<string | null> {
    return value != null
      ? this.apiSerivce.mediaV1MediaRead({ _id: value }).pipe(
        map((res) => res.result.media?.url ?? null),
        catchError(() => of(null)),
      )
      : of(null);
  }
}
