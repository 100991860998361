<div *ngFor="let stat of stats" class="stat">
  <div class="stat-name">{{ stat.name }}</div>
  <div class="stat-value">
    <span class="stat-number">
      <ng-template #valueTemplate>
        <ng-template [ngIf]="isNumber(stat.value)" [ngIfElse]="stringTemplate">{{ stat.value | formatNumber:2 }}</ng-template>
        <ng-template #stringTemplate>{{ nullishDash(stat.value) }}</ng-template>
      </ng-template>
      <a *ngIf="stat.routerLink; else valueTemplate" [routerLink]="stat.routerLink">
        <ng-container [ngTemplateOutlet]="valueTemplate"></ng-container>
      </a>
    </span>
    {{ stat.unit }}
  </div>
</div>
