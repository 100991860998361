import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { WAI_LUXON_DATE_FORMAT } from '../../../constants/luxon-date-formats.constant';
import { DateRangePickerContentComponent } from './date-range-picker-content';
import { DateRangePickerComponent } from './date-range-picker.component';

@NgModule({
  declarations: [
    DateRangePickerContentComponent,
    DateRangePickerComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatLuxonDateModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: WAI_LUXON_DATE_FORMAT },
  ],
  exports: [
    DateRangePickerContentComponent,
    DateRangePickerComponent,
  ],
})
export class DateRangePickerModule {}
