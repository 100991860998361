import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentText } from '../../content-viewer/content-text';

@Component({
  selector: 'app-content-text-edit',
  templateUrl: './content-text-edit.component.html',
  styleUrls: ['./content-text-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentTextEditComponent {
  @Input() cut: ContentText;

  constructor() {}
}
