import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { VideoPreviewModule } from '../video-preview';
import { ContentEditorComponent } from './content-editor.component';
import { ContentImageEditComponent } from './content-image-edit';
import { ContentTextEditComponent } from './content-text-edit';
import { ContentVideoEditComponent } from './content-video-edit';

@NgModule({
  declarations: [
    ContentEditorComponent,
    ContentImageEditComponent,
    ContentTextEditComponent,
    ContentVideoEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    VideoPreviewModule,
  ],
  exports: [
    ContentEditorComponent,
    ContentImageEditComponent,
    ContentTextEditComponent,
    ContentVideoEditComponent,
  ],
})
export class ContentEditorModule {}
