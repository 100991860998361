import { ContentCut } from '../content-viewer.type';

export interface ContentVideo extends ContentCut {
  type: 'video';
  data: {
    mediaId: string;
    url: string;
    posterUrl?: string;
    muted: boolean;
    controls: boolean;
    loop: boolean;
  };
}
