import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { ContentImageComponent } from './content-image';
import { ContentTextComponent } from './content-text';
import { ContentVideoComponent } from './content-video';
import { ContentViewerComponent } from './content-viewer.component';

@NgModule({
  declarations: [
    ContentImageComponent,
    ContentTextComponent,
    ContentVideoComponent,
    ContentViewerComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
  ],
  exports: [
    ContentImageComponent,
    ContentTextComponent,
    ContentVideoComponent,
    ContentViewerComponent,
  ],
})
export class ContentViewerModule {}
