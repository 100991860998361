import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WithId } from 'mongodb';
import { BehaviorSubject, combineLatest, defer, EMPTY, from, Subscription } from 'rxjs';
import { catchError, concatAll, finalize, share, switchMap, tap } from 'rxjs/operators';
import { MediaMediaListParams } from '../../../types/api/media.type';
import { MongoInsert } from '../../../types/db-insert.type';
import { DBMedia } from '../../../types/mongodb.type';
import { copyError, isNotNullish, randomHexString, showErrorSnackbar } from '../../../utils/etc.util';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';
import { AlertService } from '../../services/alert';
import { ApiService } from '../../services/api';
import { BusyService } from '../../services/busy';
import { MediaUploading, UploadService } from '../../services/upload';

interface UnknownMedia {
  _id: string;
  type: string;
  name: string;
  url: string;
}

function getUnknownMedia(mediaId: string): UnknownMedia {
  return {
    _id: mediaId,
    type: 'image/svg',
    name: mediaId,
    url: 'assets/unknown-media.svg',
  };
}

// TODO: mediaList에 없는 미디어 id 값이 들어올 시 따로 불러와 표시할 수 있도록 하기
// TODO: 동일한 미디어 목록을 사용하는 컴포넌트끼리 미디어 목록 공유
@Component({
  selector: 'app-select-media',
  templateUrl: './select-media.component.html',
  styleUrls: ['./select-media.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectMediaComponent),
      multi: true
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMediaComponent implements OnChanges, OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('dialogTemplate', { static: true }) dialogTemplate: TemplateRef<any>;

  @Input() mode: 'image' | 'video' | string;
  @Input() contentType: string;
  @Input() contentId: string;
  @Input() mediaCategory: string;
  @Input() label: string;
  get clearable(): boolean { return this.innerClearable; }
  @Input() set clearable(value: boolean) { this.innerClearable = value != null && value !== false; }
  get multiple(): boolean { return this.innerMultiple; }
  @Input() set multiple(value: boolean) { this.innerMultiple = value != null && value !== false; }
  get readonly(): boolean { return this.innerReadonly; }
  @Input() set readonly(value: boolean) { this.innerReadonly = value != null && value !== false; }
  get disabled(): boolean { return this.isDisabled; }
  @Input() set disabled(value: boolean) { this.isDisabled = value != null && value !== false; }

  get value(): string | Array<string> | undefined {
    if (this.innerValue != null) {
      return this.innerMultiple ? this.innerValue.slice() : this.innerValue[0];
    }

    return undefined;
  }

  @Input()
  set value(value: string | Array<string> | undefined) {
    this.writeValue(value);
    this.onChangeCallback?.(this.value);
  }

  random: string;

  isDraggingOver = [false, false];

  mediaList: Array<WithId<DBMedia | MediaUploading>>;
  mediaUploadingList: Array<DBMedia>;

  private innerClearable = false;
  private innerMultiple = false;
  private innerReadonly = false;
  private dialog: MatDialogRef<any>;
  private mediaListSubscription: Subscription;
  private uploadFileSubscriptions: Array<Subscription> = [];
  private mediaUploadingSubjectSubject = new BehaviorSubject<Array<BehaviorSubject<Array<WithId<DBMedia | MediaUploading>>>>>([]);
  private mediaUploadingSubscription?: Subscription;

  private innerValue: Array<string> = [];
  private onChangeCallback: (value: string | Array<string> | undefined) => void;
  private onTouchedCallback: () => void;
  private isDisabled = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    private apiService: ApiService,
    private alertService: AlertService,
    private uploadService: UploadService,
    private busyService: BusyService,
  ) {
    this.random = randomHexString();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.contentType ||
      changes.contentId ||
      changes.mediaCategory
    ) {
      this.fetchMediaList();
    }
    if (changes.multiple) {
      this.value = this.value;
    }
  }

  ngOnInit(): void {
    this.mediaUploadingSubscription = from(this.mediaUploadingSubjectSubject).pipe(
      switchMap((subject) => combineLatest(subject.map((v) => from(v)))),
      concatAll(),
    ).subscribe((mediaUploadingList) => {
      this.mediaUploadingList = mediaUploadingList.slice().reverse();
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.mediaListSubscription,
      ...this.uploadFileSubscriptions,
      this.mediaUploadingSubscription,
    ])
  }

  /**
   * 들어온 드래그가 파일을 포함하면 오버레이를 표시합니다.
   * @param ev 드래그 이벤트
   */
  onDragEnterMediaList(ev: DragEvent, index: 0 | 1): void {
    if (this.disabled || this.readonly) {
      return;
    }

    if (ev.dataTransfer?.files) {
      ev.preventDefault();
      this.isDraggingOver[index] = true;
    }
  }

  /**
   * 드래그가 나가면 오버레이를 숨깁니다.
   * @param ev 드래그 이벤트
   */
  onDragLeaveMediaList(ev: DragEvent, index: 0 | 1): void {
    ev.preventDefault();
    this.isDraggingOver[index] = false;
  }

  /**
   * 드래그가 파일을 포함하면 브라우저 기본 동작을 막습니다.
   * @param ev 드래그 이벤트
   */
  onDragOverMediaList(ev: DragEvent, index: 0 | 1): void {
    if (this.isDraggingOver[index]) {
      ev.preventDefault();
    }
  }

  /**
   * 파일을 드랍한 경우 파일을 업로드합니다.
   * @param ev 드래그 이벤트
   */
  onDropMediaList(ev: DragEvent, index: 0 | 1): void {
    if (this.isDraggingOver[index]) {
      ev.preventDefault();
      this.isDraggingOver[index] = false;

      if (this.disabled || this.readonly) {
        return;
      }

      if (ev.dataTransfer?.files != null) {
        this.uploadFile(ev.dataTransfer.files, index === 0);
      }
    }
  }

  /**
   * 드래그 앤 드랍 중 드랍 오버레이가 고정되면 클릭하여 해제
   */
  onClickDropOverlay(index: 0 | 1): void {
    this.isDraggingOver[index] = false;
  }

  onBlur(): void {
    if (!this.dialog) {
      this.onTouchedCallback?.();
    }
  }

  onChangeFile(ev: Event): void {
    if (this.disabled || this.readonly) {
      return;
    }

    const input = ev.target as HTMLInputElement;

    if (input.files != null) {
      this.uploadFile(input.files);
      input.value = '';
    }
  }

  onClickUnselect(ev: MouseEvent, mediaId: string): void {
    ev.stopPropagation();

    if (this.disabled || this.readonly) {
      return;
    }

    if (this.multiple) {
      const value = this.innerValue?.slice() ?? [];
      const index = value.indexOf(mediaId);
      if (index > -1) {
        value.splice(index, 1);
      } else {
        value.push(mediaId);
      }

      this.value = value;
    } else {
      this.value = undefined;
    }
  }

  onClickSelectMedia(mediaId: string): void {
    if (this.disabled || this.readonly) {
      return;
    }

    this.selectMedia(mediaId);
  }

  async onClickDeleteMedia(ev: MouseEvent, mediaId: string): Promise<void> {
    ev.stopPropagation();

    if (!await this.alertService.confirm('삭제 확인', '정말로 삭제하시겠습니까?')) {
      return;
    }

    if (this.disabled || this.readonly) {
      return;
    }

    this.deleteMedia(mediaId).catch((err) => showErrorSnackbar(this.matSnackBar, err));
  }

  getSelectedMediaList(): Array<WithId<DBMedia | MediaUploading> | UnknownMedia> {
    return this.innerValue?.map((mediaId) => this.mediaList?.find((media) => media._id === mediaId) ?? getUnknownMedia(mediaId)) ?? [];
  }

  hasValue(mediaId: string): boolean {
    return this.innerValue?.includes(mediaId) ?? false;
  }

  getMediaTypeName(): string {
    switch (this.mode) {
      case 'image':
        return '이미지';
      case 'video':
        return '동영상';
      default:
        return '이미지/동영상';
    }
  }

  openDialog(): void {
    this.closeDialog();
    this.dialog = this.matDialog.open(this.dialogTemplate, { maxHeight: '90vh', panelClass: 'select-media-dialog-panel' });
  }

  closeDialog(): void {
    this.dialog?.close();
    this.isDraggingOver[1] = false;
    this.changeDetectorRef.markForCheck();
  }

  selectMedia(mediaId: string): void {
    if (this.multiple) {
      const value = this.innerValue?.slice() ?? [];
      const index = value.indexOf(mediaId);
      if (index > -1) {
        value.splice(index, 1);
      } else {
        value.push(mediaId);
      }
      if (value.length || this.clearable) {
        this.value = value;
      }
    } else {
      this.closeDialog();
      this.value = mediaId;
    }
  }

  async deleteMedia(mediaId: string): Promise<void> {
    await this.uploadService.delete(mediaId);

    const index = this.mediaList.findIndex((m) => m._id === mediaId);
    if (index > -1) {
      this.mediaList.splice(index, 1);
      this.changeDetectorRef.markForCheck();
    }
  }

  trackMedia(_: number, media: WithId<DBMedia | MediaUploading> | MongoInsert<MediaUploading>): string {
    return media._id != null ? media._id : Math.random() + '';
  }

  formatPercentage(ratio: number): string {
    return `${Math.round(ratio * 100)}%`;
  }

  writeValue(value: string | Array<string> | undefined): void {
    if (value == null) {
      this.innerValue = [];
      this.changeDetectorRef.markForCheck();
      return;
    }

    const newValue = (Array.isArray(value) ? value : [value]).filter(isNotNullish);
    this.innerValue = this.innerMultiple ? newValue : newValue.slice(0, 1);
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(callback: (value: string | Array<string> | undefined) => void): void {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private fetchMediaList(): void {
    unsubscribeAll([this.mediaListSubscription]);
    const params: MediaMediaListParams = {};
    if (this.contentType != null) { params.uploadedContentType = this.contentType; }
    if (this.contentId != null) { params.uploadedContentId = this.contentId; }
    if (this.mediaCategory != null) { params.category = this.mediaCategory; }
    this.mediaListSubscription = this.apiService.mediaV1MediaList(params).subscribe((res) => {
      this.mediaList = res.result.list;
      this.changeDetectorRef.markForCheck();
    }, (err) => showErrorSnackbar(this.matSnackBar, err));
  }

  private uploadFile(fileOrFiles: File | Array<File> | FileList, setValue: boolean = false): void {
    const uploadObservable = this.uploadService.upload(fileOrFiles, this.contentType, this.contentId, this.mediaCategory).pipe(
      share(),
    );
    const sub = defer(() => {
      const mediaUploadingSubject = new BehaviorSubject<Array<WithId<DBMedia | MediaUploading>>>([]);
      this.mediaUploadingSubjectSubject.next(this.mediaUploadingSubjectSubject.value.concat(mediaUploadingSubject));

      return uploadObservable.pipe(
        tap((progress) => {
          mediaUploadingSubject.next(progress.mediaUploadingList);
          if (progress.mediaUploadedNewList?.length) {
            this.mediaList = progress.mediaUploadedNewList.slice().reverse().concat(this.mediaList);
          }

          if (setValue) {
            const newIds = progress.mediaUploadedNewList?.map((media) => media._id) ?? [];
            if (newIds.length) {
              if (this.multiple) {
                this.value = [...this.value ?? [], ...newIds];
              } else {
                const lastId = newIds[newIds.length - 1];
                if (lastId != null) {
                  this.value = lastId;
                }
              }
            }
          }

          this.changeDetectorRef.markForCheck();

          if (progress.completed) {
            if (progress.errorList?.length) {
              const snackBar = this.matSnackBar.open('전체 또는 일부 업로드 과정에서 오류가 발생했습니다.', '오류 내용 복사', { duration: 5000 });
              snackBar.onAction().subscribe(() => {
                copyError(progress.errorList!);
              });
            }
          }
        }),
        catchError((err) => {
          const snackBar = this.matSnackBar.open(`${err.message ?? '알 수 없는 오류가 발생했습니다.'}`, '오류 내용 복사', { duration: 5000 });
          snackBar.onAction().subscribe(() => {
            copyError(err);
          });
          return EMPTY;
        }),
        finalize(() => {
          this.mediaUploadingSubjectSubject.next(this.mediaUploadingSubjectSubject.value.filter((subject) => subject !== mediaUploadingSubject));
        }),
      );
    }).pipe(
      finalize(() => {
        this.uploadFileSubscriptions = this.uploadFileSubscriptions.filter((item) => item !== sub);
      }),
    ).subscribe();
    this.uploadFileSubscriptions = this.uploadFileSubscriptions.concat(sub);
    this.busyService.mark(sub);
  }
}
