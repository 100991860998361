import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import * as ScrollMagic from 'scrollmagic';
import Swiper from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { getScrollParent } from '../../../utils/etc.util';
import { clamp } from '../../../utils/number.util';
import { unsubscribeAll } from '../../../utils/unsubscribe-all';
import { ContentCut } from './content-viewer.type';

@Component({
  selector: 'app-content-viewer',
  templateUrl: './content-viewer.component.html',
  styleUrls: ['./content-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentViewerComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('slides', { static: false, read: SwiperComponent }) slides?: SwiperComponent;

  @Input() contents?: Array<ContentCut>;
  @Input() direction: 'vertical' | 'horizontal' = 'vertical';
  @Input() download?: boolean;
  @Input() preContents?: Array<TemplateRef<any>>;
  @Input() postContents?: Array<TemplateRef<any>>;

  @Output() readProgress: EventEmitter<number> = new EventEmitter();
  @Output() clickStatic: EventEmitter<UIEvent> = new EventEmitter();
  @Output() clickLink: EventEmitter<CustomEvent<string>> = new EventEmitter();

  scrollMagic?: ScrollMagic.Controller;

  contentsAll: Array<ContentCut> = [];

  width = 0;
  height = 0;

  private lastReadProgress?: number;

  private scrollSubscription?: Subscription;
  private resizeObserver?: ResizeObserver;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    const elem: HTMLElement = this.elementRef.nativeElement;
    const scrollElem = getScrollParent(elem);

    this.scrollMagic = new ScrollMagic.Controller({
      container: scrollElem,
    });

    this.scrollSubscription = fromEvent(scrollElem, 'scroll').subscribe((ev: Event) => {
      if (this.direction !== 'horizontal') {
        const progress = this.getVerticalReadProgress(ev.target as HTMLElement);
        this.updateReadProgress(progress);
      }
    });

    this.resizeObserver = new ResizeObserver(() => {
      this.updateViewerDimension();
    });
    this.resizeObserver.observe(elem);
    this.updateViewerDimension();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.contents ||
      changes.preContents ||
      changes.postContents
    ) {
      this.contentsAll = [
        ...this.preContents?.map<ContentCut>((template) => ({ type: 'ng-template', data: { template } })) ?? [],
        ...this.contents ?? [],
        ...this.postContents?.map<ContentCut>((template) => ({ type: 'ng-template', data: { template } })) ?? [],
      ];
    }
  }

  ngOnDestroy(): void {
    unsubscribeAll([
      this.scrollSubscription,
    ]);
    this.scrollMagic?.destroy(true);
    this.resizeObserver?.disconnect();
  }

  onSlideChange(ev: [Swiper]): void {
    this.getHorizontalReadProgress(ev[0]).then((progress) => {
      this.updateReadProgress(progress);
    });
  }

  onClickSlide(ev: MouseEvent): void {
    if ((ev.target as Element).classList.contains('slide-wrapper')) {
      this.clickStatic.emit(ev);
    }
  }

  calcWidth(cut: ContentCut): number | null {
    if (!cut.width || !cut.height) {
      return null;
    }

    if (this.direction !== 'horizontal') {
      return this.width;
    }
    return Math.min(cut.width / cut.height * this.height, this.width);
  }

  calcHeight(cut: ContentCut): number | null {
    if (!cut.width || !cut.height) {
      return null;
    }

    if (this.direction !== 'horizontal') {
      return cut.height / cut.width * this.width;
    }
    return Math.min(cut.height / cut.width * this.width, this.height);
  }

  async moveToProgress(progress: number, smooth: boolean = false): Promise<void> {
    if (this.direction !== 'horizontal') {
      // TODO: preContents, postContents 지원 추가
      const elem: HTMLElement = this.elementRef.nativeElement;
      const scrollElem = getScrollParent(elem);
      scrollElem.scrollTo({
        top: Math.round((elem.clientHeight - scrollElem.clientHeight) * progress + elem.offsetTop),
        behavior: smooth ? 'smooth' : undefined,
      });
    } else {
      const start = this.preContents?.length ?? 0;
      this.slides?.swiperRef.slideTo(
        start + Math.round(((this.contents?.length ?? 0) - 1) * progress),
        smooth ? this.slides.speed : undefined,
      );
    }
  }

  async prevProgress(smooth: boolean = false): Promise<void> {
    const direction: -1 | 0 | 1 = -1;

    if (this.direction !== 'horizontal') {
      const elem: HTMLElement = this.elementRef.nativeElement;
      const scrollElem = getScrollParent(elem);
      scrollElem.scrollTo({
        top: scrollElem.scrollTop + elem.clientHeight * direction,
        behavior: smooth ? 'smooth' : undefined,
      });
    } else {
      this.slides?.swiperRef.slideTo(
        this.slides.swiperRef.activeIndex + direction,
        smooth ? this.slides.speed : undefined,
      );
    }
  }

  async nextProgress(smooth: boolean = false): Promise<void> {
    const direction: -1 | 0 | 1 = 1;

    if (this.direction !== 'horizontal') {
      const elem: HTMLElement = this.elementRef.nativeElement;
      const scrollElem = getScrollParent(elem);
      scrollElem.scrollTo({
        top: scrollElem.scrollTop + elem.clientHeight * direction,
        behavior: smooth ? 'smooth' : undefined,
      });
    } else {
      this.slides?.swiperRef.slideTo(
        this.slides.swiperRef.activeIndex + direction,
        smooth ? this.slides.speed : undefined,
      );
    }
  }

  private updateViewerDimension(): void {
    const elem: Element = this.elementRef.nativeElement;
    this.width = elem.clientWidth;
    this.height = elem.clientHeight;
    this.changeDetectorRef.markForCheck();
  }

  private updateReadProgress(progress: number): void {
    progress = clamp(progress, 0, 1);
    if (this.lastReadProgress !== progress) {
      this.lastReadProgress = progress;
      this.readProgress.emit(progress);
    }
  }

  private getVerticalReadProgress(scrollParent: HTMLElement): number {
    // TODO: preContents, postContents 지원 추가
    const elem: HTMLElement = this.elementRef.nativeElement;
    const height = elem.clientHeight - scrollParent.clientHeight;
    return clamp(scrollParent.scrollTop - elem.offsetTop, 0, height) / height;
  }

  private getHorizontalReadProgress(swiper: Swiper): Promise<number> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const start = this.preContents?.length ?? 0;
        resolve(clamp((swiper.activeIndex - start) / ((this.contents?.length ?? 0) - 1), 0, 1));
      });
    });
  }
}
