export enum AdminPermission {
  ADMIN_LIST = 'admin.list',
  ADMIN_READ = 'admin.read',
  ADMIN_UPDATE = 'admin.update',
  ADMIN_DELETE = 'admin.delete',
  ADMIN_REGISTER = 'admin.register',
  COUPON_CODE_LIST = 'couponCode.list,',
  COUPON_CODE_CREATE = 'couponCode.create',
  COUPON_CODE_READ = 'couponCode.read',
  COUPON_CODE_UPDATE = 'couponCode.update',
  COUPON_CODE_DELETE = 'couponCode.delete',
  COUPON_CODE_USAGE_LIST = 'couponCode.usageList',
  COUPON_TYPE_LIST = 'couponType.list,',
  COUPON_TYPE_CREATE = 'couponType.create',
  COUPON_TYPE_READ = 'couponType.read',
  COUPON_TYPE_UPDATE = 'couponType.update',
  COUPON_TYPE_DELETE = 'couponType.delete',
  WORK_COLLECTION_LIST = 'workCollection.list,',
  WORK_COLLECTION_CREATE = 'workCollection.create',
  WORK_COLLECTION_READ = 'workCollection.read',
  WORK_COLLECTION_UPDATE = 'workCollection.update',
  WORK_COLLECTION_DELETE = 'workCollection.delete',
  SUB_CAP_RECOMMENDATION_LIST = 'subCapRecommendation.list,',
  SUB_CAP_RECOMMENDATION_CREATE = 'subCapRecommendation.create',
  SUB_CAP_RECOMMENDATION_READ = 'subCapRecommendation.read',
  SUB_CAP_RECOMMENDATION_UPDATE = 'subCapRecommendation.update',
  SUB_CAP_RECOMMENDATION_DELETE = 'subCapRecommendation.delete',
  SUB_CAP_KEYWORD_LIST = 'subCapKeyword.list,',
  SUB_CAP_KEYWORD_CREATE = 'subCapKeyword.create',
  SUB_CAP_KEYWORD_READ = 'subCapKeyword.read',
  SUB_CAP_KEYWORD_UPDATE = 'subCapKeyword.update',
  SUB_CAP_KEYWORD_DELETE = 'subCapKeyword.delete',
  MEDIA_LIST = 'media.list',
  MEDIA_CREATE = 'media.create',
  MEDIA_READ = 'media.read',
  MEDIA_UPDATE = 'media.update',
  MEDIA_DELETE = 'media.delete',
  NOTICE_LIST = 'notice.list',
  NOTICE_CREATE = 'notice.create',
  NOTICE_READ = 'notice.read',
  NOTICE_UPDATE = 'notice.update',
  NOTICE_DELETE = 'notice.delete',
  PROMOTION_LIST = 'promotion.list',
  PROMOTION_CREATE = 'promotion.create',
  PROMOTION_READ = 'promotion.read',
  PROMOTION_UPDATE = 'promotion.update',
  PROMOTION_DELETE = 'promotion.delete',
  PROMOTION_SETTING_GET = 'promotionSetting.get',
  PROMOTION_SETTING_SET = 'promotionSetting.set',
  LOGIN_SCREEN_LIST = 'loginScreen.list',
  LOGIN_SCREEN_CREATE = 'loginScreen.create',
  LOGIN_SCREEN_READ = 'loginScreen.read',
  LOGIN_SCREEN_UPDATE = 'loginScreen.update',
  LOGIN_SCREEN_DELETE = 'loginScreen.delete',
  QNA_LIST = 'qna.list',
  QNA_READ = 'qna.read',
  QNA_UPDATE = 'qna.update',
  SEARCH_TEXT_LIST = 'searchText.list',
  SEARCH_TEXT_UPDATE_VISIBILITY = 'searchText.updateVisibility',
  STAT_CONTENT = 'stat.content',
  STAT_USER = 'stat.user',
  STAT_PURCHASE = 'stat.purchase',
  STAT_WORK_TICKET_USAGE = 'stat.workTicketUsage',
  STAT_WORK_TICKET_USAGE_DETAIL = 'stat.workTicketUsageDetail',
  STAT_DASHBOARD = 'stat.dashboard',
  SUB_CAP_LIST = 'subCap.list',
  SUB_CAP_CREATE = 'subCap.create',
  SUB_CAP_READ = 'subCap.read',
  SUB_CAP_UPDATE = 'subCap.update',
  SUB_CAP_DELETE = 'subCap.delete',
  SUB_CAP_CATEGORY_LIST = 'subCapCategory.list',
  SUB_CAP_CATEGORY_CREATE = 'subCapCategory.create',
  SUB_CAP_CATEGORY_READ = 'subCapCategory.read',
  SUB_CAP_CATEGORY_UPDATE = 'subCapCategory.update',
  SUB_CAP_CATEGORY_DELETE = 'subCapCategory.delete',
  TICKET_LIST = 'ticket.list',
  TICKET_USAGE_LIST = 'ticket.usageList',
  REFUND_LIST = 'refund.list',
  USER_LIST = 'user.list',
  USER_READ = 'user.read',
  USER_MOVIE_CAP_RENT_LIST = 'user.movieCapRentList',
  USER_WATCHED_LIST = 'user.watchedList',
  USER_TICKET_LIST = 'user.ticketList',
  USER_QNA_LIST = 'user.qnaList',
  USER_REFUND_LIST = 'user.refundList',
  USER_SESSION_ACCESS_LIST = 'user.sessionAccessList',
  USER_UPDATE = 'user.update',
  USER_DELETE = 'user.delete',
  USER_SEND_NOTIFICATION = 'user.sendNotification',
  USER_GIVE_TICKET_FREE = 'user.giveTicket.free',
  USER_GIVE_TICKET_PASS = 'user.giveTicket.pass',
  WORK_LIST = 'work.list',
  WORK_CREATE = 'work.create',
  WORK_READ = 'work.read',
  WORK_UPDATE = 'work.update',
  WORK_DELETE = 'work.delete',
  MOVIE_CAP_LIST = 'movieCap.list',
  MOVIE_CAP_CREATE = 'movieCap.create',
  MOVIE_CAP_READ = 'movieCap.read',
  MOVIE_CAP_UPDATE = 'movieCap.update',
  MOVIE_CAP_DELETE = 'movieCap.delete',
  UPCOMING_LIST = 'upcoming.list',
  UPCOMING_CREATE = 'upcoming.create',
  UPCOMING_READ = 'upcoming.read',
  UPCOMING_UPDATE = 'upcoming.update',
  UPCOMING_DELETE = 'upcoming.delete',
  UPCOMING_SEND_PUSH = 'upcoming.sendPush',
  NOTIFICATION_LIST = 'notification.list',
  NOTIFICATION_CREATE = 'notification.create',
  NOTIFICATION_READ = 'notification.read',
  NOTIFICATION_UPDATE = 'notification.update',
  NOTIFICATION_DELETE = 'notification.delete',
  NOTIFICATION_SEND = 'notification.send',
  VERSION_SET_REQUIRED_MIN = 'version.setRequiredMin',
  BANNER_LIST = 'banner.list',
  BANNER_CREATE = 'banner.create',
  BANNER_READ = 'banner.read',
  BANNER_UPDATE = 'banner.update',
  BANNER_DELETE = 'banner.delete',
  TICKET_BUY_OPTION_LIST = 'ticketBuyOption.list',
  CONTENT_WATCHED_LIST = 'contentWatched.list',
  WORK_WATCHED_LIST = 'workWatched.list',
  MOVIE_CAP_WATCHED_LIST = 'movieCapWatched.list',
  SUB_CAP_WATCHED_LIST = 'subCapWatched.list',
  STAT_PROMOTION = 'stat.promotion',
  TICKET_REVOKE = 'ticket.revoke',
}
