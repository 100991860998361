<div class="editor">
  <div class="viewer">
    <mat-card class="viewer-header">
      <div class="viewer-count">
        총 {{ contents?.length || 0 }}개 컷
      </div>
      <button mat-button class="viewer-mepty-button" (click)="onClickViewerEmpty()">
        <mat-icon>delete</mat-icon>
        전체 삭제
      </button>
    </mat-card>
    <mat-card *ngFor="let cut of contents; let i = index" class="content-cut" [ngSwitch]="cut.type">
      <mat-card-title>
        <span>{{ getTypeName(cut.type) }}</span>
        <div class="flex-fill"></div>
        <span class="content-cut-order">{{ i + 1 }}</span>
        <button mat-icon-button [disabled]="i === 0" (click)="moveCut(cut, 'up')">
          <mat-icon>arrow_upward</mat-icon>
        </button>
        <button mat-icon-button [disabled]="i === contents.length - 1" (click)="moveCut(cut, 'down')">
          <mat-icon>arrow_downward</mat-icon>
        </button>
        <button mat-icon-button (click)="onClickDeleteCut(cut)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-title>
      <app-content-text-edit *ngSwitchCase="'text'" [cut]="cut"></app-content-text-edit>
      <app-content-image-edit *ngSwitchCase="'image'" [cut]="cut" [mediaList]="mediaList"></app-content-image-edit>
      <app-content-video-edit *ngSwitchCase="'video'" [cut]="cut" [mediaList]="mediaList"></app-content-video-edit>
      <div *ngSwitchDefault class="content-cut-unknown">알 수 없는 컷 종류: {{ cut.type }}</div>
    </mat-card>
  </div>
  <div class="toolbar" [class.toolbar-open-mobile]="isToolbarOpenMobile">
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>컷</ng-template>
        <div class="cut-tab">
          <button *ngFor="let cut of cutAddList" mat-stroked-button class="add-cut-button" (click)="onClickAddCut(cut.type)">
            <mat-icon>{{ cut.icon }}</mat-icon>
            <div>{{ cut.name }}</div>
          </button>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>이미지/동영상</ng-template>
        <div class="media-tab" (dragenter)="onDragEnterMediaList($event)" (dragleave)="onDragLeaveMediaList($event)" (dragover)="onDragOverMediaList($event)" (drop)="onDropMediaList($event)">
          <div class="media-header">
            <div class="media-count">
              총 {{ mediaList?.length || 0 }}개 미디어
            </div>
          </div>
          <div class="media-sort-and-view">
            <mat-form-field>
              <mat-label>정렬</mat-label>
              <mat-select [(ngModel)]="mediaListSortType" (ngModelChange)="onChangeMediaListSortType()">
                <mat-option value="content">내용 삽입순</mat-option>
                <mat-option value="nameAsc">파일명 A→Z 순</mat-option>
                <mat-option value="nameDesc">파일명 Z→A 순</mat-option>
                <mat-option value="createdAtDesc">업로드 최신 순</mat-option>
                <mat-option value="createdAtAsc">업로드 오래된 순</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>보기</mat-label>
              <mat-select [(ngModel)]="mediaListViewType">
                <mat-option value="list">리스트형</mat-option>
                <mat-option value="gallery">갤러리형</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="media-add-button">
            <input type="file" id="upload-media" name="upload-media" multiple (change)="onChangeFile($event)">
            <label #uploadMediaLabel for="upload-media">
              <button mat-raised-button color="primary" [disabled]="isPreparingUpload" (click)="uploadMediaLabel.click()">
                <ng-container *ngIf="!isPreparingUpload">이미지/동영상 업로드</ng-container>
                <ng-container *ngIf="isPreparingUpload">이미지/동영상 업로드 준비 중...</ng-container>
              </button>
            </label>
          </div>
          <ul class="media-list" [class.media-list-view-list]="mediaListViewType === 'list'" [class.media-list-view-gallery]="mediaListViewType === 'gallery'">
            <li *ngFor="let media of mediaUploadingList; trackBy: trackMedia" class="media-item" [class.media-item-unused]="!mediaUsed[media._id]" [matMenuTriggerFor]="mediaMenu" [matMenuTriggerData]="{ media: media }">
              <div class="media-preview-box">
                <img *ngIf="!media.type.startsWith('video')" [src]="media.dataUrl || media.url">
                <app-video-preview *ngIf="media.type.startsWith('video')" [src]="media.dataUrl || media.url"></app-video-preview>
                <div *ngIf="!mediaUsed[media._id]" class="media-preview-unused"></div>
                <div *ngIf="media.progress !== undefined && media.progress !== null" class="media-preview-uploading">업로드하는 중... ({{ formatPercentage(media.progress) }})</div>
              </div>
              <div class="media-item-name">
                <span *ngIf="media.progress != null" class="media-item-uploading">({{ formatPercentage(media.progress) }})</span>
                <span>{{ media.name }}</span>
              </div>
            </li>
            <li *ngFor="let media of mediaList; trackBy: trackMedia" class="media-item" [class.media-item-unused]="!mediaUsed[media._id]" [matMenuTriggerFor]="mediaMenu" [matMenuTriggerData]="{ media: media }">
              <div class="media-preview-box">
                <img *ngIf="!media.type.startsWith('video')" [src]="media.dataUrl || media.url">
                <app-video-preview *ngIf="media.type.startsWith('video')" [src]="media.dataUrl || media.url"></app-video-preview>
                <div *ngIf="!mediaUsed[media._id]" class="media-preview-unused"></div>
              </div>
              <div class="media-item-name">{{ media.name }}</div>
            </li>
          </ul>
          <div *ngIf="isDraggingOver" class="media-drop-overlay">이미지/동영상 파일을 놓아 업로드</div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <button mat-fab class="toolbar-toggle-mobile" (click)="isToolbarOpenMobile = !isToolbarOpenMobile">
    <mat-icon>
      <ng-template [ngIf]="!isToolbarOpenMobile" [ngIfElse]="closeTemplate">menu</ng-template>
      <ng-template #closeTemplate>close</ng-template>
    </mat-icon>
  </button>
</div>
<mat-menu #mediaMenu>
  <ng-template matMenuContent let-media="media">
    <button *ngIf="media.type.startsWith('image') || media.type.startsWith('video')" mat-menu-item (click)="onClickAddToContents(media)">본문에 추가</button>
    <button mat-menu-item (click)="onClickRenameMedia(media)">이름 변경</button>
    <button mat-menu-item (click)="onClickDeleteMedia(media)">삭제</button>
  </ng-template>
</mat-menu>
