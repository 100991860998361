import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatGridItemComponent } from './stat-grid-item.component';
import { StatGridComponent } from './stat-grid.component';

@NgModule({
  declarations: [
    StatGridItemComponent,
    StatGridComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    StatGridItemComponent,
    StatGridComponent,
  ],
})
export class StatGridModule {}
