import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { DateTimeRange } from '../date-range-picker.type';
import { fadeInCalendar, transformPanel } from './date-range-picker-content-animations';
import { PRESETS } from './date-range-picker-content.constant';
import { Preset } from './date-range-picker-content.type';

@Component({
  selector: 'app-date-range-picker-content',
  templateUrl: 'date-range-picker-content.component.html',
  styleUrls: ['date-range-picker-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    transformPanel,
    fadeInCalendar,
  ],
})
export class DateRangePickerContentComponent implements OnInit, OnDestroy {
  @HostBinding('class.mat-datepicker-content') classMatDatepickerContent = true;
  @HostBinding('@transformPanel') animationState: 'enter-dropdown' | 'enter-dialog' | 'void';

  @Output() selectPreset: EventEmitter<DateTimeRange> = new EventEmitter();

  presets = PRESETS;

  animationDone = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  @HostListener('@transformPanel.done') transformPanelDone(): void {
    this.animationDone.next();
  }

  ngOnInit(): void {
    this.animationState = 'enter-dropdown';
  }

  ngOnDestroy(): void {
    this.animationDone.complete();
  }

  onClickPreset(preset: Preset): void {
    const now = DateTime.local();
    this.selectPreset.emit(preset.func(now));
  }

  startExitAnimation(): void {
    this.animationState = 'void';
    this.changeDetectorRef.markForCheck();
  }
}
