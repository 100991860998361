import { Pipe, PipeTransform } from '@angular/core';
import { Big } from 'big.js';

@Pipe({
  name: 'formatNumberDelta'
})
export class FormatNumberDeltaPipe implements PipeTransform {
  transform(
    value: number | string | null | undefined,
    maxFractionDigits?: number,
    fallback: string | null = null,
  ): string | null {
    if (value == null) {
      return fallback ?? null;
    }

    try {
      const bigStr = maxFractionDigits != null ?
        new Big(value).toFixed(maxFractionDigits).match(/^-?\d+(\.\d*[1-9])?/)?.[0] :
        new Big(value).toString();
      if (bigStr != null) {
        return (bigStr.startsWith('-') ? '' : '+') + bigStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
    } catch {}
    return fallback ?? null;
  }
}
